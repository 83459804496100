import React, { useState } from "react";
import { Formik } from "formik";
import { Modal } from "antd";
import { useAppContext } from "../../contexts/AppContext";

const BasketReference = ({ custIds, reference, vehiclesStd, onSelect = (reference) => {} }) => {
    const [isOpenedReferenceModal, setOpenReferenceModal] = useState(false);
    const { getCustlabelByCustId } = useAppContext();

    const selectReference = (values) => {
        onSelect(values);
        setOpenReferenceModal(false);
    };

    return (
        <>
            <button
                className="w-44 bg-indigo-700 hover:bg-indigo-500 text-white font-semibold shadow-button py-1 rounded-md"
                onClick={() => {
                    setOpenReferenceModal(true);
                }}
            >
                Select reference line
            </button>
            <Modal
                open={isOpenedReferenceModal}
                onCancel={() => {
                    setOpenReferenceModal(false);
                }}
                footer={null}
                width={800}
            >
                <Formik initialValues={reference} onSubmit={selectReference}>
                    {(props) => (
                        <form onSubmit={props.handleSubmit} className="flex flex-col items-center gap-4">
                            <div className="flex gap-4 justify-center self-stretch">
                                <section className="flex-1">
                                    <span className="font-bold text-indigo-700">Player</span>
                                    <div className="p-2 flex flex-col gap-2">
                                        {custIds.map((custIndex) => {
                                            const custLabel = getCustlabelByCustId(custIndex);
                                            return (
                                                <div className="flex gap-2 items-center">
                                                    <input key={`${custIndex}-radio`} id={`${custIndex}-radio`} name="customer" type="radio" value={custIndex} checked={props.values.customer && custIndex === props.values.customer} onChange={props.handleChange} />
                                                    <label htmlFor={`${custIndex}-radio`} className="text-sm cursor-pointer">
                                                        {custLabel}
                                                    </label>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </section>
                                {/* <div className="border-r-2 border-secondary-500"></div> */}
                                <section className="flex-1">
                                    <span className="font-bold text-indigo-700">Vehicle</span>
                                    <div className="p-2 flex flex-col gap-2">
                                        {vehiclesStd.map((versionStd) => (
                                            <div className="flex gap-2 items-center">
                                                <input key={`${versionStd}-radio`} id={`${versionStd}-radio`} type="radio" name="versionStd" value={versionStd} checked={props.values.versionStd && versionStd === props.values.versionStd} onChange={props.handleChange} />
                                                <label htmlFor={`${versionStd}-radio`} className="text-sm cursor-pointer">
                                                    {versionStd}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </section>
                            </div>
                            <button type="submit" className="flex items-center border border-indigo-900 my-2 font-semibold rounded text-white bg-indigo-700 px-8 py-1 hover:bg-indigo-500 text-sm">
                                Select as a reference line
                            </button>
                        </form>
                    )}
                </Formik>
            </Modal>
        </>
    );
};

export { BasketReference };
