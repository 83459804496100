import React, { useEffect, useState, useContext } from "react";
import { useQuery } from "@apollo/client";
import { Button, message, Modal, Tooltip } from "antd";
import { MdGroup, MdPersonAddAlt1, MdClose } from "react-icons/md";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { AiOutlineEye } from "react-icons/ai";

import LoadingSpinner from "../LoadingSpinner";
import { useUserContext } from "../../contexts/User";
import { GET_ASSIGNED_USERS_TO_BASKET } from "../../queries/baskets";
import { useBasketContext } from "../../contexts/BasketProvider";
import { useNotificationContext } from "../../contexts/NotificationProvider";
import FiltersContext from "../../contexts/Filters";
import DropdownButton from "./DropDownButton";

const BasketShareModal = ({ showModal, selectedBasket, onCloseModal = () => {}, ...props }) => {
    const { shareBasket, removeAssignedUsersFromBasket, updateBasketAssignmentInfo } = useBasketContext();
    const { getFilterValue } = useContext(FiltersContext);
    const { insertNotifications } = useNotificationContext();
    const { filterUsersByEmail, user } = useUserContext();
    const { refetch } = useQuery(GET_ASSIGNED_USERS_TO_BASKET);
    const [newCollaborators, setCollaborators] = useState([]);
    const [basketOwner, setBasketOwner] = useState(undefined);
    // Assigned user of the given basket
    const [initialAssignedUsers, setInitialAssignedUsers] = useState([]);
    // assigning new manager / drop collaborator handled by this state
    const [assignedUsers, setAssignedUsers] = useState(initialAssignedUsers);
    const [inputEmail, setInputEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [isLoadingShareModal, setLoadShareModal] = useState(false);
    // Loading || submitted || error
    const [submitStatus, setSubmitStatus] = useState("");
    const isBasketOwner = user?.auth0_id === selectedBasket?.createdBy;
    const countryValue = getFilterValue("filterCountryId")[0];

    function onChangeEmailBoxHandler(event) {
        setInputEmail(event.target.value);
        errorMessage && setErrorMessage(undefined);
    }

    function captureUserEmails(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            if (validateEmailFormat(inputEmail)) {
                const formattedEmail = inputEmail.toLowerCase();
                if (assignedUsers.find((item) => item.email === formattedEmail) || formattedEmail === user.username) {
                    setErrorMessage(`${formattedEmail} already assigned to this basket`);
                    return;
                } else if (newCollaborators.find((item) => item.email === formattedEmail)) {
                    setErrorMessage(`"${formattedEmail}" is already in the email list`);
                    return;
                }
                setCollaborators([...newCollaborators, { email: formattedEmail, access_role: "reader" }]);
                setInputEmail("");
                setErrorMessage(undefined);
            } else {
                setErrorMessage("Email is not valid");
            }
        }
    }

    function dropAssignedUser(index) {
        assignedUsers.splice(index, 1);
        setAssignedUsers([...assignedUsers]);
    }

    function onUpdatePermission(index, access_role) {
        const user = {
            ...assignedUsers[index],
            access_role,
        };
        assignedUsers.splice(index, 1, user);
        setAssignedUsers([...assignedUsers]);
    }

    function dropCollaborator(index) {
        newCollaborators.splice(index, 1);
        setCollaborators([...newCollaborators]);
    }

    function deleteAccessPermissions() {
        // Find deleted objects
        const removedUsersIDs = initialAssignedUsers.filter((item) => !assignedUsers.find((usr) => usr.email === item.email)).map((item) => item.id);
        return removeAssignedUsersFromBasket({ ids: removedUsersIDs });
    }

    function onShareSubmit() {
        // Handler for submitting the basket share
        setSubmitStatus("loading");
        if (assignedUsers.length !== initialAssignedUsers.length) {
            deleteAccessPermissions().then((res) => {
                message.info("Dropping users");
                onCloseModal();
            });
        }
        // Check if the permissions are updated
        const updatedUsers = assignedUsers.filter((user) => user.access_role !== initialAssignedUsers.find((initUser) => initUser.email === user.email)?.access_role);
        if (updatedUsers.length > 0) {
            updateBasketAssignmentInfo(
                updatedUsers.map((obj) =>
                    Object.assign({
                        id: obj.id,
                        access_role: obj.access_role,
                    }),
                ),
            ).then((res) => {
                message.info("Access permissions updated");
                onCloseModal();
            });
        }
        if (newCollaborators.length > 0) {
            return filterUsersByEmail(newCollaborators.map((item) => item.email))
                .then((res) => {
                    const messageNotif = `${user.username} had shared with you the "${selectedBasket.name}" basket`;
                    const basketUsers = res.data?.re_rentaleye_users.map((userItem) =>
                        Object.assign({
                            ...userItem,
                            access_role: newCollaborators.find((collaborator) => collaborator.email === userItem.username)?.access_role || "reader",
                        }),
                    );
                    return shareBasket({ basket: selectedBasket, sharedBy: user, collaborators: basketUsers }).then((res) => {
                        message.info("Basket had been shared");
                        onCloseModal();
                        const usersNotifs = basketUsers.map((user) =>
                            Object.assign({
                                auth_user_id: user.auth0_id,
                                message: messageNotif,
                                obj_id: selectedBasket.id,
                                obj_type: "re_rentaleye_baskets",
                                route: "/baskets",
                                metadata: {
                                    country: countryValue,
                                },
                            }),
                        );
                        insertNotifications(usersNotifs);
                    });
                })
                .catch((error) => {
                    setSubmitStatus("error");
                    setErrorMessage("Unable to find a user connected with those emails");
                });
        }
    }

    useEffect(() => {
        if (initialAssignedUsers.length > 0 && JSON.stringify(initialAssignedUsers) !== JSON.stringify(assignedUsers)) {
            setAssignedUsers([...initialAssignedUsers]);
        }
    }, [initialAssignedUsers]);

    useEffect(() => {
        if (!showModal) {
            setCollaborators([]);
            setAssignedUsers([]);
            setErrorMessage(undefined);
        } else {
            Promise.resolve(
                setTimeout(() => {
                    setLoadShareModal(true);
                }, 1000),
            ).then((res) => {
                setLoadShareModal(false);
            });
        }
        return () => {
            setErrorMessage(undefined);
            setSubmitStatus(undefined);
        };
    }, [showModal]);

    useEffect(() => {
        if (selectedBasket?.id && showModal)
            refetch({
                basketId: selectedBasket.id,
            }).then((res) => {
                const assignedEmailList = res.data.re_rentaleye_basket_permissions.map((item) =>
                    Object.assign({
                        id: item.id,
                        email: item.basket_permissions_user.username,
                        access_role: item.access_role,
                    }),
                );
                const basketOwner = res.data.re_rentaleye_basket_permissions.find((item) => item.basket_permissions_assigned_by)?.basket_permissions_assigned_by;
                setInitialAssignedUsers(assignedEmailList);
                basketOwner && setBasketOwner(basketOwner);
            });
    }, [selectedBasket, showModal]);

    return (
        <Modal
            width={800}
            open={showModal}
            onCancel={onCloseModal}
            footer={
                !isBasketOwner ? null : (
                    <>
                        <Button onClick={onCloseModal}>Cancel</Button>
                        <Button loading={submitStatus === "loading"} disabled={newCollaborators.length === 0 && JSON.stringify(initialAssignedUsers) === JSON.stringify(assignedUsers)} type="primary" onClick={onShareSubmit}>
                            OK
                        </Button>
                    </>
                )
            }
        >
            {!isLoadingShareModal ? (
                <LoadingSpinner />
            ) : (
                <>
                    <h4
                        className="relative bottom-2 text-steel text-xl font-semibold w-full"
                        style={{
                            wordSpacing: 2,
                        }}
                    >
                        Manage access for <span className="font-bold">{selectedBasket.name}</span>
                    </h4>
                    {isBasketOwner && (
                        <div className="flex flex-col gap-2">
                            <h4 className="inline-flex gap-1 items-center text-lg text-indigo-500">
                                <MdPersonAddAlt1 className="w-9 h-9 p-2 rounded-full bg-indigo-500 text-white" />
                                Share with new users
                            </h4>
                            <div className={`w-full p-2 border border-rounded focus:outline-none ${errorMessage ? "border-secondary-300" : "border-gray-300"}`}>
                                {newCollaborators.length > 0 && (
                                    <ul className="my-1 flex flex-wrap gap-2">
                                        {newCollaborators.map((item, index) => (
                                            <li className={"flex items-center gap-2 text-sm py-1 px-2 cursor-pointer rounded-full bg-backgroundColor" + (item.access_role === "manager" ? " bg-steel text-white" : " hover:bg-backgroundColor")}>
                                                <span>{item.email}</span>
                                                <MdClose className="self-end h-4 w-4 hover:text-secondary-500" onClick={() => dropCollaborator(index)} />
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                <input className="w-full focus:outline-none" type="text" placeholder="user@organisation.com" value={inputEmail} onKeyDown={captureUserEmails} onChange={onChangeEmailBoxHandler} />
                            </div>
                            <span className="text-xs font-medium text-gray-500">* Please click on "Enter" to validate an email</span>
                            {errorMessage && <span className="rounded-md bg-secondary-500 border border-secondary-500 shadow-[inset_0_2px_4px_rgba(0,0,0,0.1)] text-base font-medium text-white px-2 py-1">{errorMessage}</span>}
                        </div>
                    )}
                    {assignedUsers.length > 0 && (
                        <div className="mt-4">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <h4 className="inline-flex gap-1 items-center text-lg text-indigo-500">
                                    <MdGroup className="w-9 h-9 p-2 rounded-full bg-indigo-500 text-white" />
                                    Basket group
                                </h4>
                            </div>
                            <ul className="divide-y divide-gray-200">
                                <li className="flex justify-between items-center p-2 hover:bg-backgroundColor cursor-pointer">
                                    {basketOwner?.username}
                                    <div className="inline-flex gap-2 items-center">
                                        <span>Owner</span>
                                        {isBasketOwner && <div className="w-4"></div>}
                                    </div>
                                </li>
                                {assignedUsers.map((item, index) => {
                                    const defaultOption = basketAccess.find((option) => item.access_role === option.key);
                                    const collabElem = (
                                        <li className={"text-md flex justify-between items-center p-2 cursor-pointer hover:bg-backgroundColor " + (item.access_role === "manager" ? "" : "")}>
                                            <span>{item.email}</span>
                                            <div className="inline-flex gap-2 items-center">
                                                {isBasketOwner ? <DropdownButton defaultOption={defaultOption} objIndex={index} options={basketAccess} onSelect={onUpdatePermission} /> : defaultOption.label}
                                                {isBasketOwner && (
                                                    <Tooltip title={"Drop a user"} color="#ff7d93">
                                                        <DeleteOutlined
                                                            style={{
                                                                color: "#dc143c",
                                                            }}
                                                            onClick={() => dropAssignedUser(index)}
                                                        />
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </li>
                                    );
                                    return collabElem;
                                })}
                            </ul>
                        </div>
                    )}
                </>
            )}
        </Modal>
    );
};

const basketAccess = [
    {
        key: "manager",
        label: (
            <div className="inline-flex gap-1 items-center">
                <EditOutlined /> <span>Can edit</span>
            </div>
        ),
    },
    {
        key: "reader",
        label: (
            <div className="inline-flex gap-1 items-center">
                <AiOutlineEye /> <span>Viewer</span>
            </div>
        ),
    },
];

function validateEmailFormat(email) {
    const re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,5}$/;
    return re.test(email);
}

export { BasketShareModal };
