import React, { useContext, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import HighchartsComponent from "../HighchartsComponent";
import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { CustIDByScope, useBrandConfigurator } from "../../contexts/BrandConfiguratorProvider";
import MeasuresContext from "../../contexts/Measures";
import { medianByMake, weightedAverageByMake } from "../../utils/calculationUtils";
import * as Md from "../../md/full";
import FiltersContext from "../../contexts/Filters";
import { calculationModes, transformSlicesToBrandTree } from "../../routes/BrandModelAnalysis";
import { useAppContext } from "../../contexts/AppContext";

const widgetId = "brand-column-chart";

function ColumnChartAnalysis({ calculationMode = "average", selectedPlayers, ...props }) {
    const { filterSelectors } = useBrandConfigurator();
    const { selectedKpi } = useContext(MeasuresContext);

    // Group by make
    const [series, setSeries] = useState([]);
    const [status, setStatus] = useState(undefined);

    useEffect(() => {
        // Calculated weighted average by model
        setStatus("loading");
        setTimeout(() => {
            const seriesTmp = Object.entries(filterSelectors).map(([make, modelsObject]) => {
                const custIdValues = selectedPlayers.reduce((obj, player) => {
                    const val = calculationMode === "average" ? weightedAverageByMake(modelsObject, selectedKpi, calculationModes[calculationMode].calculationMethod, player.custId) : medianByMake(modelsObject, selectedKpi, calculationModes[calculationMode].calculationMethod, player.custId);
                    const roundedValue = ![null, 0].includes(val) ? selectedKpi.roundValue(val) : null;

                    return {
                        ...obj,
                        [player.custId]: roundedValue,
                    };
                }, {});

                return Object.assign({
                    name: make,
                    y: _.min(Object.values(custIdValues)),
                    color: "white",
                    borderColor: "#3a0ca3",
                    borderWidth: 2,
                    ...custIdValues,
                });
            }, []);

            seriesTmp?.sort((a, b) => b.y - a.y);

            setSeries([...seriesTmp]);
            setStatus("complete");
        }, 100);

        return () => {
            setSeries([]);
            setStatus(undefined);
        };
    }, [filterSelectors, selectedKpi.index, calculationMode]);

    return status === "loading" ? (
        <LoadingComponent />
    ) : (
        <div className="flex flex-col gap-2">
            <ColumnChart id={widgetId} filters={props.filters} series={series} calculationMode={calculationMode} selectedPlayers={selectedPlayers} menuButtonPosition={"inside"} {...props} />
            {props.footnote && <span className="pl-3 pb-2 text-left text-xs font-medium">{props.footnote}</span>}
        </div>
    );
}

/**
 *
 * Widget used on the dashboard
 */
const ColumnChartAnalysisWidget = ({ calculationMode = "average", ...props }) => {
    const { seriesBy, selectedKpi } = useContext(MeasuresContext);
    const { getCustlabelByCustId, getColorByCustId } = useAppContext();
    const [series, setSeries] = useState([]);
    const { status, result } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: [Md.CorrMake, Md.CorrModel, Md.CustId, Md.VersionStd],
            filters: props.filters,
        },
    });
    const playerFilter = props.filters.find((obj) => obj.positiveAttributeFilter.displayForm.identifier === Md.CustId.attribute.displayForm.identifier);

    const playerCustIDs = playerFilter?.positiveAttributeFilter.in.values.length > 0 ? playerFilter.positiveAttributeFilter.in.values : ["OEM", "CAP"];
    const selectedPlayers = playerCustIDs.map((custId) => ({
        custId: custId,
        custLabel: getCustlabelByCustId(custId),
        custColor: getColorByCustId(custId),
    }));

    const { getFirstFilterValue } = useContext(FiltersContext);
    const countryId = getFirstFilterValue("filterCountryId");
    const channel1 = getFirstFilterValue("filterChannel1");

    const makeModelObject = useMemo(() => {
        const slices = result?.data().slices().toArray();
        const res = slices ? transformSlicesToBrandTree(slices) : {};
        return res;
    }, [result, status, selectedKpi]);

    useEffect(() => {
        if (makeModelObject) {
            const seriesTmp = Object.entries(makeModelObject).map(([make, modelsObject]) => {
                const custIdValues = selectedPlayers.reduce((obj, player) => {
                    const val = calculationMode === "average" ? weightedAverageByMake(modelsObject, selectedKpi, calculationModes[calculationMode].calculationMethod, player.custId) : medianByMake(modelsObject, selectedKpi, calculationModes[calculationMode].calculationMethod, player.custId);
                    const roundedValue = ![null, 0].includes(val) ? selectedKpi.roundValue(val) : null;

                    return {
                        ...obj,
                        [player.custId]: roundedValue,
                    };
                }, {});
                return Object.assign({
                    name: make,
                    y: _.min(Object.values(custIdValues)),
                    color: "white",
                    borderColor: "#3a0ca3",
                    borderWidth: 2,
                    ...custIdValues,
                });
            }, []);
            seriesTmp?.sort((a, b) => b.y - a.y);
            seriesTmp && setSeries([...seriesTmp]);
        }
    }, [makeModelObject]);

    return status === "loading" || series.length <= 0 ? (
        <div className="flex flex-col items-center justify-center gap-2 min-h-[400px]">
            <div>
                <LoadingComponent />
            </div>
            <span>Loading...</span>
        </div>
    ) : (
        <ColumnChart id={widgetId} filters={props.filters} series={series} calculationMode={calculationMode} selectedPlayers={selectedPlayers} {...props} />
    );
};

function ColumnChart({ id, filters, series, selectedPlayers = [], ...props }) {
    return (
        <HighchartsComponent
            widgetProps={{
                id,
                filters,
                section: "oem-views",
                calculationMode: props.calculationMode,
            }}
            options={{
                chart: {
                    type: "column",
                    height: 500,
                    marginTop: 65,
                    spacingBottom: 2,
                },
                plotOptions: {
                    column: {
                        maxPointWidth: 60,
                        // colorByPoint: true,
                        dataLabels: {
                            enabled: true,
                        },
                        marker: {
                            symbol: "none", // This completely removes the marker/symbol for the column series in the legend
                        },
                        legendIndex: 0,
                        legend: {
                            symbolWidth: 0,
                            symbolHeight: 0, // Hides the symbol for the column series
                        },
                    },
                    spline: {
                        lineWidth: 0,
                        states: {
                            hover: {
                                enabled: false,
                            },
                        },
                    },
                },
                title: {
                    text: props.title,
                    align: "center",
                    style: {
                        fontSize: "15px",
                        fontWeight: 600,
                        color: "#3a0ca3",
                    },
                },
                subtitle: {
                    text: props.subtitle,
                    align: "center",
                    style: {
                        fontSize: "12px",
                        fontWeight: 600,
                        color: "#506e96",
                    },
                },
                xAxis: {
                    categories: series.length > 0 ? series.map((serie) => serie.name) : null,
                    // labels: labels || {
                    //     padding: 3,
                    //     reserveSpace: series.length > 0,
                    //     style: {
                    //         fontSize: pdfMode ? "8px" : "10px",
                    //         fontWeight: 600,
                    //         textOverflow: "none",
                    //     },
                    // },
                    title: {
                        text: null,
                    },
                },
                yAxis: {
                    labels: {
                        enabled: false,
                    },
                    title: {
                        text: null,
                    },
                    min: 0,
                    // max: series.length > 0 ?series[0].y * 1.1 : null,
                    tickAmount: null,
                    // plotLines: referenceLine && [
                    //     {
                    //         value: referenceLine.value,
                    //         width: 2,
                    //         zIndex: 10,
                    //         color: "#FF0000",
                    //         dashStyle: "shortdash",
                    //     },
                    // ],
                },
                legend: {
                    enabled: true,
                    align: "center",
                    verticalAlign: "bottom",
                    layout: "horizontal",
                    squareSymbol: false,
                    symbolRadius: 0,
                    symbolWidth: 0,
                    symbolHeight: 0,
                    itemStyle: {
                        fontWeight: "bold",
                    },
                    useHTML: true,
                    labelFormatter: function () {
                        return this.name === "Cheapest"
                            ? `<div style="display:flex; gap:3px; align-items: center; margin: 4px 2px;"><div style="width:10px; height:10px; border: 2px solid #3a0ca3"></div> ${this.name}</div>`
                            : `<div style="display:flex; gap:3px; align-items: center; margin: 4px 2px;"><div style="width:10px; height:10px; border-radius: 20px; background-color: ${this.color};"></div> ${this.name}</div>`;
                    },
                },
                series:
                    series.length > 0
                        ? [
                              {
                                  name: "Cheapest",
                                  type: "column",
                                  data: series,
                                  legend: {
                                      symbolWidth: 0,
                                      symbolHeight: 0, // Hides the symbol for the column series
                                  },
                                  // showInLegend: false,
                                  enableMouseTracking: false,
                                  marker: {
                                      enabled: false, // This completely removes the marker/symbol for the column series in the legend
                                  },
                                  // colorByPoint: true,
                                  // legendColor: "#ff7d93",
                              },
                              ...selectedPlayers.map((player) =>
                                  Object.assign({
                                      name: [player.custLabel],
                                      type: "spline",
                                      marker: {
                                          symbol: "circle",
                                          radius: 5,
                                          enabled: true,
                                      },
                                      color: player.custColor,
                                      showInLegend: series.filter((serie) => serie[player.custId] !== null).length > 0,
                                      data: series.map((serie) =>
                                          Object.assign({
                                              y: serie[player.custId],
                                          }),
                                      ),
                                  }),
                              ),
                          ]
                        : null,
                lang: {
                    noData: "No data available",
                },
                noData: {
                    style: {
                        fontWeight: "bold",
                        fontSize: "15px",
                        color: "#595959",
                    },
                },
            }}
            {...props}
        />
    );
}

export { ColumnChartAnalysis, ColumnChartAnalysisWidget };
