//@ts-nocheck
import React, { useEffect, useState } from "react";
import { ErrorComponent, LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { newArithmeticMeasure } from "@gooddata/sdk-model";

import jStat from "jstat";
import { useAppContext } from "../../contexts/AppContext";
import { flagServiceMap } from "../../contexts/Measures";
import { Dropdown } from "antd";
import { RiUnpinFill } from "react-icons/ri";
import { FiMoreVertical } from "react-icons/fi";
import { useDashboard } from "../../contexts/DashboardProvider";
import * as Md from "../../md/full";

function regression(arrWeight, arrHeight) {
    let r, sy, sx, b, a, meanX, meanY;
    r = jStat.corrcoeff(arrWeight, arrHeight);
    sy = jStat.stdev(arrWeight);
    sx = jStat.stdev(arrHeight);
    meanY = jStat(arrWeight).mean();
    meanX = jStat(arrHeight).mean();
    b = r * (sy / sx);
    a = meanY - meanX * b;
    //Set up a line
    let y1, y2, x1, x2;
    x1 = jStat.min(arrHeight);
    x2 = jStat.max(arrHeight);
    y1 = a + b * x1;
    y2 = a + b * x2;
    return {
        line: [
            [x1, y1],
            [x2, y2],
        ],
        r,
    };
}

const BasketLFWidget = ({ id, execDefinition, widgetUid, componentChild, ...props }) => {
    const { seriesBy: execSeries } = execDefinition;
    const [data, setData] = useState([]);
    const [flat, setFlat] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const [corrline, setCorrline] = useState({});
    const { getColorByCustId } = useAppContext();
    const [newSeries, setNewSeries] = useState([...execSeries]);
    const { country, flag_services } = props;
    const { unPinwWidget } = useDashboard();
    const { result, status } = useExecutionDataView({
        execution: {
            ...execDefinition,
            seriesBy: [...newSeries],
        },
    });

    useEffect(() => {
        if (flag_services) {
            const enabledServices = Object.keys(flag_services)
                .filter((flService) => flService in flagServiceMap && flag_services[flService])
                .map((flService) => flagServiceMap[flService]);
            newSeries.splice(23, 1, newArithmeticMeasure([Md.FinRental.Avg, Md.AdjDeposit.Avg, ...enabledServices], "sum"));
            newSeries.splice(24, 1, newArithmeticMeasure([newArithmeticMeasure([Md.FinRental.Avg, Md.AdjDeposit.Avg, ...enabledServices], "sum"), Md.ListPrice.Avg], "ratio"));
            setNewSeries([...newSeries]);
        }
    }, [componentChild, flag_services]);

    useEffect(() => {
        if (result && status === "success") {
            const slices = result?.data()?.slices().toArray();
            if (slices?.length > 0) {
                const flat = slices?.map((slice) => {
                    return {
                        Model: slice.sliceTitles()[10],
                        Trim: slice.sliceTitles()[4],
                        Player: slice.sliceTitles()[9],
                        "List Price": Number(slice.dataPoints()[1].rawValue) > 0 ? Number(Number(slice.dataPoints()[1].rawValue).toFixed(0)) : null,
                        "Adj. Rental": Number(slice.dataPoints()[23].rawValue) > 0 ? Number(Number(slice.dataPoints()[23].rawValue).toFixed(0)) : null,
                        "Leasing Factor": 100 * Number(slice.dataPoints()[24].rawValue) > 0 ? Number((100 * Number(slice.dataPoints()[24].rawValue)).toFixed(2)) : null,
                    };
                });
                setFlat(flat);
                const series = flat
                    .map((elem) => {
                        return {
                            name: elem.Player,
                            color: getColorByCustId(elem.Player),
                            data: flat
                                .filter((elem2) => elem2.Player === elem.Player)
                                .map((elem2) => {
                                    return {
                                        x: elem2["List Price"],
                                        y: elem2["Adj. Rental"],
                                        z: elem2["Leasing Factor"],
                                        t: elem2.Trim,
                                        m: elem2.Model,
                                    };
                                }),
                        };
                    })
                    .filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);
                setSelectedCustomer(series.map((elem) => elem.name));
                setData(series);
            }
        }
    }, [result]);

    useEffect(() => {
        const filtered = flat?.filter((elem) => selectedCustomer.includes(elem.Player));
        const arrLP = filtered.filter((elem) => elem["Leasing Factor"] !== null).map((elem) => elem["List Price"]);
        const arrAR = filtered.filter((elem) => elem["Leasing Factor"] !== null).map((elem) => elem["Adj. Rental"]);
        const { line, r } = regression(arrAR, arrLP);
        setCorrline({ line, r });
    }, [selectedCustomer, flat]);

    return status === "success" && data.length > 0 ? (
        React.createElement(componentChild, { data, ...props })
    ) : status === "loading" ? (
        <div className="min-h-[400px] flex items-center justify-center">
            <LoadingComponent />
        </div>
    ) : (
        <div className="relative">
            <Dropdown
                menu={{
                    items: [
                        {
                            key: "unpin",
                            icon: <RiUnpinFill />,
                            label: "Unpin widget",
                            onClick: () => unPinwWidget(widgetUid),
                        },
                    ],
                }}
                trigger={["click"]}
            >
                <FiMoreVertical className="absolute top-2 right-2 z-40 rounded w-8 h-8 px-1.5 py-1 text-gray-500 cursor-pointer hover:bg-backgroundColor hover:text-indigo-700" />
            </Dropdown>
            <div className="min-h-[400px] flex flex-col items-center justify-center">
                <ErrorComponent />
                <span className="text-sm font-medium text-steel/80 text-center py-2">
                    Seems like there is an issue with the data or the widget <br /> try unpinning and re-pinning it
                </span>
            </div>
        </div>
    );
};

export default BasketLFWidget;
