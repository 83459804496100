import { ErrorComponent, LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import React, { useEffect, useState } from "react";
import { Dropdown, Typography } from "antd";
import jsonPivot from "../../utils/jsonPivot";
import { RiUnpinFill } from "react-icons/ri";
import { FiMoreVertical } from "react-icons/fi";
import { useDashboard } from "../../contexts/DashboardProvider";

const AdjustedDataCoverageWidget = ({ id, widgetUid, execDefinition, componentChild, ...props }) => {
    const Databy = props?.metadata?.Databy;
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const { unPinwWidget } = useDashboard();
    const { result, status } = useExecutionDataView({
        execution: execDefinition,
    });

    useEffect(() => {
        if (!["Distinct Versions"].includes(Databy)) {
            const slices = result?.data()?.slices().toArray();
            const newdata = [];
            const data = slices?.map((slice) => {
                return {
                    CustId: slice.sliceTitles()[0],
                    [Databy]: slice.sliceTitles()[1],
                    count: Number(slice.dataPoints()[28].rawValue),
                };
            });
            data?.forEach((item) => {
                const newitem = {
                    CustId: item.CustId,
                    [Databy]: item[Databy],
                    count: item.count,
                };
                if (!newdata.some((e) => e.CustId === newitem.CustId && e[Databy] === newitem[Databy])) {
                    newdata.push(newitem);
                }
            });
            if (newdata.length > 0) {
                const dataSource = jsonPivot(newdata, { row: [Databy], column: "CustId", value: "count" });
                const newdataSource = dataSource.map((item) => {
                    const total = Object.values(item)
                        .filter((m) => typeof m === "number")
                        .reduce((a, b) => a + b, 0);
                    const newitem = {
                        ...item,
                        Total: total,
                    };
                    return newitem;
                });
                setData(newdataSource);
                if (newdataSource[0] !== undefined) {
                    setColumns(
                        Object.keys(newdataSource[0]).map((m) => {
                            return {
                                title: () => <center>{[Databy, "Total"].includes(m) ? m : <img src={`/images/logos/Logo_${m}.png`} width={64} height={64} alt={m} />}</center>,
                                dataIndex: m,
                                key: m,
                                width: m === Databy ? 200 : 100,
                                fixed: [Databy].includes(m) ? "left" : null,
                                align: "center",
                                render: (cell) => {
                                    return (
                                        <Typography.Text style={{ fontSize: 12 }} strong>
                                            {cell}
                                        </Typography.Text>
                                    );
                                },
                                filters: [Databy].includes(m)
                                    ? null
                                    : [
                                          {
                                              text: "Show values only",
                                              value: null,
                                          },
                                      ],

                                onFilter: (value, record) => ([Databy].includes(m) ? value : record[m] !== value),
                            };
                        }),
                    );
                }
            }
        }
    }, [result, Databy]);
    useEffect(() => {
        if (Databy === "Distinct Versions") {
            const slices = result?.data()?.slices().toArray();
            const newdata = {
                "Distinct Versions": {},
                Offers: {},
            };

            slices?.forEach((slice) => {
                const custId = slice.sliceTitles()[0];
                newdata["Distinct Versions"][custId] = Number(slice.dataPoints()[28].rawValue);
                newdata["Offers"][custId] = Number(slice.dataPoints()[33].rawValue);
            });

            // Calculate totals for both rows
            ["Distinct Versions", "Offers"].forEach((key) => {
                const total = Object.values(newdata[key]).reduce((a, b) => a + b, 0);
                newdata[key]["Total"] = total;
            });
            setData(
                Object.entries(newdata).map(([key, value]) => ({
                    attribute: key,
                    ...value,
                })),
            );
        }
    }, [result, Databy]);
    useEffect(() => {
        if (Databy === "Distinct Versions") {
            if (data[0] !== undefined) {
                const colLabels = Object.keys(data[0]).map((key) => {
                    return {
                        title: <center>{key !== "Total" ? key !== "attribute" ? <img src={`/images/logos/Logo_${key}.png`} width={64} height={64} alt={key} /> : "" : key}</center>,
                        dataIndex: key,
                        key: key,
                        width: key !== "attribute" ? 100 : 160,
                        render: (text) => (
                            <Typography.Text style={{ fontSize: 12 }} strong>
                                {text}
                            </Typography.Text>
                        ),
                        align: "center",
                    };
                });
                setColumns(colLabels);
            }
        }
    }, [data, Databy]);

    return (
        <div className="relative">
            {status === "success" && data?.length > 0 ? (
                React.createElement(componentChild, { data, columns, ...props })
            ) : status === "loading" ? (
                <div className="min-h-[400px] flex items-center justify-center">
                    <LoadingComponent />
                </div>
            ) : (
                <div className="relative min-h-[400px]">
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    key: "unpin",
                                    icon: <RiUnpinFill />,
                                    label: "Unpin widget",
                                    onClick: () => unPinwWidget(widgetUid),
                                },
                            ],
                        }}
                        trigger={["click"]}
                    >
                        <FiMoreVertical className="absolute top-2 right-2 z-40 rounded w-8 h-8 px-1.5 py-1 text-gray-500 cursor-pointer hover:bg-backgroundColor hover:text-indigo-700" />
                    </Dropdown>
                    <div className="flex flex-col items-center justify-center h-full">
                        <ErrorComponent />
                        <span className="text-sm font-medium text-steel/80 text-center py-2">
                            Seems like there is an issue with the data or the widget <br /> try unpinning and re-pinning it
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdjustedDataCoverageWidget;
