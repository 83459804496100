import React, { useContext } from "react";
import { ErrorComponent, LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { useEffect } from "react";
import { useState } from "react";
import { newAttributeSort, newPositiveAttributeFilter } from "@gooddata/sdk-model";
import * as Md from "../../../md/full";
import { useAppContext } from "../../../contexts/AppContext";
import UpDownButton from "../../Buttons/UpDownButton";
import UserContext from "../../../contexts/User";
import MeasuresContext from "../../../contexts/Measures";
import { ScatterChart } from "../../Visualization/ScatterChart";
import jStat from "jstat";
import moment from "moment";
import { PdfHeader } from "./BasketViz";

/**
 * @typedef {Object} KpiSettings
 * @property {boolean} [flag_ser]
 * @property {boolean} [flag_bre]
 * @property {boolean} [flag_ins]
 * @property {boolean} [flag_tyr]
 * @property {boolean} [flag_fin]
 *
 * @typedef {Object} KpiObject
 * @property {string} [name]
 * @property {number} [id]
 */

/**
 * @param {Object} props
 * @param {KpiSettings} [props.userPref]
 * @param {KpiObject} [props.kpi]
 */
const BasketSummary = ({ userPref = {}, kpi = {}, ...props }) => {
    const summaryLabels = {
        Maintenance: userPref?.flag_ser,
        Breakdown: userPref?.flag_bre,
        Insurance: userPref?.flag_ins,
        Tyres: userPref?.flag_tyr,
        "Financial loss": userPref?.flag_fin,
    };
    let summaryLabelsIterator = Object.entries(summaryLabels);
    return (
        <span className="italic text-gray-500 text-xs font-light">
            {`Monthly Rate Incl. VAT, LCV Rental Excl. VAT,  ${kpi?.name}, `}
            {[1, 4].includes(kpi?.id) && summaryLabelsIterator.map(([key, value], index) => (value ? "Incl." : "Excl.") + " " + key + (index > summaryLabelsIterator?.length - 1 ? ", " : " "))}
        </span>
    );
};

function regression(arrWeight, arrHeight) {
    let r, sy, sx, b, a, meanX, meanY;
    //@ts-ignore
    r = jStat.corrcoeff(arrWeight, arrHeight);
    //@ts-ignore
    sy = jStat.stdev(arrWeight);
    //@ts-ignore
    sx = jStat.stdev(arrHeight);
    //@ts-ignore
    meanY = jStat(arrWeight).mean();
    //@ts-ignore
    meanX = jStat(arrHeight).mean();
    b = r * (sy / sx);
    a = meanY - meanX * b;
    //Set up a line
    let y1, y2, x1, x2;
    //@ts-ignore
    x1 = jStat.min(arrHeight);
    //@ts-ignore
    x2 = jStat.max(arrHeight);
    y1 = a + b * x1;
    y2 = a + b * x2;
    return {
        line: [
            [x1, y1],
            [x2, y2],
        ],
        r,
    };
}

const LFGraph = ({ filters, kpiSettings, Basket, country, title, setIsLoading, printStatus, printMode, ...props }) => {
    const userPreferenceContext = useContext(UserContext);
    const { seriesBy, selectedAdjDeposit, selectedKpi } = useContext(MeasuresContext);
    const [data, setData] = useState([]);
    const [flat, setFlat] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const [corrline, setCorrline] = useState({});
    const [showSummary, setShowSummary] = useState(userPreferenceContext?.user?.basket_sections?.Bakset_LFMatrix);
    const { getColorByCustId } = useAppContext();
    const [versionStdFilter, setVersionStdFilter] = useState(newPositiveAttributeFilter(Md.VersionStd, [Basket.basketFilters.filter((elem) => elem.type === "version_std").map((elem) => elem.value)]));
    const custSlots = Object.entries(userPreferenceContext.defaultUserPref)
        .filter(([key, val]) => key.includes("customer_slot") && val !== null)
        .map(([key, val]) => val);
    const custIdFilters = newPositiveAttributeFilter(Md.CustId, custSlots);
    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy: [...seriesBy, Md.Deposit.Avg, Md.Emissions.Avg],
            slicesBy: [Md.Channel1, Md.Ctryid, Md.Duration, Md.Channel3, Md.TrimName, Md.Fuel, Md.GearsPlus, Md.Drive, Md.VehSegment, Md.CustId, Md.CorrModel],
            filters: [...filters, versionStdFilter, custIdFilters],
            sortBy: [newAttributeSort(Md.CorrModel)],
        },
    });
    useEffect(() => {
        if (Basket !== undefined) {
            let versionStds = [];
            Basket.basketFilters
                .filter((basketFilter) => basketFilter.type === "version_std")
                .forEach((basketFilter) => {
                    versionStds.push(basketFilter.value);
                });
            setVersionStdFilter(newPositiveAttributeFilter(Md.VersionStd, versionStds));
        }
    }, [Basket]);
    useEffect(() => {
        if (status !== "loading") {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [status, setIsLoading]);

    useEffect(() => {
        if (result && status === "success") {
            const slices = result?.data()?.slices().toArray();
            if (slices?.length > 0) {
                const flat = slices?.map((slice) => {
                    return {
                        Model: slice.sliceTitles()[10],
                        Trim: slice.sliceTitles()[4],
                        Player: slice.sliceTitles()[9],
                        "List Price": Number(slice.dataPoints()[1].rawValue) > 0 ? Number(Number(slice.dataPoints()[1].rawValue).toFixed(0)) : null,
                        "Adj. Rental": Number(slice.dataPoints()[23].rawValue) > 0 ? Number(Number(slice.dataPoints()[23].rawValue).toFixed(0)) : null,
                        "Adj. Deposit": Number(slice.dataPoints()[2].rawValue) > 0 ? Number(Number(slice.dataPoints()[2].rawValue).toFixed(0)) : null,
                        "Leasing Factor": 100 * Number(slice.dataPoints()[24].rawValue) > 0 ? Number((100 * Number(slice.dataPoints()[24].rawValue)).toFixed(2)) : null,
                    };
                });
                if (selectedAdjDeposit !== "Total Payable by month") {
                    setFlat(flat.filter((elem) => elem["Adj. Deposit"] !== null));
                } else {
                    setFlat(flat);
                }
                const series = flat
                    .map((elem) => {
                        return {
                            name: elem.Player,
                            color: getColorByCustId(elem.Player),
                            data: flat
                                .filter((elem2) => elem2.Player === elem.Player)
                                .map((elem2) => {
                                    return {
                                        x: elem2["List Price"],
                                        y: elem2["Adj. Rental"],
                                        z: elem2["Leasing Factor"],
                                        t: elem2.Trim,
                                        m: elem2.Model,
                                    };
                                }),
                        };
                    })
                    .filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);
                setSelectedCustomer(series.map((elem) => elem.name));
                setData(series);
            }
        }
    }, [result]);

    useEffect(() => {
        const filtered = flat?.filter((elem) => selectedCustomer.includes(elem.Player));
        const arrLP = filtered.filter((elem) => elem["Leasing Factor"] !== null).map((elem) => elem["List Price"]);
        const arrAR = filtered.filter((elem) => elem["Leasing Factor"] !== null).map((elem) => elem["Adj. Rental"]);
        const { line, r } = regression(arrAR, arrLP);
        setCorrline({ line, r });
    }, [selectedCustomer, flat]);

    // Get filters for header display
    const filterMthCode = filters[2];
    const filterCountryId = filters[1];
    const filterChannel1 = filters[0];
    const filterDuration = filters[3];

    // Format date for header
    const monthDate = filterMthCode ? moment(filterMthCode.positiveAttributeFilter.in["values"][0], "YYYY MM").format("MMMM YY") : "";

    // Get country for header
    const displayCountry = props.getCountryByCode ? props.getCountryByCode(filterCountryId?.positiveAttributeFilter?.in["values"][0]) : country;

    // Check if this component should be visible in current print mode
    const showInPrintMode = !printStatus || printMode === "all" || printMode === "lfgraph";

    if (!showInPrintMode) return null;

    return (
        <div className={printStatus ? "break-after-page" : "my-4"}>
            {/* PDF Header - only shown in print mode */}
            {printStatus && (
                <PdfHeader basketName={Basket.name} country={displayCountry} date={monthDate} channel1={filterChannel1.positiveAttributeFilter.in["values"][0]} duration={filterDuration.positiveAttributeFilter.in["values"][0]} kpi={selectedKpi?.name} adjdeposititle={props.adjdeposititle} />
            )}

            {/* Normal render for non-print mode */}
            {!printStatus && (
                <div className="p-3 rounded-md flex justify-between items-stretch cursor-pointer hover:bg-gray-200" onClick={() => setShowSummary(!showSummary)}>
                    <span className="font-semibold text-lg text-steel">Leasing factor matrix</span>
                    <UpDownButton visibilityState={showSummary} />
                </div>
            )}

            {/* Title for print mode */}
            {printStatus && <h3 className="text-xl font-bold text-center my-4">Leasing Factor Matrix</h3>}

            {/* Chart content - always shown in print mode */}
            {(showSummary || printStatus) && (
                <div className={`${printStatus ? "mt-4" : ""}`}>
                    {status === "error" && (
                        <div className="flex flex-col justify-center items-center h-full">
                            <span className="text-lg text-steel font-semibold">No data available</span>
                            <ErrorComponent />
                        </div>
                    )}
                    {status === "success" && (
                        <ScatterChart
                            pdfMode={printStatus}
                            series={data}
                            country={country}
                            title={printStatus ? null : title}
                            corrline={corrline}
                            selectedCustomer={selectedCustomer}
                            setSelectedCustomer={setSelectedCustomer}
                            filters={[...filters, versionStdFilter, custIdFilters]}
                            id={"basket-LFMatrix-chart"}
                            section={"basketLFM"}
                            kpiSettings={kpiSettings}
                            width={printStatus ? (printMode === "lfgraph" ? 1024 : 1200) : null}
                            {...props}
                        />
                    )}
                    {status === "loading" && (
                        <div className="flex justify-center items-center h-full">
                            <LoadingComponent />
                        </div>
                    )}
                </div>
            )}

            {/* PDF Footer - only shown in print mode */}
            {printStatus && (
                <div className="fixed bottom-0">
                    <BasketSummary userPref={kpiSettings} kpi={selectedKpi} />
                </div>
            )}
        </div>
    );
};

export default LFGraph;
