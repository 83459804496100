import { newAttributeSort, newPositiveAttributeFilter } from "@gooddata/sdk-model";
import { useExecutionDataView } from "@gooddata/sdk-ui";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { utils, writeFile } from "xlsx";
import { useAppContext } from "../../../contexts/AppContext";
import { useBasketContext } from "../../../contexts/BasketProvider";
import MeasuresContext from "../../../contexts/Measures";
import UserContext from "../../../contexts/User";
import * as Md from "../../../md/full";
import { newJsonPivot } from "../../../utils/jsonPivot";

const TrendHistory = ({ setisExported, selectedKpi, filterChannel1, filterCountryId, filterDuration, filterExtrapType, filterRolledForward }) => {
    const { selectedBaskets } = useBasketContext();
    const { getCustlabelByCustId } = useAppContext();
    const { seriesBy } = useContext(MeasuresContext);
    const { defaultUserPref } = useContext(UserContext);
    const [customFilterDuration] = useState(filterDuration);

    // Data used for xls export
    const [exportedData, setExportedData] = useState([]);
    const custSlots = Object.entries(defaultUserPref)
        .filter(([key, val]) => key.includes("customer_slot") && val !== null)
        .map(([key, val]) => val);
    const custIdFilters = newPositiveAttributeFilter(Md.CustId, custSlots);
    const [versionStdFilter, setVersionStdFilter] = useState(newPositiveAttributeFilter(Md.VersionStd, []));
    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: [Md.CustId, Md.VersionStd, Md.MthCode],
            filters: [versionStdFilter, filterChannel1, filterCountryId, customFilterDuration, custIdFilters, filterExtrapType, filterRolledForward],
            sortBy: [newAttributeSort(Md.CustId), newAttributeSort(Md.VersionStd), newAttributeSort(Md.MthCode)],
        },
    });
    useEffect(() => {
        if (selectedBaskets.length > 0) {
            let versionStds = [];
            selectedBaskets?.map((basket) =>
                basket.basketFilters
                    .filter((basketFilter) => basketFilter.type === "version_std")
                    .forEach((basketFilter) => {
                        versionStds.push(basketFilter.value);
                    }),
            );
            setVersionStdFilter(newPositiveAttributeFilter(Md.VersionStd, versionStds));
        }
    }, [selectedBaskets]);

    useEffect(() => {
        if (result) {
            const data = result.data().series().firstForMeasure(seriesBy[selectedKpi.index]);
            const dataPoints = data?.dataPoints().map((dp) =>
                Object.freeze({
                    row: [dp.sliceDesc.sliceTitles()[0], dp.sliceDesc.sliceTitles()[1]],
                    mthCode: dp.sliceDesc.sliceTitles()[2],
                    value: Number(dp.rawValue) === 0 ? null : selectedKpi.index === 24 ? Number((Number(dp.rawValue) * 100).toFixed(2)) : Math.round(Number(dp.rawValue)),
                }),
            );
            const tableData = newJsonPivot(dataPoints, { row: "row", column: "mthCode", value: "value" });
            let flatBaskets = selectedBaskets
                .map((basket) =>
                    basket.basketFilters.map((basketFilter) =>
                        Object.assign({
                            name: basket.name,
                            versionStd: basketFilter.value,
                        }),
                    ),
                )
                .flat();
            tableData.forEach((tdElem) => {
                let index = flatBaskets.findIndex((elem) => elem?.versionStd === tdElem?.row[1]);
                if (index !== -1) {
                    tdElem.basket = flatBaskets[index].name;
                }
            });
            tableData.forEach((tdElem) => {
                tdElem.custId = tdElem.row[0];
                tdElem.custName = getCustlabelByCustId(tdElem.row[0]);
                tdElem.versionStd = tdElem.row[1];
                tdElem.Channel = filterChannel1?.positiveAttributeFilter?.in["values"][0];
                delete tdElem.row;
            });
            tableData.sort((a, b) => {
                if (a.basket < b.basket) {
                    return -1;
                } else if (a.basket > b.basket) {
                    return 1;
                }
                return 0;
            });
            // Re-position columns
            let tableKeys = Object.keys(tableData[0]);
            let basketColumnIndex = tableKeys.findIndex((elem) => elem === "basket");
            if (basketColumnIndex !== -1) {
                let basketColumns = tableKeys.splice(basketColumnIndex, 1);
                tableKeys.splice(0, 0, ...basketColumns);
            }
            let custIdColumnIndex = tableKeys.findIndex((elem) => elem === "custId");
            if (custIdColumnIndex !== -1) {
                let custIdColumns = tableKeys.splice(custIdColumnIndex, 1);
                tableKeys.splice(1, 0, ...custIdColumns);
            }
            let custNameColumnIndex = tableKeys.findIndex((elem) => elem === "custName");
            if (custNameColumnIndex !== -1) {
                let custNameColumns = tableKeys.splice(custNameColumnIndex, 1);
                tableKeys.splice(2, 0, ...custNameColumns);
            }
            let versionStdColumnIndex = tableKeys.findIndex((elem) => elem === "versionStd");
            if (versionStdColumnIndex !== -1) {
                let versionStdColumns = tableKeys.splice(versionStdColumnIndex, 1);
                tableKeys.splice(3, 0, ...versionStdColumns);
            }
            let channelColumnIndex = tableKeys.findIndex((elem) => elem === "Channel");
            if (channelColumnIndex !== -1) {
                let channelColumns = tableKeys.splice(channelColumnIndex, 1);
                tableKeys.splice(4, 0, ...channelColumns);
            }
            //Re-position rest of columns
            let mthCodeColumns = tableKeys.filter((elem) => elem !== "basket" && elem !== "custId" && elem !== "custName" && elem !== "versionStd" && elem !== "Channel");
            mthCodeColumns.sort((a, b) => {
                if (a < b) {
                    return -1;
                } else if (a > b) {
                    return 1;
                }
                return 0;
            });
            tableKeys.splice(5, mthCodeColumns.length, ...mthCodeColumns);
            let sortedData = tableData.map((elem) => {
                let sortedElem = {};
                let newkeys = tableKeys.map((elem) => {
                    if (elem === "versionStd") {
                        return "Version";
                    } else if (elem === "custId") {
                        return "Customer Code";
                    } else if (elem === "custName") {
                        return "Customer";
                    } else {
                        return elem;
                    }
                });
                tableKeys.forEach((key) => {
                    sortedElem[newkeys[tableKeys.indexOf(key)]] = elem[key];
                });
                return sortedElem;
            });
            setExportedData(sortedData);
        }
    }, [result, status]);

    const exportData = () => {
        const ws = utils.json_to_sheet(exportedData);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Sheet1");
        writeFile(wb, "TrendHistory.xlsx");
        setisExported(false);
    };
    return <>{status === "success" && exportedData.length > 0 && exportData()}</>;
};

export default TrendHistory;
