import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import React, { useContext, useEffect, useState } from "react";
import { newJsonPivot } from "../../utils/jsonPivot";
import * as Md from "../../md/full";
import MeasuresContext, { flagServiceMap } from "../../contexts/Measures";
import { newArithmeticMeasure } from "@gooddata/sdk-model";
import { Dropdown } from "antd";
import { RiUnpinFill } from "react-icons/ri";
import { FiMoreVertical } from "react-icons/fi";
import { ErrorComponent } from "@gooddata/sdk-ui";
import { useDashboard } from "../../contexts/DashboardProvider";

const BasketBarWidget = ({ id, execDefinition, widgetUid, componentChild, ...props }) => {
    const { selectedKpi } = useContext(MeasuresContext);
    const { seriesBy: execSeries, slicesBy, filters } = execDefinition;
    const [newSeries, setNewSeries] = useState([...execSeries]);
    const { unPinwWidget } = useDashboard();
    // const [categoryColors, setCategoryColors] = useState([]);
    const [data, setData] = useState([]);
    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy: [...newSeries],
            slicesBy,
            filters,
        },
    });
    const { flag_services } = props;

    useEffect(() => {
        if (result) {
            const data = result
                ?.data()
                .series()
                .toArray()
                .find((serie) => serie.measureTitle() === newSeries[selectedKpi.index].measure.localIdentifier);
            const dataPoints = data?.dataPoints().map((dp) =>
                Object.freeze({
                    value: Number(dp.rawValue) === 0 ? null : selectedKpi.index === 24 ? Number((Number(dp.rawValue) * 100).toFixed(2)) : Math.round(Number(dp.rawValue)),
                    custId: dp.sliceDesc.sliceTitles()[0],
                    versionStd: dp.sliceDesc.sliceTitles()[1],
                }),
            );
            if (dataPoints) {
                const parsedData = newJsonPivot(dataPoints, { row: "versionStd", column: "custId", value: "value" });
                const prepasedData = newJsonPivot(dataPoints, { row: "custId", column: "versionStd", value: "value" });
                if (props.displayMode.key === "player") setData(prepasedData);
                else setData(parsedData);
            }
        }
    }, [result, selectedKpi, newSeries]);

    useEffect(() => {
        if (flag_services) {
            const enabledServices = Object.keys(flag_services)
                .filter((flService) => flService in flagServiceMap && flag_services[flService])
                .map((flService) => flagServiceMap[flService]);
            newSeries.splice(23, 1, newArithmeticMeasure([Md.FinRental.Avg, Md.AdjDeposit.Avg, ...enabledServices], "sum"));
            newSeries.splice(24, 1, newArithmeticMeasure([newArithmeticMeasure([Md.FinRental.Avg, Md.AdjDeposit.Avg, ...enabledServices], "sum"), Md.ListPrice.Avg], "ratio"));
            setNewSeries([...newSeries]);
        }
    }, [componentChild, flag_services]);

    let player;
    let keys;
    if (props.displayMode.key === "player") {
        player = data?.find((elem) => elem.custId === props.custIndex);
        keys = player && Object.keys(player).filter((key) => key !== "custId");
    } else {
        player = data?.find((elem) => elem.versionStd === props.custIndex);
        keys = player && Object.keys(player).filter((key) => key !== "versionStd");
    }
    let barChartData = keys
        ?.map((key) => {
            return {
                title: key,
                dataPoint: player[key],
            };
        })
        .filter((elem) => elem.dataPoint !== null);
    barChartData?.sort((a, b) => b.dataPoint - a.dataPoint);

    return status === "success" && data.length > 0 ? (
        React.createElement(componentChild, { data, ...props, custIndex: props.custIndex, displayMode: props.displayMode })
    ) : status === "loading" ? (
        <div className="min-h-[400px] flex items-center justify-center">
            <LoadingComponent />
        </div>
    ) : (
        <div className="relative">
            <Dropdown
                menu={{
                    items: [
                        {
                            key: "unpin",
                            icon: <RiUnpinFill />,
                            label: "Unpin widget",
                            onClick: () => unPinwWidget(widgetUid),
                        },
                    ],
                }}
                trigger={["click"]}
            >
                <FiMoreVertical className="absolute top-2 right-2 z-40 rounded w-8 h-8 px-1.5 py-1 text-gray-500 cursor-pointer hover:bg-backgroundColor hover:text-indigo-700" />
            </Dropdown>
            <div className="min-h-[400px] flex items-center justify-center">
                <ErrorComponent />
                <span className="text-sm font-medium text-steel/80 text-center py-2">
                    Seems like there is an issue with the data or the widget <br /> try unpinning and re-pinning it
                </span>
            </div>
        </div>
    );
};

export default BasketBarWidget;
