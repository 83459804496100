// The name of the application. It is rendered in a header of the web application.
const appName = "rentaleye-cloud";

// The URL of analytical GoodData backend with workspaces, insights, metrics, attributes, datasets, and users
// that will be available to the application.
const backend = "https://analytics.experteye.com";

// The ID of workspace that is selected by default in the optional workspace picker component (located in the web
// application header). It is also a default setting for script that refreshes MD data used by the application.
// MD script shows list of available workspaces on backend in the case when the value is not set to any ID.
const workspace = "gb3phb0ky787mqamchzqrnglx0dgrnct";

// RegExp used by optional workspace picker component (located in the web application header) to filter out
// workspaces that should not be rendered to the application user. Only the workspaces with title that match
// the regular expression will be rendered. Set to 'undefined' to show all the available workspaces.
// Example: /test/ or /^GoodData/
const workspaceFilter = undefined;
const auth0Domain = "auth.experteye.com";
const auth0ClientID = "QLaOmQSIFhFhoKdTL7LuQCE8yaWJA2HE";
const auth0LogoutUrl = process.env.REACT_APP_SET_HOSTNAME ? process.env.REACT_APP_RELAY_STATE : "https://localhost:3000/";
const hasuraServerUri = "https://rentaleye.hasura.app/v1/graphql";
const supportEmail = "support@experteye.com";

// Static colors used for rendering data series inside the graphs
const colors = ["#5a189a", "#1b998b", "#fb8500", "#d90368", "#758bfd", "#6d6875", "#3ab795", "#b76d68", "#1c3144", "#072ac8", "#6b9080", "#e4ba65", "#23f0c7", "#ECB365", "#0F3460", "#C84B31", "#16213E", "#03C4A1", "#E94560", "#FF0000", "#787A91", "#FFE15D", "#38E54D"];

export { appName, backend, workspace, workspaceFilter, auth0ClientID, auth0Domain, auth0LogoutUrl, hasuraServerUri, supportEmail, colors };
