import React, { useContext, useState } from "react";
import UpDownButton from "../../Buttons/UpDownButton";
import UserContext from "../../../contexts/User";
import TableComponent from "../../TableComponent";
import moment from "moment";
import { PdfHeader } from "./BasketViz";
import MeasuresContext from "../../../contexts/Measures";

/**
 * @typedef {Object} KpiSettings
 * @property {boolean} [flag_ser]
 * @property {boolean} [flag_bre]
 * @property {boolean} [flag_ins]
 * @property {boolean} [flag_tyr]
 * @property {boolean} [flag_fin]
 *
 * @typedef {Object} KpiObject
 * @property {string} [name]
 * @property {number} [id]
 */

/**
 * @param {Object} props
 * @param {KpiSettings} [props.userPref]
 * @param {KpiObject} [props.kpi]
 */
const BasketSummary = ({ userPref = {}, kpi = {}, ...props }) => {
    const summaryLabels = {
        Maintenance: userPref?.flag_ser,
        Breakdown: userPref?.flag_bre,
        Insurance: userPref?.flag_ins,
        Tyres: userPref?.flag_tyr,
        "Financial loss": userPref?.flag_fin,
    };
    let summaryLabelsIterator = Object.entries(summaryLabels);
    return (
        <span className="italic text-gray-500 text-xs font-light">
            {`Monthly Rate Incl. VAT, LCV Rental Excl. VAT,  ${kpi?.name}, `}
            {[1, 4].includes(kpi?.id) && summaryLabelsIterator.map(([key, value], index) => (value ? "Incl." : "Excl.") + " " + key + (index > summaryLabelsIterator?.length - 1 ? ", " : " "))}
        </span>
    );
};

const Summary = ({ columns, filters, dataTable, selectedBaskets, printStatus, printMode, ...props }) => {
    const { user, kpiSettings } = useContext(UserContext);
    const { selectedKpi } = useContext(MeasuresContext);
    const [showSynthesis, setShowSynthesis] = useState(user?.basket_sections?.Basket_Summary);

    // Get filters for header display
    const filterMthCode = filters[5];
    const filterCountryId = filters[2];
    const filterChannel1 = filters[1];
    const filterDuration = filters[3];

    // Format date for header
    const monthDate = filterMthCode ? moment(filterMthCode.positiveAttributeFilter.in["values"][0], "YYYY MM").format("MMMM YY") : "";

    // Get country for header
    const country = props.getCountryByCode ? props.getCountryByCode(filterCountryId?.positiveAttributeFilter?.in["values"][0]) : filterCountryId?.positiveAttributeFilter?.in["values"][0];

    // Check if this component should be visible in current print mode
    const showInPrintMode = !printStatus || printMode === "all" || printMode === "summary";

    if (!showInPrintMode) return null;

    // Create print-optimized columns with larger font size
    const printColumns = printStatus
        ? columns.map((col) => ({
              ...col,
              title: typeof col.title === "string" ? <span style={{ fontSize: "12px", fontWeight: "bold" }}>{col.title}</span> : col.title, // Preserve non-string titles (like React elements or images)
              render: col.render
                  ? (text, record) => {
                        const originalContent = col.render(text, record);
                        // If the rendered content is a string or number, wrap it in a span with adjusted font
                        if (typeof originalContent === "string" || typeof originalContent === "number") {
                            return <span style={{ fontSize: "11px" }}>{originalContent}</span>;
                        }
                        // Otherwise, return the original content
                        return originalContent;
                    }
                  : (text) => <span style={{ fontSize: "11px" }}>{text}</span>,
          }))
        : columns;

    return (
        <div className={printStatus ? "break-after-page" : "my-4"}>
            {/* Print styles */}
            {printStatus && (
                <style
                    dangerouslySetInnerHTML={{
                        __html: `
                        @media print {
                            .ant-table { font-size: 11px !important; }
                            .ant-table-thead > tr > th { 
                                padding: 6px 8px !important; 
                                height: 24px !important;
                                background-color: #f0f0f0 !important;
                                -webkit-print-color-adjust: exact !important;
                                color-adjust: exact !important;
                                print-color-adjust: exact !important;
                                font-weight: bold !important;
                                border: 1px solid #ddd !important;
                            }
                            .ant-table-tbody > tr > td { 
                                padding: 4px 8px !important; 
                                height: 20px !important; 
                                font-size: 11px !important;
                                border: 1px solid #ddd !important;
                            }
                            .summary-print-header {
                                margin-bottom: 10px !important;
                                font-size: 16px !important;
                            }
                            .ant-table-wrapper { 
                                margin-top: 8px !important;
                                margin-bottom: 20px !important;
                            }
                            /* Ensure borders are visible when printing */
                            table, th, td {
                                border-collapse: collapse !important;
                                border: 1px solid #ddd !important;
                            }
                            /* Force background colors to print */
                            * {
                                -webkit-print-color-adjust: exact !important;
                                color-adjust: exact !important;
                                print-color-adjust: exact !important;
                            }
                        }
                    `,
                    }}
                />
            )}

            {/* PDF Header - only shown in print mode */}
            {printStatus && (
                <PdfHeader
                    basketName={selectedBaskets[0]?.name || ""}
                    country={country}
                    date={monthDate}
                    channel1={filterChannel1.positiveAttributeFilter.in["values"][0]}
                    duration={filterDuration.positiveAttributeFilter.in["values"][0]}
                    kpi={selectedKpi?.name}
                    adjdeposititle={props.adjdeposititle}
                />
            )}

            {/* Normal render for non-print mode */}
            {!printStatus && (
                <div className="p-3 rounded-md flex justify-between items-stretch cursor-pointer hover:bg-gray-200" onClick={() => setShowSynthesis(!showSynthesis)}>
                    <span className="font-semibold text-lg text-steel">Summary Table</span>
                    <UpDownButton visibilityState={showSynthesis} />
                </div>
            )}

            {/* Title for print mode */}
            {printStatus && <h3 className="text-xl font-bold text-center mb-2 summary-print-header">Summary Table</h3>}

            {/* Table content - always shown in print mode */}
            {(showSynthesis || printStatus) && (
                <>
                    <TableComponent
                        enableScreenshot={!printStatus}
                        columns={printColumns}
                        dataSource={dataTable}
                        pagination={false}
                        scroll={printStatus ? {} : { y: 400 }}
                        bordered
                        className={`border border-steel-300 ${printStatus ? "print-table" : "mt-16"}`}
                        widgetProps={{
                            id: "summary-table",
                            filters: filters,
                            section: "basketsummary",
                            metadata: { selectedBaskets },
                        }}
                        {...props}
                    />
                </>
            )}

            {/* PDF Footer - only shown in print mode */}
            {printStatus && (
                <div className="fixed bottom-0">
                    <BasketSummary userPref={kpiSettings} kpi={selectedKpi} />
                </div>
            )}
        </div>
    );
};

export default Summary;
