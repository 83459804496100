import React, { useCallback, useContext, useEffect, useState } from "react";
import { newPositiveAttributeFilter } from "@gooddata/sdk-model";
import * as Md from "../md/full";
import FiltersContext from "./Filters";
import { useAppContext } from "./AppContext";

const BrandConfiguratorContext = React.createContext({
    filterSelectors: {},
    selectedPlayers: [],
    showPlayerSelectionModal: false,
    // The status of the brand model analysis query
    statusQuery: undefined,
    exportTrends: false,
    toGDFilters: () => [],
    setFilterSelector: (newConfig) => {},
    deselectConfigurator: (make, model, versions) => {},
    setSelectedPlayers: (val) => {},
    setShowPlayerSelectionModal: (val) => {},
    setStatus: (status) => {},
    setExportTrends: (val) => {},
});

const CustIDByScope = {
    "FR-B2B": {
        promo: ["OEM"],
        config: ["CAP", "COA"],
    },
    "DE-B2B": {
        promo: ["OEM"],
        config: ["CAP"],
    },
    "BE-B2B": {
        promo: ["OEM"],
        config: ["CAP"],
    },
    "IT-B2B": {
        promo: ["OEM", "OEP", "OEH"],
        config: ["CAP", "COP"],
    },
    "ES-B2B": {
        promo: ["OEM"],
        config: ["COH"],
    },
    "NL-B2B": {
        promo: ["OBH"],
        config: [],
    },
    "UK-B2B": {
        promo: [],
        config: ["CAP"],
    },
    // B2C
    "FR-B2C": {
        promo: ["OEM"],
        config: ["CAP", "COA"],
    },
    "DE-B2C": {
        promo: ["OEM"],
        config: ["CAP"],
    },
    "BE-B2C": {
        promo: ["OEM"],
        config: ["CAP"],
    },
    "IT-B2C": {
        promo: ["OEM", "OEP", "OEH"],
        config: ["CAP", "COP"],
    },
    "ES-B2C": {
        promo: ["OEM"],
        config: ["COH", "COP"],
    },
    "NL-B2C": {
        promo: ["OEH"],
        config: [],
    },
    "UK-B2C": {
        promo: ["OEP"],
        config: [],
    },
    "GB-B2C": {
        promo: ["3PO"],
        config: [],
    },
};

const BrandColors = {
    ABARTH: "#E5E4CA",
    ALPINE: "#003399",
    "ALFA ROMEO": "#C00000",
    AUDI: "#2E5CB8",
    BMW: "#A02B93",
    BYD: "#DAE9F8",
    CITROEN: "#D97710",
    CUPRA: "#9F0001",
    DACIA: "#2D4ACB",
    DS: "#945200",
    FIAT: "#8ED973",
    FORD: "#61CBF3",
    HYUNDAI: "#4D93D9",
    JAGUAR: "#3C7D22",
    JEEP: "#A98E63",
    KIA: "#BE7380",
    MADZA: "#D0D0D0",
    "MERCEDES-BENZ": "#EE66E1",
    MG: "#DAF2D0",
    MINI: "#ADADAD",
    NISSAN: "#002060",
    OPEL: "#DAE54E",
    PEUGEOT: "#4F46E5",
    POLESTAR: "#DAF2D0",
    RENAULT: "#ffd500",
    SEAT: "#8C2518",
    SKODA: "#0D9488",
    TESLA: "#FF2600",
    TOYOTA: "#FF9300",
    VOLKSWAGEN: "#45578c",
    VOLVO: "#4E8FAD",
};

const BrandConfiguratorProvider = ({ children }) => {
    const { getFilterValue } = useContext(FiltersContext);
    const { getCustlabelByCustId, getColorByCustId, customers } = useAppContext();
    const [filterSelectors, setFilterSelector] = useState({});
    // selected players for each scope
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [showPlayerSelectionModal, setShowPlayerSelectionModal] = useState(false);
    const [statusQuery, setStatus] = useState();
    const [exportTrends, setExportTrends] = useState(false);

    const toGDFilters = useCallback(() => {
        const makes = Object.keys(filterSelectors);
        let models = [];
        //  makes.map(make => Object.keys(filterSelectors[make])).flat();
        let versionStd = [];
        makes.forEach((make) => {
            models = [...models, ...Object.keys(filterSelectors[make])];
            versionStd = [
                ...versionStd,
                ...Object.values(filterSelectors[make])
                    .flat()
                    .map((versionObj) => versionObj.label),
            ];
        });
        return [newPositiveAttributeFilter(Md.CorrMake, makes), newPositiveAttributeFilter(Md.CorrModel, models), newPositiveAttributeFilter(Md.VersionStd, versionStd)];
    }, [filterSelectors]);

    // Function used in Configurator and the versions report in brand model
    const deselectConfigurator = useCallback(
        (make, model = undefined, versions = []) => {
            const tmpFilterSelectors = { ...filterSelectors };
            if (versions.length > 0) {
                tmpFilterSelectors[make][model] = [
                    ...tmpFilterSelectors[make][model].reduce((acc, versionStdObj) => {
                        if (!versions.map((newVersion) => newVersion.label).includes(versionStdObj.label)) acc.push(versionStdObj);
                        return acc;
                    }, []),
                ];
                // filter((versionStdObj) => !versions.map((newVersion) => newVersion.label).includes(versionStdObj.label));
            } else if (model) {
                tmpFilterSelectors[make] = Object.entries(tmpFilterSelectors[make])
                    .filter(([key, val]) => key !== model)
                    .reduce((acc, [key, versions]) => {
                        return {
                            ...acc,
                            [key]: versions,
                        };
                    }, {});
                // Deselect all models
                if (Object.keys(tmpFilterSelectors[make]).length === 0) delete tmpFilterSelectors[make];
            } else {
                delete tmpFilterSelectors[make];
            }
            setFilterSelector(tmpFilterSelectors);
        },
        [filterSelectors],
    );

    const custChannelKey = getFilterValue("filterCountryId").filter((val) => val !== undefined).length > 0 && getFilterValue("filterChannel1").filter((val) => val !== undefined).length > 0 ? `${getFilterValue("filterCountryId")[0]}-${getFilterValue("filterChannel1")[0]}` : undefined;

    useEffect(() => {
        // if country or channel1 is changed, initialize the selection players with the related custId, if the selection is empty
        if (custChannelKey && !(custChannelKey in selectedPlayers) && CustIDByScope[custChannelKey]) {
            const arr = CustIDByScope[custChannelKey].promo.concat(CustIDByScope[custChannelKey].config).map((custId) => ({
                custId: custId,
                custLabel: getCustlabelByCustId(custId),
                custColor: getColorByCustId(custId),
            }));
            setSelectedPlayers([...arr]);
        }
    }, [custChannelKey, customers]);

    return (
        <BrandConfiguratorContext.Provider
            value={{
                filterSelectors,
                selectedPlayers,
                showPlayerSelectionModal,
                statusQuery,
                exportTrends,
                toGDFilters,
                setFilterSelector,
                deselectConfigurator,
                setSelectedPlayers,
                setShowPlayerSelectionModal,
                setStatus,
                setExportTrends,
            }}
        >
            {children}
        </BrandConfiguratorContext.Provider>
    );
};

const useBrandConfigurator = () => useContext(BrandConfiguratorContext);

export { useBrandConfigurator, BrandConfiguratorProvider, CustIDByScope, BrandColors };
