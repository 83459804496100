import React, { useState, useEffect } from "react";
import { Modal, Select, Button, Table, Typography, Tooltip } from "antd";
import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { newMeasureValueFilter, newPositiveAttributeFilter } from "@gooddata/sdk-model";
import * as Md from "../../md/full";
import { mode, weigthedAverage } from "../../utils/calculationUtils";
import { BiInfoCircle } from "react-icons/bi";

// TODO: fix the issue with the slice titles by adding the CorrMake to the slicesBy in the mapResultToVersions function, tableVehicles.jsx and MakeModelTable.jsx and check if it fixes the issue then recheck the logic of version match

const mapResultToVersions = (slices, kpi) => {
    return slices?.map((slice) => {
        const dataPoints = slice.dataPoints();
        return {
            Version: slice.sliceTitles()[0],
            CustId: slice.sliceTitles()[1],
            "List Price": Math.round(Number(slice.dataPoints()[1].rawValue)) > 0 ? Math.round(Number(slice.dataPoints()[1].rawValue)) : null,
            CellColor: slice.sliceTitles()[2],
            Model: slice.sliceTitles()[3],
            Make: slice.sliceTitles()[8],
            Trim: slice.sliceTitles()[9],
            Value: dataPoints[kpi?.index]?.rawValue !== null ? (kpi?.index === 24 ? Math.round(10000 * Number(dataPoints[kpi?.index]?.rawValue)) / 100 : Math.round(Number(dataPoints[kpi?.index]?.rawValue))) : null,
            versionCount: Number(dataPoints[30].rawValue),
            Segment: slice.sliceTitles()[4],
            "Body Style": slice.sliceTitles()[5],
            Gear: slice.sliceTitles()[6],
            Energy: slice.sliceTitles()[7],
            Doors: slice.dataPoints()[35].rawValue,
            Kw: slice.dataPoints()[29].rawValue,
            CO2: slice.dataPoints()[34].rawValue,
            Power: slice.dataPoints()[22].rawValue,
        };
    });
};

const MapToVersionSelection = (data, kpi) => {
    if (data.length > 0) {
        // @ts-ignore
        const custIdKeys = [...new Set(data.map(({ CustId }) => CustId))];
        let vehicleOffersObj = data
            // .filter((item) => (activePlayers.length > 0 ? activePlayers.includes(item.CustId) : true))
            .reduce((acc, nextItem) => {
                const vehicleInfo = Object.entries(nextItem)
                    .filter(([key, val]) => !["CustId", "Value", "List Price"].includes(key))
                    .reduce((acc, [key, value]) => Object.assign({ [key]: value, ...acc }), {});
                if (nextItem.Version in acc && nextItem.CustId in acc[nextItem.Version]) {
                    acc[nextItem.Version][nextItem.CustId].values.push(nextItem.Value);
                    acc[nextItem.Version][nextItem.CustId].versionCount.push(nextItem.versionCount);
                } else {
                    acc[nextItem.Version] = {
                        [nextItem.CustId]: {
                            values: [nextItem.Value],
                            versionCount: [nextItem.versionCount],
                        },
                        ...vehicleInfo,
                        ...acc[nextItem.Version],
                    };
                }
                acc[nextItem.Version] = {
                    ...acc[nextItem.Version],
                    listPrices: "listPrices" in acc[nextItem.Version] ? [...acc[nextItem.Version]["listPrices"], nextItem["List Price"]] : [nextItem["List Price"]],
                };
                return acc;
            }, {});
        // Calculate the weighted average
        Object.keys(vehicleOffersObj).forEach((key) => {
            custIdKeys.forEach((custId) => {
                vehicleOffersObj[key][custId] = custId in vehicleOffersObj[key] ? kpi?.roundValue(weigthedAverage(vehicleOffersObj[key][custId]?.values, vehicleOffersObj[key][custId]?.versionCount)) : null;
            });
        });
        const dataSource = Object.values(vehicleOffersObj).map((item) => {
            const custValues = Object.keys(item)
                .filter((key) => custIdKeys.includes(key) && item[key] > 0)
                .map((key) => item[key]);
            let rentalSum = custValues.reduce((acc, currentVal) => acc + currentVal, 0);
            let Average = rentalSum > 0 ? kpi?.roundValue(rentalSum / custValues.length) : null;
            let ModListPrice = item.listPrices?.length > 0 ? Math.round(Number(mode(item.listPrices))) || null : null;

            return { Version: item.Version, "List Price": ModListPrice, Average, ...item };
        });
        return dataSource;
    }
};

const VersionMatch = ({ selectedVehicle, onCancel, seriesBy, slicesBy, kpi, filters, action = (values) => {} }) => {
    const [powerThresholdCoeff, setPowerThreshold] = useState(0.3);
    const [listpriceThreshold, setListpriceThreshold] = useState(0.2);
    const [currentStep, setCurrentStep] = useState(0);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [filteredVersions, setFilteredVersions] = useState([]);
    const [matchVersions, setMatchVersions] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selection, setSelection] = useState([selectedVehicle]);
    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState([]);
    const [selectOpen, setSelectOpen] = useState(false);
    const TotalSteps = 6;
    // Fetch brands
    const {
        status: statusBrands,
        result: resultBrands,
        error: errorBrands,
    } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: [Md.CorrMake],
            filters: [...filters],
        },
    });

    useEffect(() => {
        if (statusBrands === "success") {
            setBrands(
                resultBrands
                    .data()
                    .slices()
                    .toArray()
                    .map((item) => item.sliceTitles()[0]),
            );
            setCurrentStep(1);
        }
    }, [statusBrands, resultBrands, errorBrands]);

    // First query
    const {
        status: statusFirstQuery,
        result: resultFirstQuery,
        error: errorFirstQuery,
    } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: slicesBy,
            filters:
                selectedVehicle.Energy !== "ELECTRIC"
                    ? [
                          ...filters,
                          newPositiveAttributeFilter(Md.BodyStyle, [selectedVehicle["Body Style"]]),
                          newPositiveAttributeFilter(Md.VehType, [selectedVehicle.VehType]),
                          newPositiveAttributeFilter(Md.CorrMake, [...selectedBrands]),
                          newPositiveAttributeFilter(Md.Fuel, [selectedVehicle.Energy]),
                          newPositiveAttributeFilter(Md.GearsPlus, [selectedVehicle.Gear]),
                          newMeasureValueFilter(Md.Doors.Avg, "EQUAL_TO", Number(selectedVehicle.Doors)),
                          newMeasureValueFilter(Md.ListPrice.Avg, "BETWEEN", selectedVehicle["List Price"] - listpriceThreshold * selectedVehicle["List Price"], selectedVehicle["List Price"] + listpriceThreshold * selectedVehicle["List Price"]),
                      ]
                    : [
                          ...filters,
                          newPositiveAttributeFilter(Md.VehType, [selectedVehicle.VehType]),
                          newPositiveAttributeFilter(Md.CorrMake, [...selectedBrands]),
                          newPositiveAttributeFilter(Md.Fuel, [selectedVehicle.Energy]),
                          newPositiveAttributeFilter(Md.GearsPlus, [selectedVehicle.Gear]),
                          newMeasureValueFilter(Md.ListPrice.Avg, "BETWEEN", selectedVehicle["List Price"] - listpriceThreshold * selectedVehicle["List Price"], selectedVehicle["List Price"] + listpriceThreshold * selectedVehicle["List Price"]),
                      ],
        },
    });
    useEffect(() => {
        if (currentStep === 2 && statusFirstQuery !== "loading") {
            if (statusFirstQuery === "success") {
                const slices = resultFirstQuery?.data()?.slices()?.toArray();
                if (slices.length !== 0) {
                    setFilteredVersions(mapResultToVersions(slices, kpi).filter((version) => version["List Price"] !== null));
                }
            } else if (statusFirstQuery === "error") {
                setCurrentStep(3);
            }
        }
    }, [statusFirstQuery, currentStep, errorFirstQuery]);

    const contraryMeasures = {
        Gear: {
            MANUAL: "AUTO",
            AUTO: "MANUAL",
        },
        Energy: {
            PETROL: "HYBRID",
            DIESEL: "HYBRID",
            HYBRID: ["PETROL", "DIESEL"],
            ELECTRIC: ["PHEV", "HYBRID"],
            PHEV: ["ELECTRIC", "HYBRID"],
        },
    };
    const reboundFilters = [
        ...filters,
        newPositiveAttributeFilter(Md.BodyStyle, [selectedVehicle["Body Style"]]),
        newPositiveAttributeFilter(Md.CorrMake, [...selectedBrands]),
        newPositiveAttributeFilter(Md.GearsPlus, [contraryMeasures.Gear[selectedVehicle.Gear]]),
        newPositiveAttributeFilter(Md.VehType, [selectedVehicle.VehType]),
        newPositiveAttributeFilter(Md.Fuel, [...contraryMeasures.Energy[selectedVehicle.Energy]]),
        newMeasureValueFilter(Md.ListPrice.Avg, "BETWEEN", selectedVehicle["List Price"] - listpriceThreshold * selectedVehicle["List Price"], selectedVehicle["List Price"] + listpriceThreshold * selectedVehicle["List Price"]),
    ];

    // Rebound query
    const { status: statusReboundQuery, result: resultReboundQuery } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: slicesBy,
            filters: [...reboundFilters],
        },
    });

    useEffect(() => {
        if (currentStep === 3 && statusReboundQuery !== "loading") {
            if (statusReboundQuery === "success") {
                const slices = resultReboundQuery.data().slices().toArray();
                if (slices.length !== 0) {
                    setFilteredVersions(mapResultToVersions(slices, kpi).filter((version) => version["List Price"] !== null));
                }
            } else if (statusReboundQuery === "error") {
                setCurrentStep(4);
            }
        }
    }, [statusReboundQuery, currentStep]);

    // fetch models based on selected brands
    const {
        status: statusModels,
        result: resultModels,
        error: errorModels,
    } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: [Md.CorrModel],
            filters: [...filters, newPositiveAttributeFilter(Md.CorrMake, selectedBrands)],
        },
    });

    useEffect(() => {
        if (statusModels === "success") {
            setModels(
                resultModels
                    .data()
                    .slices()
                    .toArray()
                    .map((item) => item.sliceTitles()[0]),
            );
        }
    }, [statusModels, resultModels, errorModels]);

    // Final query
    const { status: statusFinalQuery, result: resultFinalQuery } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: slicesBy,
            filters: [...filters, newPositiveAttributeFilter(Md.CorrModel, selectedModel)],
        },
    });

    useEffect(() => {
        if (statusFinalQuery !== "loading" && currentStep === 5 && selectedModel.length > 0) {
            const slices = resultFinalQuery.data().slices().toArray();
            if (slices.length !== 0) {
                setFilteredVersions(mapResultToVersions(slices, kpi).filter((version) => version["List Price"] !== null));
            }
        }
    }, [statusFinalQuery, currentStep, selectedModel]);

    // query to get the list of versions that group by model, body, gear, and fuel of the filtered versions
    const { status: statusGroupedVersions, result: resultGroupedVersions } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: slicesBy,
            filters: [
                ...filters,
                //@ts-ignore
                newPositiveAttributeFilter(Md.CorrModel, [selectedVehicle?.Model, ...new Set(filteredVersions.map((item) => item.Model))]),
                //@ts-ignore
                newPositiveAttributeFilter(Md.BodyStyle, [selectedVehicle["Body Style"], ...new Set(filteredVersions.map((item) => item["Body Style"]))]),
                //@ts-ignore
                newPositiveAttributeFilter(Md.GearsPlus, [selectedVehicle?.Gear, ...new Set(filteredVersions.map((item) => item.Gear))]),
                //@ts-ignore
                newPositiveAttributeFilter(Md.Fuel, [selectedVehicle?.Energy, ...new Set(filteredVersions.map((item) => item.Energy))]),
            ],
        },
    });

    useEffect(() => {
        let resultedata = [];
        let MSGEgroup = [];
        let versionSelected = selectedVehicle;
        if (filteredVersions.length !== 0 && statusGroupedVersions === "success") {
            const slices = resultGroupedVersions.data().slices().toArray();
            if (slices.length !== 0) {
                MSGEgroup = mapResultToVersions(slices, kpi);
                // Group by model, body, gear, and fuel
                const groupedVersions = MSGEgroup.filter((version) => version["List Price"] !== null).reduce((acc, curr) => {
                    const key = `${curr.Model}-${curr["Body Style"]}-${curr.Gear}-${curr.Energy}`;
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push(curr);
                    return acc;
                }, {});

                // Calculate median list price for each group
                const medianListPrices = Object.keys(groupedVersions).reduce((acc, key) => {
                    const group = groupedVersions[key];
                    const sortedListPrices = group.map((version) => version["List Price"] || 0).sort((a, b) => a - b);
                    const mid = Math.floor(sortedListPrices.length / 2);
                    acc[key] = sortedListPrices.length % 2 !== 0 ? sortedListPrices[mid] : (sortedListPrices[mid - 1] + sortedListPrices[mid]) / 2;
                    return acc;
                }, {});

                // Calculate the selected range index
                const selectedRangeIndex = selectedVehicle["List Price"] / medianListPrices[`${selectedVehicle.Model}-${selectedVehicle["Body Style"]}-${selectedVehicle.Gear}-${selectedVehicle.Energy}`];
                versionSelected["rangeIndex"] = selectedRangeIndex;
                // Filter versions based on power
                const filteredByPower = filteredVersions.filter((version) => {
                    const power = Number(version.Power);
                    const selectedPower = Number(selectedVehicle.Power);
                    const powerThreshold = selectedPower * powerThresholdCoeff;
                    version["rangeIndex"] = version["List Price"] / medianListPrices[`${version.Model}-${version["Body Style"]}-${version.Gear}-${version.Energy}`];
                    version["rangeIndexGap"] = Math.abs(version["rangeIndex"] - selectedRangeIndex);
                    return power >= selectedPower - powerThreshold && power <= selectedPower + powerThreshold;
                });
                // Filter by range index
                // const filterByRangeIndex = (versions) => {
                //     return versions
                //         .map((version) => {
                //             const groupKey = `${version.Model}-${version.Style}-${version.Gear}-${version.Energy}`;
                //             const medianListPrice = medianListPrices[groupKey];
                //             const rangeIndex = (version["List Price"] || 0) / medianListPrice;
                //             version["rangeIndexGap"] = Math.abs(rangeIndex - selectedRangeIndex);
                //             version["rangeIndex"] = rangeIndex;
                //             return version;
                //         })
                //         .filter((version) => {
                //             return version["rangeIndexGap"] <= rangeIndexCoeff;
                //         });
                // };
                // Apply filters in the desired order
                if (filteredByPower.length > 0) {
                    // Group by Make and Model and Trim
                    const groupedData = filteredByPower.reduce((acc, curr) => {
                        const key = `${curr.Make}-${curr.Model}`;
                        if (!acc[key]) {
                            acc[key] = [];
                        }
                        acc[key].push(curr);
                        return acc;
                    }, {});
                    // Sort by both power difference and range index difference
                    const selectedData = Object.values(groupedData)
                        .map((group) => {
                            // Sort by both power difference and range index difference
                            const sortedGroup = group.sort((a, b) => {
                                const powerDiffA = Math.abs(Number(a.Power) - Number(selectedVehicle.Power));
                                const powerDiffB = Math.abs(Number(b.Power) - Number(selectedVehicle.Power));
                                const rangeIndexDiffA = Math.abs(a.rangeIndex - selectedVehicle.rangeIndex);
                                const rangeIndexDiffB = Math.abs(b.rangeIndex - selectedVehicle.rangeIndex);

                                // Normalize the differences (giving equal weight to both factors)
                                const normalizedScoreA = powerDiffA / Number(selectedVehicle.Power) + rangeIndexDiffA;
                                const normalizedScoreB = powerDiffB / Number(selectedVehicle.Power) + rangeIndexDiffB;

                                return normalizedScoreA - normalizedScoreB;
                            });
                            // select the top 1 closest matches
                            const closestMatch = sortedGroup.slice(0, 1);
                            // return the group of the closest match
                            const groupOfClosestMatch = sortedGroup.filter((item) => item.Version === closestMatch[0].Version);
                            return groupOfClosestMatch;
                        })
                        .flat();

                    resultedata = MapToVersionSelection(selectedData, kpi);
                }
            }
            if (resultedata.length > 0) {
                setMatchVersions([versionSelected, ...resultedata]);
                if (![1, 4].includes(currentStep)) {
                    setCurrentStep(TotalSteps);
                }
            } else {
                setMatchVersions([versionSelected, ...MapToVersionSelection(filteredVersions, kpi)]);
                setCurrentStep(TotalSteps);
            }
        }
    }, [filteredVersions, statusGroupedVersions, powerThresholdCoeff, listpriceThreshold]);

    useEffect(() => {
        if (matchVersions.length > 0) {
            const columns = ["Version", "List Price", "Range index", "Range index Gap", "Power", "Power Gap", "Average", "Segment", "Body Style", "Gear", "Energy", "Doors", "Kw", "CO2"];
            setColumns(
                columns.map((m) => {
                    const custIDs = matchVersions.map((item) => item.CustId);
                    return {
                        title: () => (
                            <div>
                                {["Version", "List Price", "Average"].includes(m) ? (
                                    <span className="text-xs">{m === "Average" ? "Average Adj. Rental" : m}</span>
                                ) : m === "Power Gap" ? (
                                    <span className="flex-1 text-xs self-center">{`${m} \n ${powerThresholdCoeff * 100}%`}</span>
                                ) : m === "Range index" ? (
                                    <div className="flex items-center gap-1">
                                        <span className="flex-1 text-xs self-center">{m}</span>
                                        <Tooltip title="The Range index is the version list price divided by the median list price of all versions of the same model with same energy type, gear and body style. It indicates how the version is positioned in the range: low, medium of high trim.">
                                            <BiInfoCircle className="text-indigo-700 w-4 h-4 hover:opacity-50" />
                                        </Tooltip>
                                    </div>
                                ) : (
                                    <span className="flex-1 text-xs self-center">{m}</span>
                                )}
                            </div>
                        ),
                        fixed: ["Version"].includes(m) ? "left" : null,
                        align: m !== "Version" && "center",
                        dataIndex: m,
                        key: m,
                        width: m === "Version" ? 250 : ["List Price", "Power", "Average"].includes(m) ? 120 : !custIDs.includes(m) ? (m.length > 8 ? m.length * 10 : 100) : 100,
                        render: (cell, record) => {
                            let cellContent = cell;
                            if (m === "Power Gap" && record.Power !== null) {
                                const diffPercent = Math.round((Number(record.Power) / Number(selectedVehicle.Power) - 1) * 100);
                                cellContent = `${diffPercent} %`;
                            } else if (m === "Range index Gap" && record["rangeIndexGap"] > 0) {
                                cellContent = `${Number(record["rangeIndexGap"] * 100).toFixed(2)}%`;
                            } else if (m === "Range index" && record["rangeIndex"] > 0) {
                                cellContent = `${Number(record["rangeIndex"]).toFixed(2)}`;
                            }
                            return (
                                <Typography.Text
                                    style={{
                                        fontSize: 10,
                                        color: record.Version === selectedVehicle.Version ? "#fff" : "",
                                    }}
                                    ellipsis={{
                                        tooltip: {
                                            title: cellContent,
                                            placement: "topLeft",
                                        },
                                    }}
                                >
                                    {cellContent?.toLocaleString("en-US", { useGrouping: false })}
                                </Typography.Text>
                            );
                        },
                        filters: !custIDs.includes(m)
                            ? null
                            : [
                                  {
                                      text: "Show values only",
                                      value: null,
                                  },
                              ],

                        onFilter: (value, record) => (["Version", "List Price", "Average"].includes(m) ? value : record[m] !== value),
                        ...(m === "Range index Gap" && {
                            sorter: (a, b) => {
                                // Skip sorting if either record is the selected vehicle
                                if (a.Version === selectedVehicle.Version || b.Version === selectedVehicle.Version) {
                                    return 0;
                                }
                                return a.rangeIndexGap - b.rangeIndexGap;
                            },
                            sortDirections: ["ascend", "descend"],
                            defaultSortOrder: "ascend",
                        }),
                        // ...(m === "List Price" && {
                        //     sorter: (a, b) => {
                        //         // Skip sorting if either record is the selected vehicle
                        //         if (a.Version === selectedVehicle.Version || b.Version === selectedVehicle.Version) {
                        //             return 0;
                        //         }
                        //         return (a["List Price"] || 0) - (b["List Price"] || 0);
                        //     },
                        //     sortDirections: ["ascend", "descend"],
                        // }),
                        ...(m === "Power Gap" && {
                            sorter: (a, b) => {
                                const diffPercentA = Math.round((Number(a.Power) / Number(selectedVehicle.Power) - 1) * 100);
                                const diffPercentB = Math.round((Number(b.Power) / Number(selectedVehicle.Power) - 1) * 100);
                                return diffPercentA - diffPercentB;
                            },
                            sortDirections: ["ascend", "descend"],
                        }),
                        ...(m === "Range index" && {
                            sorter: (a, b) => {
                                return Number(a["rangeIndex"]) - Number(b["rangeIndex"]);
                            },
                            sortDirections: ["ascend", "descend"],
                        }),
                        ...(!["Range index Gap", "Power Gap", "Range index"].includes(m) && {
                            sorter: (a, b) => {
                                if (typeof a[m] === "string" && typeof b[m] === "string") {
                                    return a[m].localeCompare(b[m]);
                                } else {
                                    return Number(a[m]) - Number(b[m]);
                                }
                            },
                            sortDirections: ["ascend", "descend"],
                        }),
                    };
                }),
            );
        }
    }, [matchVersions, selectedVehicle.Version, powerThresholdCoeff]);

    const handleBrandSelection = (value) => {
        if (value.length <= 12) {
            setSelectedBrands(value);
        } else {
            // If the new selection exceeds 12, keep only the first 12 items
            setSelectedBrands(value.slice(0, 12));
        }
        setSelectOpen(false);
    };

    const handleModelSelection = (value) => {
        setSelectedModel(value);
        setSelectOpen(false);
    };

    const nextStep = () => {
        if (currentStep === 1 && (selectedBrands.length === 0 || selectedBrands.length > 12)) {
            return;
        }
        if (currentStep === 4 && selectedModel.length === 0) {
            return;
        }
        setCurrentStep((prevStep) => prevStep + 1);
    };

    const handleBackStep = () => {
        if (currentStep > 0) {
            if ([4, TotalSteps].includes(currentStep)) {
                setCurrentStep(1);
            } else {
                return;
            }
        }
    };
    const resetState = () => {
        setCurrentStep(0);
        setSelectedBrands([]);
        setFilteredVersions([]);
        setMatchVersions([]);
        setSelection([]);
        setBrands([]);
        setModels([]);
        setSelectedModel([]);
        setPowerThreshold(0.3);
        setListpriceThreshold(0.2);
    };

    const handleCancel = () => {
        resetState();
        onCancel();
    };

    const renderStep = () => {
        switch (currentStep) {
            case 0:
                return (
                    <div className="text-center">
                        <span className="text-indigo-700 text-base font-medium">Initializing ...</span>
                        <LoadingComponent />
                    </div>
                );
            case 1:
                return (
                    <div className="flex flex-col gap-3 curs">
                        <div>
                            <h2>Brand Selection</h2>
                            <Select mode="multiple" style={{ width: "100%" }} allowClear placeholder="Select brands" value={selectedBrands} onChange={handleBrandSelection} maxTagCount={12} open={selectOpen} onDropdownVisibleChange={setSelectOpen}>
                                {brands.map((brand) => (
                                    <Select.Option key={brand} value={brand}>
                                        {brand}
                                    </Select.Option>
                                ))}
                            </Select>
                            <span className="text-secondary-300 text-xs block mt-2 mb-2">* Select up to 12 brands </span>
                        </div>
                        <div className="flex flex-col justify-center gap-2">
                            <div className="text-center flex w-1/4 justify-between">
                                <div className="flex gap-1 items-center">
                                    <Tooltip title="Power threshold is the percentage of power difference between the selected vehicle and the matched vehicle.">
                                        <BiInfoCircle className="text-indigo-700 w-5 h-5 hover:opacity-50" />
                                    </Tooltip>
                                    <span className=" text-base font-medium">Power (+/-) :</span>
                                </div>
                                <div className="flex gap-1">
                                    <input type="number" className="w-20 px-2 py-0.5 text-center font-medium border border-indigo-700 rounded-md" value={Number((powerThresholdCoeff * 100).toFixed(1))} onChange={(e) => setPowerThreshold(Number(Number(e.target.value) / 100))} step="5" min="0" />
                                    <span className=" text-base font-medium">%</span>
                                </div>
                            </div>
                            <div className="text-center flex  w-1/4 justify-between">
                                <div className="flex gap-1 items-center">
                                    <Tooltip title="List price threshold is the percentage of the price difference between the selected vehicle and the matched vehicle.">
                                        <BiInfoCircle className="text-indigo-700 w-5 h-5 hover:opacity-50" />
                                    </Tooltip>
                                    <span className=" text-base font-medium">List Price (+/-) :</span>
                                </div>
                                <div className="flex gap-1">
                                    <input type="number" className="w-20 px-2 py-0.5 text-center font-medium border border-indigo-700 rounded-md" value={Number((listpriceThreshold * 100).toFixed(1))} onChange={(e) => setListpriceThreshold(Number(Number(e.target.value) / 100))} step="5" min="0" />
                                    <span className=" text-base font-medium">%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="text-center">
                        <span className="text-indigo-700 text-base font-medium">Searching for exact matches ...</span>
                        <LoadingComponent />
                    </div>
                );
            case 3:
                return (
                    <div className="text-center">
                        <span className="text-indigo-700 text-base font-medium">Searching for exact matches ...</span>
                        <LoadingComponent />
                    </div>
                );
            case 4:
                return (
                    <div>
                        <h3>Version Match Not Found</h3>
                        <p className="text-secondary-300">Please select a set of models to find a match:</p>
                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Select model" onChange={handleModelSelection} open={selectOpen} onDropdownVisibleChange={setSelectOpen}>
                            {models.map((model) => (
                                <Select.Option key={model} value={model}>
                                    {model}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                );
            case 5:
                return (
                    <div className="text-center">
                        <span className="text-indigo-700 text-base font-medium">Searching for similar versions ...</span>
                        <LoadingComponent />
                    </div>
                );
            case TotalSteps:
                return (
                    <div>
                        <style>{`
                                .selected-row td {
                                    background-color: #3a0ca3 !important;
                                }
                                .ant-table-row-disabled > td {
                                    cursor: not-allowed;
                                }
                            `}</style>
                        <span className="text-indigo-700 text-base font-medium">Selected Brands : {selectedBrands.join(", ")}</span>
                        <Table
                            dataSource={[selectedVehicle, ...matchVersions.filter((v) => v.Version !== selectedVehicle.Version)]}
                            columns={columns}
                            rowKey={(record) => record.Version}
                            pagination={false}
                            size="small"
                            scroll={{ y: 600 }}
                            rowSelection={{
                                type: "checkbox",
                                onSelect: (record, selected, selectedRows, nativeEvent) => {
                                    setSelection([...selectedRows.filter((row) => row?.Version)]);
                                },
                                onSelectAll: (selected, selectedRows, changeRows) => {
                                    setSelection([...selectedRows.filter((row) => row?.Version)]);
                                },
                                selectedRowKeys: selection.map((item) => item.Version),
                            }}
                            rowClassName={(record, index) => {
                                if (record.Version === selectedVehicle.Version) return "ant-table-row-disabled selected-row";
                                return "";
                            }}
                            loading={matchVersions.length === 0}
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <Modal
            title="Magic Version Match"
            width={"100%"}
            open={true}
            onCancel={handleCancel}
            footer={[
                <Button key="back" type="primary" className="w-fit" onClick={handleBackStep} disabled={![TotalSteps, 4].includes(currentStep)}>
                    Back
                </Button>,
                <Button
                    key="next"
                    type="primary"
                    className="w-fit"
                    onClick={
                        currentStep === TotalSteps
                            ? () => {
                                  action(selection);
                                  handleCancel();
                              }
                            : nextStep
                    }
                    disabled={
                        (currentStep === TotalSteps && selection.length <= 1) || (currentStep === 1 && (selectedBrands.length === 0 || selectedBrands.length > 12)) || (currentStep === 4 && selectedModel.length === 0) || currentStep === 0 || currentStep === 2 || currentStep === 3 || currentStep === 5
                    }
                >
                    {currentStep === TotalSteps ? "Select" : "Next"}
                </Button>,
            ]}
        >
            {renderStep()}
        </Modal>
    );
};

export default VersionMatch;
