import React, { useContext, useEffect, useRef, useState } from "react";

import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import * as Md from "../../md/full";
import HighchartsComponent from "../HighchartsComponent";
import { newAbsoluteDateFilter, newAttributeSort } from "@gooddata/sdk-model";
import { useAppContext } from "../../contexts/AppContext";
import { useUserContext } from "../../contexts/User";
import { Switch } from "antd";
import FiltersContext from "../../contexts/Filters";
import { MinusOutlined } from "@ant-design/icons";

/**
 * Return the chart of trends with volumes and the pie chart for showing the distribution of volumes for a single month
 */
function CarSelectorOverviewWrapper({ seriesBy, filters, selectedKpi, ...props }) {
    const { defaultUserPref } = useUserContext();
    const { xAxisMode, setXAxisMode } = useAppContext();
    const [periodVolumeChart, setperiodVolumeChart] = useState(undefined);
    const [volumeSeries, setVolumeSeries] = useState([]);

    const { getCustlabelByCustId, getColorByCustId, dateRange } = useAppContext();
    const formattedDateRange = dateRange.map((dt) => dt.format("YYYY-MM-DD"));
    let queryFilters = [...filters];

    if (formattedDateRange.length === 2) {
        queryFilters = [...filters, newAbsoluteDateFilter(Md.DateDatasets.DateOfScraping.ref, formattedDateRange[0], formattedDateRange[1])];
    }

    const slicePeriodAttribute = Boolean(defaultUserPref?.fl_weekly) ? sliceAttributeOptions[xAxisMode] : Md.MthCode;
    const vizOptions = [
        {
            label: "As Aggregated",
            value: "As Aggregated",
            slicesBy: [slicePeriodAttribute],
            sortBy: slicePeriodAttribute,
        },
        {
            label: "By Players",
            value: "By Players",
            slicesBy: [slicePeriodAttribute, Md.CustId],
            sortBy: slicePeriodAttribute,
        },
    ];
    const [vizPlayerMode, setPlayerVizMode] = useState(vizOptions[0]);
    const ref = useRef(null);

    const chartContainerWidth = ref?.current?.getBoundingClientRect().width;
    const [documentWidth, setDocumentWidth] = useState(document.body.getBoundingClientRect().width);

    // Query used to get the volumes for each player at the month level
    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy,
            slicesBy: [slicePeriodAttribute, Md.CustId],
            filters: queryFilters,
            sortBy: [newAttributeSort(vizOptions.find((item) => item.value === vizPlayerMode.value).sortBy, "asc")],
        },
    });

    function resizeWindow() {
        setDocumentWidth(document.body.getBoundingClientRect().width);
    }

    useEffect(() => {
        // To fix responsiveness of the chart when resizing the browser
        window.addEventListener("resize", resizeWindow);
        return () => {
            window.removeEventListener("resize", resizeWindow);
        };
    }, []);

    useEffect(() => {
        const slices = result?.data().slices().toArray();
        const dataTmp = slices?.map((slice) =>
            Object.assign({
                period: slice.sliceTitles()[0],
                custId: slice.sliceTitles()[1],
                value: slice.dataPoints()[selectedKpi.index].rawValue !== null ? selectedKpi.roundValue(slice.dataPoints()[selectedKpi.index].rawValue) : null,
                offerCount: slice.dataPoints()[33].rawValue !== null ? Number(slice.dataPoints()[33].rawValue) : null,
            }),
        );
        const periods = slices?.reduce((acc, slice) => {
            const period = slice.sliceTitles()[0];
            if (!acc.includes(period)) acc.push(period);
            return acc;
        }, []);
        const series = transformQueryToVolumeData(dataTmp, periods, getCustlabelByCustId, getColorByCustId);
        // .sort((a, b) => a.localeCompare(b));
        setVolumeSeries(series);
        // return () => {
        //     setVolumeSeries([]);
        //     setperiodVolumeChart(undefined);
        // }
        return () => {
            setVolumeSeries([]);
        };
    }, [result, vizPlayerMode, periodVolumeChart]);

    return (
        <div className="grid grid-cols-4">
            <div className={(status === "loading" ? "opacity-40 select-none pointer-events-none" : "") + ` col-span-full flex gap-14 items-center ml-6 mb-6`}>
                <div className="flex flex-row gap-4 items-center">
                    <fieldset className="space-x-4">
                        <Switch
                            checked={vizPlayerMode.value === "By Players"}
                            onChange={() => setPlayerVizMode(vizOptions.find((item) => item.value !== vizPlayerMode.value))}
                            size="small"
                            style={{
                                backgroundColor: vizPlayerMode.value === "By Players" ? "#3a0ca3" : "#EEF1FF",
                            }}
                        />
                        <label className={(vizPlayerMode.value === "By Players" ? "text-indigo-500" : "text-[#495057]") + " text-base font-medium"}>Display By Players</label>
                    </fieldset>
                </div>
                {defaultUserPref?.fl_weekly && (
                    <div className="flex flex-row gap-4 items-center">
                        <fieldset className="space-x-4">
                            <Switch
                                checked={xAxisMode === "monthly"}
                                onChange={() => setXAxisMode(xAxisMode === "monthly" ? "weekly" : "monthly")}
                                size="small"
                                style={{
                                    backgroundColor: xAxisMode === "monthly" ? "#3a0ca3" : "#EEF1FF",
                                }}
                            />
                            <label className={(xAxisMode === "monthly" ? "text-indigo-500" : "text-[#495057]") + " text-base font-medium"}>Show as Monthly</label>
                        </fieldset>
                    </div>
                )}
            </div>
            <div className={periodVolumeChart && volumeSeries.find((obj) => obj.period === periodVolumeChart) ? "col-span-3 w-full" : "col-span-full"} ref={ref}>
                <CarSelectorOverview
                    seriesBy={seriesBy}
                    slicesBy={vizOptions.find((item) => item.value === vizPlayerMode.value).slicesBy}
                    sortBy={vizOptions.find((item) => item.value === vizPlayerMode.value).sortBy}
                    filters={queryFilters}
                    selectedKpi={selectedKpi}
                    onSelectBar={setperiodVolumeChart}
                    width={periodVolumeChart && volumeSeries.find((obj) => obj.period === periodVolumeChart) && chartContainerWidth >= (documentWidth * 3) / 4 ? (chartContainerWidth * 3) / 4 : chartContainerWidth || null}
                />
            </div>
            {periodVolumeChart && volumeSeries.find((obj) => obj.period === periodVolumeChart) && (
                <div className="relative">
                    <button onClick={() => setperiodVolumeChart(null)} className="absolute z-10 top-0 right-0" title="Minimize distribution chart">
                        <MinusOutlined className="w-6 h-6 hover:bg-indigo-100 p-1 rounded-md transition-colors" />
                    </button>
                    <OfferVolumeChart periodLabel={periodVolumeChart} dataSeries={volumeSeries.find((obj) => obj.period === periodVolumeChart)?.data} />
                </div>
            )}
        </div>
    );
}

function CarSelectorOverview({ seriesBy, filters, slicesBy, sortBy, selectedKpi, onSelectBar = (mthCode) => {}, ...props }) {
    const [lineSeries, setLineSeries] = useState([]);
    const [categories, setCategories] = useState([]);
    const [volumeSeries, setVolumeSeries] = useState([]);
    const { getCustlabelByCustId, getColorByCustId } = useAppContext();
    const { getFirstFilterValue } = useContext(FiltersContext);
    const chartRef = useRef();
    const countryId = getFirstFilterValue("filterCountryId");
    const { defaultUserPref } = useUserContext();
    const id = `car-selector-overview-${Boolean(defaultUserPref?.fl_weekly) ? "weekly" : "monthly"}`;

    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy,
            slicesBy,
            filters,
            sortBy: [newAttributeSort(sortBy, "asc")],
        },
    });

    const generateSubTitle = ({ isTrends = false }) => {
        return [
            countryId,
            getFirstFilterValue("filterDuration"),
            getFirstFilterValue("filterChannel1"),
            !isTrends ? getFirstFilterValue("filterMthCode") : null,
            selectedKpi.name,
            !selectedKpi.name.toLowerCase().includes("leasing factor") && ["UK", "GB"].includes(countryId) ? "GBP" : !selectedKpi.name.toLowerCase().includes("leasing factor") ? "EUR" : null,
        ]
            .filter((item) => item !== null)
            .join(", ");
    };

    useEffect(() => {
        const slices = result?.data().slices().toArray();
        const dataTmp = slices?.map((slice) =>
            Object.assign({
                mthCode: slice.sliceTitles()[0],
                custId: slice.sliceTitles()[1],
                value: slice.dataPoints()[selectedKpi.index].rawValue !== null ? selectedKpi.roundValue(slice.dataPoints()[selectedKpi.index].rawValue) : null,
                versionCount: slice.dataPoints()[33].rawValue !== null ? Number(slice.dataPoints()[33].rawValue) : null,
            }),
        );

        const periods = slices?.reduce((acc, slice) => {
            const period = slice.sliceTitles()[0];
            if (!acc.includes(period)) acc.push(period);
            return acc;
        }, []);
        // .sort((a, b) => a.localeCompare(b));

        const series = dataTmp?.reduce((acc, item) => {
            const custLabel = item.custId ? getCustlabelByCustId(item.custId) : "Average";
            const color = getColorByCustId(item.custId);
            const index = acc.findIndex((obj) => obj.name === custLabel);
            const periodIndex = periods.findIndex((mth) => mth === item.mthCode);
            if (index !== -1) {
                acc[index].data[periodIndex] = item.value;
            } else {
                const dataPoints = new Array(periods.length).fill(null);
                dataPoints[periodIndex] = item.value;
                acc = [
                    ...acc,
                    Object.assign({
                        name: custLabel,
                        // color: color,
                        type: "spline",
                        yAxis: 0,
                        data: dataPoints,
                        color: {
                            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                            stops: color
                                ? [[0, color]]
                                : [
                                      [0, "#6B50B6"],
                                      [0.5, "#8C6BC4"],
                                      [1, "#AD87D2"],
                                  ],
                        },
                        originalColor: {
                            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                            stops: color
                                ? [[0, color]]
                                : [
                                      [0, "#6B50B6"],
                                      [0.5, "#8C6BC4"],
                                      [1, "#AD87D2"],
                                  ],
                        },
                        lineWidth: color ? 2 : 3,
                        shadow: true,
                    }),
                ];
            }
            return acc;
        }, []);
        // const series = Object.assign({
        //     name: "Player average",
        //     data: mthCodes.map((mth, index) => lineSeries?.map(line => line.data[index]).reduce((acc, val) => acc + val,0) / lineSeries?.map(line => line.data[index]).length
        // ),
        // });

        // Volumes
        const volumes = periods?.map((mthCode) => dataTmp.filter((obj) => obj.mthCode === mthCode).reduce((acc, obj) => acc + obj.versionCount, 0));

        if (series?.length > 0) {
            setLineSeries(series);
            setCategories(periods);
            setVolumeSeries(volumes);
        }
        return () => {
            setCategories([]);
            setLineSeries([]);
            setVolumeSeries([]);
        };
    }, [result, selectedKpi]);

    return (
        <>
            {status === "loading" ? (
                <div className="w-full h-[400px] flex flex-col items-center justify-center gap-3">
                    <div className="min-h-[400px] flex items-center justify-center gap-3">
                        <LoadingComponent />
                        <span className="font-medium text-base text-[#666]">Loading Overview Graph</span>
                    </div>
                </div>
            ) : (
                <>
                    <HighchartsComponent
                        ref={chartRef}
                        widgetProps={{
                            id,
                            filters,
                        }}
                        options={{
                            chart: {
                                zoomType: "xy",
                                type: "spline",
                                backgroundColor: "#f9f9f9",
                                borderRadius: 10,
                                reflow: true, // Critical for responsiveness
                                width: props.width,
                                height: 580,
                                spacingTop: 23,
                                shadow: {
                                    color: "rgba(0,0,0,0.1)",
                                    offsetX: 0,
                                    offsetY: 4,
                                    opacity: 0.2,
                                    width: 10,
                                },
                                events: {
                                    load: function () {
                                        // Force resize on load
                                        this.reflow();
                                    },
                                },
                            },
                            title: {
                                text: props?.widgetUid ? null : `Filters Selection - Average ${selectedKpi.name} and Offers Volumes`,
                                style: {
                                    color: "#666",
                                    fontWeight: 600,
                                    fontSize: "18px",
                                },
                            },
                            subtitle: {
                                text: props?.widgetUid ? null : generateSubTitle({ isTrends: true }),
                                style: {
                                    color: "#666",
                                },
                            },
                            xAxis: {
                                type: "datetime",
                                categories: categories,
                                lineColor: "#999",
                                tickColor: "#999",
                                labels: {
                                    style: {
                                        color: "#666",
                                    },
                                },
                            },
                            yAxis: [
                                {
                                    min: 0,
                                    title: {
                                        text: selectedKpi.name,
                                        style: {
                                            color: "#666",
                                        },
                                    },
                                    gridLineColor: "#e6e6e6",
                                    labels: {
                                        style: {
                                            color: "#666",
                                        },
                                    },
                                },
                                {
                                    title: {
                                        text: "Volumes",
                                        style: {
                                            color: "#666",
                                        },
                                    },
                                    gridLineColor: "#e6e6e6",
                                    labels: {
                                        style: {
                                            color: "#666",
                                        },
                                    },
                                    opposite: true,
                                },
                            ],
                            tooltip: {
                                backgroundColor: "#ffffff",
                                borderColor: "#7cb5ec",
                                borderRadius: 10,
                                style: {
                                    color: "#333",
                                },
                            },
                            plotOptions: {
                                column: {
                                    pointWidth: 17,
                                    borderWidth: 0,
                                    borderRadius: 2,
                                    shadow: {
                                        color: "rgba(0,0,0,0.1)",
                                        offsetX: 0,
                                        offsetY: 2,
                                        opacity: 0.3,
                                        width: 3,
                                    },
                                    cursor: "pointer",
                                    point: {
                                        events: props?.widgetUid
                                            ? {}
                                            : {
                                                  click: function (event) {
                                                      this.series.data.forEach((point) => {
                                                          point.update(
                                                              {
                                                                  color: point.originalColor,
                                                              },
                                                              false,
                                                          );
                                                      });
                                                      this.update(
                                                          {
                                                              color: "#ff4d6d",
                                                          },
                                                          true,
                                                      );
                                                      onSelectBar(event.point.category.name);
                                                  },
                                              },
                                    },
                                },
                                spline: {
                                    marker: {
                                        symbol: "circle",
                                    },
                                },
                                series: {
                                    connectNulls: true,
                                },
                            },
                            series: [
                                {
                                    name: "Volume Offers",
                                    type: "column",
                                    yAxis: 1,
                                    data: volumeSeries,
                                    color: {
                                        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                                        stops: [
                                            [0, "#A0A0A0"],
                                            [1, "#D3D3D3"],
                                        ],
                                    },
                                },
                                ...lineSeries,
                            ],
                            credits: {
                                enabled: false,
                            },
                            legend: {
                                backgroundColor: "#ffffff",
                                borderColor: "#e6e6e6",
                                borderRadius: 5,
                                itemStyle: {
                                    color: "#333",
                                },
                            },
                        }}
                        menuButtonPosition={props?.widgetUid ? "outside" : "inside"}
                        menuLocation={{
                            top: "10px",
                        }}
                    />
                </>
            )}
        </>
    );
}

function OfferVolumeChart({ periodLabel, dataSeries }) {
    return (
        <HighchartsComponent
            options={{
                chart: {
                    type: "pie",
                    height: 430,
                    spacingTop: 23,
                    backgroundColor: "transparent",
                    style: {
                        fontFamily: "Inter, Arial, sans-serif",
                    },
                },
                title: {
                    text: `Offers Volume Distribution by Player`,
                    margin: 50,
                    style: {
                        color: "#666",
                        fontWeight: 600,
                        fontSize: "15px",
                    },
                },
                subtitle: {
                    text: `Period ${periodLabel}`,
                    y: 45,
                    style: {
                        color: "#666",
                    },
                },
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.y}</b>",
                    backgroundColor: "rgba(255,255,255,0.9)",
                    borderColor: "#ccc",
                    borderRadius: 10,
                    shadow: true,
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        dataLabels: {
                            enabled: true,
                            format: "{point.percentage:.1f}%",
                            style: {
                                color: "white",
                                fontWeight: "bold",
                                textOutline: "1px contrast",
                                fontSize: "12px",
                            },
                            distance: -35, // Negative distance places labels inside
                            filter: {
                                // Only show labels for slices above a certain percentage
                                property: "percentage",
                                operator: ">",
                                value: 5,
                            },
                        },
                        innerSize: "25%",
                        colors: ["#6366F1", "#10B981", "#F43F5E", "#F59E0B", "#6B7280"],
                    },
                },
                series: [
                    {
                        name: "Volume offers",
                        colorByPoint: true,
                        data: dataSeries,
                    },
                ],
                responsive: {
                    rules: [
                        {
                            condition: {
                                maxWidth: 500,
                            },
                            chartOptions: {
                                plotOptions: {
                                    pie: {
                                        innerSize: "25%",
                                        // dataLabels: {
                                        //     enabled: false
                                        // }
                                    },
                                },
                            },
                        },
                    ],
                },
                credits: {
                    enabled: false,
                },
            }}
            menuButtonPosition="inside"
            enableScreenshot={false}
        />
    );
}

function transformQueryToOverviewData(data, periods = [], formatLabel = (custId) => custId, getColor = (custId) => undefined) {
    return data?.reduce((acc, item) => {
        const custLabel = item.custId ? formatLabel(item.custId) : "Average";
        const color = getColor(item.custId);
        const index = acc.findIndex((obj) => obj.name === custLabel);
        const periodIndex = periods.findIndex((mth) => mth === item.mthCode);
        if (index !== -1) {
            acc[index].data[periodIndex] = item.value;
        } else {
            const dataPoints = new Array(periods.length).fill(null);
            dataPoints[periodIndex] = item.value;
            acc = [
                ...acc,
                Object.assign({
                    name: custLabel,
                    // color: color,
                    type: "spline",
                    yAxis: 0,
                    data: dataPoints,
                    color: {
                        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                        stops: color
                            ? [[0, color]]
                            : [
                                  [0, "#6B50B6"],
                                  [0.5, "#8C6BC4"],
                                  [1, "#AD87D2"],
                              ],
                    },
                    lineWidth: color ? 2 : 3,
                    shadow: true,
                }),
            ];
        }
        return acc;
    }, []);
}

function transformQueryToVolumeData(data, periods = [], formatLabel = (custId) => custId, getColor = (custId) => undefined) {
    const custIds = Array.from(new Set(data?.map((obj) => obj.custId)));

    return periods.map((period) => {
        return Object.assign({
            period: period,
            data: custIds.map((custId) =>
                Object.assign({
                    name: formatLabel(custId),
                    color: getColor(custId),
                    y: data.find((obj) => obj.custId === custId && obj.period === period)?.offerCount,
                }),
            ),
        });
    });
}

const sliceAttributeOptions = {
    monthly: Md.MthCode,
    weekly: Md.PeriodCat,
};

export { CarSelectorOverviewWrapper, CarSelectorOverview };
