import React, { useContext, useEffect, useState } from "react";
import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { CustIDByScope, useBrandConfigurator } from "../../contexts/BrandConfiguratorProvider";
import MeasuresContext from "../../contexts/Measures";
import HighchartsComponent from "../HighchartsComponent";
import * as Md from "../../md/full";
import FiltersContext from "../../contexts/Filters";
import { calculationModes, transformSlicesToBrandTree } from "../../routes/BrandModelAnalysis";
import { calculateModelAvg } from "../../utils/calculationUtils";
import _ from "lodash";
import { useAppContext } from "../../contexts/AppContext";

const widgetId = "brand-model-column-chart";

const ColumnModelAnalysis = ({ filters, calculationHandler, selectedPlayers, ...props }) => {
    const { filterSelectors } = useBrandConfigurator();
    const { selectedKpi } = useContext(MeasuresContext);
    const { getFirstFilterValue } = useContext(FiltersContext);
    // Group by make
    const [series, setSeries] = useState([]);
    const [categories, setCategories] = useState([]);

    const countryId = getFirstFilterValue("filterCountryId");
    const channel1 = getFirstFilterValue("filterChannel1");

    useEffect(() => {
        if (selectedPlayers.length > 0) {
            const preparedData = Object.entries(filterSelectors).reduce((acc, [make, modelsObject]) => {
                const flatModelsArr = Object.entries(modelsObject).reduce((accModel, [model, versionArr]) => {
                    selectedPlayers.forEach((p) => {
                        const versionValuesByCustId = versionArr.filter((v) => v[p.custId] && v[p.custId][selectedKpi.index] > 0).map((v) => v[p.custId][selectedKpi.index]);
                        const custIdVal = versionValuesByCustId.length > 0 ? selectedKpi.roundValue(calculationHandler(versionValuesByCustId)) : null;
                        const index = accModel.findIndex((o) => o.make === make && o.model === model);
                        if (index !== -1) {
                            accModel[index][p.custId] = custIdVal;
                        } else {
                            accModel.push({
                                make: make,
                                model: model,
                                [p.custId]: custIdVal,
                                color: "white",
                                borderColor: "#3a0ca3",
                                borderWidth: 2,
                            });
                        }
                    });
                    return accModel;
                }, []);
                // Find the min for each model
                const calculatedData = flatModelsArr.map((modelObj) => ({
                    ...modelObj,
                    y: _.min(
                        Object.entries(modelObj)
                            .filter(([key, _]) => selectedPlayers.map((p) => p.custId).includes(key))
                            .map(([_, val]) => val),
                    ),
                }));
                acc = [...acc, ...calculatedData];
                return acc;
            }, []);

            const categoriesArr = preparedData.reduce((acc, obj) => {
                const index = acc.findIndex((o) => o.name === obj.make);
                if (index !== -1) {
                    acc[index].categories.push(obj.model);
                } else {
                    acc.push({
                        name: obj.make,
                        categories: [obj.model],
                    });
                }
                return acc;
            }, []);

            setCategories([...categoriesArr]);
            setSeries([
                {
                    name: "Cheapest",
                    // colors: colorsGrouping,
                    colorByPoint: true,
                    data: preparedData.flat(),
                    enableMouseTracking: false,
                    // showInLegend: false,
                },
                ...selectedPlayers.map((p) => ({
                    name: p.custLabel,
                    type: "spline",
                    color: p.custColor,
                    data: preparedData.map((obj) => (p.custId in obj ? obj[p.custId] : null)),
                    showInLegend: preparedData.find((obj) => p.custId in obj) !== undefined,
                    marker: {
                        symbol: "circle",
                        fillColor: p.custColor,
                    },
                })),
            ]);
        }
    }, [filterSelectors, selectedKpi, calculationHandler, selectedPlayers]);

    return series?.length > 0 && categories.length > 0 ? (
        <>
            <ColumnChartWithNestedLabels categories={categories} series={series} filters={filters} menuButtonPosition={"inside"} {...props} />
            {props.footnote && <span className="pl-3 pb-2 text-left text-xs font-medium">{props.footnote}</span>}
        </>
    ) : (
        <LoadingComponent />
    );
};

const ColumnModelAnalysisWidget = ({ filters, calculationMode = "average", ...props }) => {
    const { selectedKpi, seriesBy } = useContext(MeasuresContext);
    const { getFirstFilterValue } = useContext(FiltersContext);
    const { getCustlabelByCustId, getColorByCustId } = useAppContext();

    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy,
            slicesBy: [Md.CorrMake, Md.CorrModel, Md.CustId, Md.VersionStd],
            filters,
        },
    });

    // Group by make
    const [series, setSeries] = useState([]);
    const [categories, setCategories] = useState([]);

    const countryId = getFirstFilterValue("filterCountryId");
    const channel1 = getFirstFilterValue("filterChannel1");
    const playerFilter = filters.find((obj) => obj.positiveAttributeFilter.displayForm.identifier === Md.CustId.attribute.displayForm.identifier);
    const playerCustIDs = playerFilter?.positiveAttributeFilter.in.values.length > 0 ? playerFilter.positiveAttributeFilter.in.values : ["OEM", "CAP"];
    const selectedPlayers = playerCustIDs.map((custId) => ({
        custId: custId,
        custLabel: getCustlabelByCustId(custId),
        custColor: getColorByCustId(custId),
    }));

    useEffect(() => {
        const slices = result?.data().slices().toArray();
        const dataByBrand = transformSlicesToBrandTree(slices);
        if (dataByBrand) {
            const preparedData = Object.entries(dataByBrand).reduce((acc, [make, modelsObject]) => {
                const flatModelsArr = Object.entries(modelsObject).reduce((accModel, [model, versionArr]) => {
                    selectedPlayers.forEach((p) => {
                        const versionValuesByCustId = versionArr.filter((v) => v[p.custId]).map((v) => v[p.custId][selectedKpi.index]);
                        const custIdVal = versionValuesByCustId.find((val) => val > 0) ? selectedKpi.roundValue(calculationModes[calculationMode].calculationMethod(versionValuesByCustId)) : null;
                        const index = accModel.findIndex((o) => o.make === make && o.model === model);
                        if (index !== -1) {
                            accModel[index][p.custId] = custIdVal;
                        } else {
                            accModel.push({
                                make: make,
                                model: model,
                                [p.custId]: custIdVal,
                                color: "white",
                                borderColor: "#3a0ca3",
                                borderWidth: 2,
                            });
                        }
                    });
                    return accModel;
                }, []);
                // Find the min for each model
                const calculatedData = flatModelsArr.map((modelObj) => ({
                    ...modelObj,
                    y: _.min(
                        Object.entries(modelObj)
                            .filter(([key, _]) => selectedPlayers.map((p) => p.custId).includes(key))
                            .map(([_, val]) => val),
                    ),
                }));
                acc = [...acc, ...calculatedData];
                return acc;
            }, []);

            const categoriesArr = preparedData.reduce((acc, obj) => {
                const index = acc.findIndex((o) => o.name === obj.make);
                if (index !== -1) {
                    acc[index].categories.push(obj.model);
                } else {
                    acc.push({
                        name: obj.make,
                        categories: [obj.model],
                    });
                }
                return acc;
            }, []);
            setCategories([...categoriesArr]);
            setSeries([
                {
                    name: "Cheapest",
                    // colors: colorsGrouping,
                    colorByPoint: true,
                    data: preparedData.flat(),
                    enableMouseTracking: false,
                    // showInLegend: false,
                },
                ...selectedPlayers.map((p) => ({
                    name: p.custLabel,
                    type: "spline",
                    color: p.custColor,
                    data: preparedData.map((obj) => (p.custId in obj ? obj[p.custId] : null)),
                    showInLegend: preparedData.find((obj) => p.custId in obj) !== undefined,
                    marker: {
                        symbol: "circle",
                        fillColor: p.custColor,
                    },
                })),
            ]);
        }
    }, [result, calculationMode]);

    return status !== "loading" && series?.length > 0 && categories.length > 0 ? (
        <ColumnChartWithNestedLabels categories={categories} series={series} selectedPlayers={selectedPlayers} {...props} />
    ) : (
        <div className="flex flex-col items-center justify-center gap-2 min-h-[400px]">
            <div>
                <LoadingComponent />
            </div>
            <span>Loading...</span>
        </div>
    );
};

const ColumnChartWithNestedLabels = ({ categories, series, ...props }) => (
    <HighchartsComponent
        widgetProps={{
            id: widgetId,
            filters: props.filters,
            section: "oem-views",
            calculationMode: props.calculationMode,
        }}
        options={{
            chart: {
                type: "column",
                // width: width,
                height: props.height || 500,
                events: {
                    click: function () {
                        // deactivate tooltip
                        this.series.forEach((serie) => {
                            Object.keys(serie.chart)
                                .filter((key) => key.includes("customTooltip"))
                                .forEach((key) => {
                                    serie.chart[key]?.destroy();
                                    serie.chart[key] = undefined;
                                });
                        });
                    },
                },
            },
            plotOptions: {
                column: {
                    maxPointWidth: 45,
                    // color: "#3a0ca3",
                    colorByPoint: true,
                    borderWidth: 0,
                    groupPadding: 0,
                    pointPadding: 0.1,
                    dataLabels: {
                        enabled: true,
                    },
                },
                spline: {
                    lineWidth: 0,
                    states: {
                        hover: {
                            enabled: false,
                        },
                    },
                    marker: {
                        radius: 6,
                        fillColor: "#ff7d93",
                    },

                    cursor: "pointer",
                    point: {
                        events: {
                            click: function () {
                                const chart = this.series.chart;
                                const point = this;
                                // const tooltipName = `tooltip-${point.x}`;
                                const tooltipName = `customTooltip`;
                                if (chart[tooltipName]) {
                                    // destroy the old one when rendering new
                                    chart[tooltipName].destroy();
                                    chart[tooltipName] = undefined;
                                }
                                // else {
                                // @ts-ignore
                                const tooltipContent = props.brandConfigurator[point.category.parent.name][point.category.name]
                                    .filter((item) => Object.keys(item).includes(...props.custIdLabels[point.series.name]))
                                    .map((item) => item.label)
                                    .sort((a, b) => b.length - a.length);
                                chart[tooltipName] = chart.renderer
                                    .label(tooltipContent.join("<br />"), point.plotX < chart.plotWidth * 0.7 ? chart.plotLeft + point.plotX : point.plotX - 5 * tooltipContent[0].length, point.plotY < chart.plotHeight * 0.5 ? point.plotY : point.plotY - 5 * tooltipContent.length)
                                    .attr({
                                        "stroke-width": 1,
                                        zIndex: 30,
                                        stroke: point.series.color,
                                        padding: 8,
                                        r: 3,
                                        fill: "rgb(247, 247, 247)",
                                    })
                                    .css({
                                        "font-size": "10px",
                                    })
                                    .add(chart.rGroup);
                                // }
                            },
                        },
                    },
                },
            },
            title: {
                text: props.title,
                align: "center",
                style: {
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "#3a0ca3",
                },
            },
            subtitle: {
                text: props.subtitle,
                align: "center",
                style: {
                    fontSize: "12px",
                    fontWeight: 600,
                    color: "#506e96",
                },
            },
            legend: {
                padding: 10,
                margin: 20,
                symbolRadius: 0,
                symbolWidth: 0,
                squareSymbol: false,
                useHTML: true,
                labelFormatter: function () {
                    return this.name === "Cheapest"
                        ? `<div style="display:flex; gap:3px; align-items: center; margin: 4px 2px;"><div style="width:12px; height:12px; border: 2px solid #3a0ca3;"></div> <span>${this.name}</span></div>`
                        : `<div style="display:flex; gap:3px; align-items: center; margin: 4px 2px;"><div style="width:11px; height:11px; border-radius: 20px; background-color: ${this.color};"></div> <span>${this.name}</span></div>`;
                },
            },
            tooltip: {
                formatter: function () {
                    return `${this.x.parent.name}, ${this.x.name}<br/>${this.series.name} <b>${this.y}</b>`;
                },
            },
            xAxis: {
                categories: categories,
                labels: {
                    // align: 'center',
                    groupedOptions: [
                        {
                            rotation: 0,
                            style: {
                                paddingTop: "10px",
                                margin: "100px",
                            },
                        },
                        {
                            rotation: 0,
                            style: {
                                paddingTop: "10px",
                                fontSize: "4px",
                            },
                        },
                    ],
                    rotation: 0,
                    style: {
                        paddingTop: "10px",
                        paddingBottom: "0px",
                        fontFamily: "Verdana, sans-serif",
                    },
                },
                title: {
                    text: null,
                },
                // min: -0.75,
                // max: Object.keys(filterSelectors).length-1 + 0.75
            },
            yAxis: {
                // labels: {
                //     enabled: false,
                // },
                title: {
                    text: null,
                },
                min: 0,
                // max: series[0].y * 1.1,
                // tickAmount: null,
            },
            series: [...series],
            lang: {
                noData: "No data available",
            },
            noData: {
                style: {
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "#595959",
                },
            },
        }}
        {...props}
    />
);

export { ColumnModelAnalysis, ColumnModelAnalysisWidget };
