import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { newAttributeSort, newPositiveAttributeFilter } from "@gooddata/sdk-model";
import { message, Tooltip } from "antd";
import FiltersContext from "../contexts/Filters";
import MeasuresContext from "../contexts/Measures";

import BarVehicles from "../components/Vehicles/BarVehicles";
import LineVehicles from "../components/Vehicles/LineVehicles";
import AdjustmentVehicles from "../components/Vehicles/AdjustmentVehicles";
import { useBasketContext } from "../contexts/BasketProvider";

import { MakeModelTable } from "../components/Vehicles/MakeModelTable";
import { TableVehicles } from "../components/Vehicles/TableVehicles";
import UserContext from "../contexts/User";
import { useCarSelector } from "../contexts/CarSelectorProvider";
// import BenchmarkDuration from "../components/Vehicles/BenchmarkDuration";
import { ActionButton } from "../components/Buttons/PrimaryButton";
import * as Md from "../md/full";
import MakeModelOverview from "../components/Vehicles/MakeModelOverview";
import MakeModelDetailedView from "../components/Vehicles/MakeModelDetailView";
import VatIndicator from "../components/VatIndicator";
import VehicleExport from "../components/Vehicles/VehiclesExport";
import { CarSelectorOverviewWrapper } from "../components/Vehicles/CarSelectorOverview";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useAppContext } from "../contexts/AppContext";

function Vehicles() {
    const { currentFilter, filterCountryId, filterChannel1, currentFilterWithNoPeriodFrequency, currentFilterHistoryWithNoPeriodFrequency, currentFilterHistory, getFiltersForFootnote, getFilterValue } = useContext(FiltersContext);
    const { seriesBy, selectedKpi, getScopeForTitle, getScopeForTitleHistory } = useContext(MeasuresContext);
    const { selectedVehicles, customFields, hiddenPlayersField, setSelectedVehicles, setSelectedModels } = useCarSelector();
    const { user, showVersionCatalog, kpiSettings, defaultUserPref } = useContext(UserContext);
    const { showOverviewGraph, setShowOverviewGraph, xAxisMode, displayRecords } = useAppContext();
    const { addVehicles } = useBasketContext();
    const [isVisualizeSelection, setVisualizeSelection] = useState(false);
    // State related to track the loading status of the data in the nested table
    const [loadingStatus, setLoadingStatus] = useState(undefined);
    const [showSelectedVehicles, setShowSelectedVehicles] = useState(false);
    // selectedVehicles | allVehicles | undefined
    const [exportMode, setExportMode] = useState(undefined);
    const vizRef = useRef(null);
    const titleHistory = getScopeForTitleHistory();
    const title = getScopeForTitle();
    const footnote = getFiltersForFootnote();
    const filterCustIDValues = getFilterValue("filterCustLabel");
    const [custIdFilter, setCustIdFilter] = useState(newPositiveAttributeFilter(Md.CustId, hiddenPlayersField?.players ? [...hiddenPlayersField?.players] : []));

    function addToBasket() {
        const basketVehicles = selectedVehicles.map((item) => {
            item.Vehicle = item?.Version;
            return item;
        });
        try {
            let addVehicleRes = addVehicles(basketVehicles);
            if (addVehicleRes) {
                message.info({
                    content: `Vehicle has been added to basket`,
                    style: {
                        right: "20px",
                        top: "70px",
                        position: "absolute",
                    },
                    duration: 1,
                });
            }
        } catch (e) {
            message.error({
                content: e?.message,
                style: {
                    right: "20px",
                    top: "70px",
                    position: "absolute",
                },
                duration: 1,
            });
        }
    }

    function selectMultipleModels(modeleVersions) {
        // setBasketVehicles([...modeleVersions]);
    }

    const onVisualizeSelection = useCallback(() => {
        !isVisualizeSelection && setVisualizeSelection(!isVisualizeSelection);
        vizRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, [vizRef]);

    const onClearSelection = () => {
        setSelectedVehicles([]);
        setSelectedModels([]);
        setShowSelectedVehicles(false);
    };

    const onExportSelection = () => {
        setExportMode("selectedVehicles");
    };

    const actionItems = [
        {
            formatTitle: () => (!showSelectedVehicles ? `Show selection (${selectedVehicles.length})` : "Show all"),
            handler: () => setShowSelectedVehicles(!showSelectedVehicles),
            isEnable: user?.enable_multiselection,
        },
        {
            formatTitle: () => "Add to basket",
            handler: addToBasket,
            isEnable: true,
        },
        {
            formatTitle: () => "Visualize my selection",
            handler: onVisualizeSelection,
            isEnable: true,
        },
        {
            formatTitle: () => "Export selection",
            handler: onExportSelection,
            isEnable: user?.enable_multiselection,
        },

        {
            formatTitle: () => "Clear selection",
            handler: onClearSelection,
            isEnable: true,
        },
    ];

    // Forcing filters to refresh
    const periodHistoryFiltersVehicleRules = useMemo(() => {
        return Boolean(defaultUserPref?.fl_weekly) ? (xAxisMode === "monthly" ? [...currentFilterHistory] : [...currentFilterHistoryWithNoPeriodFrequency]) : [...currentFilterHistory];
    }, [defaultUserPref, currentFilterHistory, currentFilterHistoryWithNoPeriodFrequency, xAxisMode]);

    const filtersAdjustmentVehicleRules = useMemo(() => {
        return Boolean(defaultUserPref?.fl_weekly) ? (displayRecords === "latestWeek" ? [...currentFilter] : [...currentFilterWithNoPeriodFrequency]) : [...currentFilter];
    }, [defaultUserPref, currentFilter, currentFilterWithNoPeriodFrequency, displayRecords]);

    useEffect(() => {
        // reset selected vehicles when country and channel1 filters are updated
        setSelectedVehicles([]);
        setLoadingStatus(undefined);
        setShowSelectedVehicles(false);
    }, [filterCountryId, filterChannel1]);

    useEffect(() => {
        isVisualizeSelection && vizRef?.current.scrollIntoView();
    }, [isVisualizeSelection]);

    useEffect(() => {
        setCustIdFilter(newPositiveAttributeFilter(Md.CustId, hiddenPlayersField?.players ? [...hiddenPlayersField?.players] : []));
    }, [hiddenPlayersField]);

    return (
        <div className="flex flex-col items-stretch gap-4">
            {
                <>
                    <div className="flex items-center gap-2">
                        <h3 className="text-indigo-700">{`Car Selector - ${title}`}</h3>
                        <VatIndicator />
                        <Tooltip
                            title={!showOverviewGraph ? "Show Overview Graph" : "Hide Overview Graph"}
                            color="#6B50B6"
                            placement="bottomLeft"
                            overlayInnerStyle={{
                                fontSize: "14px",
                                fontWeight: 500,
                                // color: "#03071e",
                                boxShadow: "1px 1px 3px #506e96",
                            }}
                        >
                            {showOverviewGraph ? (
                                <IoIosArrowUp className="cursor-pointer w-10 h-10 p-2 rounded text-[#495057] hover:bg-gray-200" onClick={() => setShowOverviewGraph(!showOverviewGraph)} />
                            ) : (
                                <IoIosArrowDown className="cursor-pointer w-10 h-10 p-2 rounded text-[#495057] hover:bg-gray-200" onClick={() => setShowOverviewGraph(!showOverviewGraph)} />
                            )}
                        </Tooltip>
                    </div>
                </>
            }
            {showOverviewGraph && <CarSelectorOverviewWrapper filters={periodHistoryFiltersVehicleRules} seriesBy={seriesBy} selectedKpi={selectedKpi} />}
            {/* {/* Selection actions */}
            {loadingStatus === "success" && selectedVehicles.length > 0 && (
                <div className={`${showOverviewGraph ? "mt-4" : "mt-2"} flex items-center gap-3`}>{actionItems.map((actionItem) => actionItem.isEnable && <ActionButton title={actionItem.formatTitle()} onClick={() => actionItem.handler()} />)}</div>
            )}
            {showVersionCatalog || showSelectedVehicles ? (
                <TableVehicles
                    defaultElements={showSelectedVehicles && selectedVehicles}
                    showLegends={showVersionCatalog}
                    seriesBy={[...seriesBy, Md.Emissions.Avg, Md.Doors.Avg]}
                    slicesBy={[Md.VersionStd, Md.CustId, Md.FontBackColor, Md.CorrModel, Md.VehSegment, Md.BodyStyle, Md.GearsPlus, Md.Fuel, Md.CorrMake, Md.VehType]}
                    filters={currentFilter}
                    kpi={selectedKpi}
                    onSelectVehicle={(vehicle) => {
                        // setBasketVehicles([vehicle]);
                    }}
                    onLoadData={() => setLoadingStatus("success")}
                    isPaginated={true}
                    footnote={footnote}
                    enableMultiSelection={user?.enable_multiselection}
                />
            ) : (
                <MakeModelTable onSelectMultipleRows={selectMultipleModels} footnote={footnote} onLoadData={() => setLoadingStatus("success")} enableMultiselection={user?.enable_multiselection} />
            )}
            {exportMode !== undefined && (
                <VehicleExport
                    seriesBy={seriesBy}
                    slicesBy={[Md.VersionStd, Md.CustId, Md.FontBackColor, Md.CorrModel]}
                    filters={[...currentFilter, newPositiveAttributeFilter(Md.VersionStd, exportMode === "selectedVehicles" ? [...selectedVehicles?.map((item) => item?.Version)] : [])]}
                    kpi={selectedKpi}
                    onDownloadCallback={() => {
                        setExportMode(undefined);
                    }}
                />
            )}
            {selectedVehicles?.length > 0 && (
                <section ref={vizRef}>
                    {selectedVehicles?.length === 1 ? (
                        <>
                            <div className="mt-8">
                                <div className="flex items-center gap-2">
                                    <h3 className="text-indigo-700">{`Rentals - ${title}`}</h3>
                                    <VatIndicator />
                                </div>
                                <h4 className="text-indigo-700">{selectedVehicles?.[0]?.Version}</h4>
                                <BarVehicles
                                    seriesBy={seriesBy}
                                    slicesBy={[Md.CustId, Md.Channel3]}
                                    filters={[...currentFilter, newPositiveAttributeFilter(Md.VersionStd, [...selectedVehicles?.map((item) => item?.Version)]), filterCustIDValues.length <= 0 ? custIdFilter : null]}
                                    kpiSettings={kpiSettings}
                                    customFields={customFields}
                                />
                                <p className="text-left text-xs font-medium">{footnote}</p>
                            </div>
                            <div className="mt-8">
                                <div className="flex items-center gap-2">
                                    <h3 className="text-indigo-700">{`Rental Trends - ${titleHistory}`}</h3>
                                    <VatIndicator />
                                </div>
                                <h4 className="text-indigo-700">{selectedVehicles?.[0]?.Version}</h4>
                                <LineVehicles
                                    seriesBy={[...seriesBy, Md.CustId]}
                                    slicesBy={[Md.DateDatasets.DateOfScraping.Date.YyyyMmDd]}
                                    filters={[...periodHistoryFiltersVehicleRules, newPositiveAttributeFilter(Md.VersionStd, [...selectedVehicles?.map((item) => item?.Version)]), filterCustIDValues.length <= 0 ? custIdFilter : null]}
                                    kpiSettings={kpiSettings}
                                    customFields={customFields}
                                    sortBy={[newAttributeSort(Md.DateDatasets.DateOfScraping.Date.YyyyMmDd, "asc")]}
                                />
                                <p className="text-left text-xs font-medium">{footnote}</p>
                            </div>

                            {/* <div className="mt-8">
                            <h3 className="text-indigo-700">{`Benchmark Duration - ${title}`}</h3>
                            <h4 className="text-indigo-700">{selectedVehicles[0].Version}</h4>
                            <BenchmarkDuration seriesBy={seriesBy} filters={[...currentFilter.slice(0, 5), ...currentFilter.slice(6), newPositiveAttributeFilter(Md.VersionStd, [...selectedVehicles?.map((item) => item.Version)])]} kpi={selectedKpi} />
                            <p className="mt-5 text-left text-xs font-medium">{footnote}</p>
                        </div> */}

                            <div className="relative mt-8">
                                <div className="flex items-center gap-2">
                                    <h3 className="text-indigo-700">{`Rental Breakdown - ${title}`}</h3>
                                    <VatIndicator />
                                </div>
                                <h4 className="text-indigo-700 mb-4">{selectedVehicles[0]?.Version}</h4>
                                <AdjustmentVehicles
                                    seriesBy={seriesBy}
                                    footnote={footnote}
                                    selectedVehicle={selectedVehicles[0]?.Version}
                                    filters={[...filtersAdjustmentVehicleRules, newPositiveAttributeFilter(Md.VersionStd, [...selectedVehicles?.map((item) => item?.Version)]), filterCustIDValues.length <= 0 ? custIdFilter : null]}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="mt-8 space-y-6">
                                <h3 className="text-indigo-700">{`Synthesis Graph | ${title}`}</h3>
                                <MakeModelOverview
                                    selectedVehicles={selectedVehicles}
                                    seriesBy={seriesBy}
                                    filters={[...currentFilter, newPositiveAttributeFilter(Md.VersionStd, [...selectedVehicles?.map((item) => item?.Version)]), filterCustIDValues.length <= 0 ? custIdFilter : null]}
                                    footnote={footnote}
                                    title={`Synthesis Graph | ${title}`}
                                    kpiSettings={kpiSettings}
                                    customFields={customFields}
                                />
                            </div>
                            <div className="mt-8 space-y-6">
                                <h3 className="text-indigo-700">{`Detailed View | ${titleHistory}`}</h3>
                                <MakeModelDetailedView
                                    selectedVehicles={selectedVehicles}
                                    filters={[...filtersAdjustmentVehicleRules, newPositiveAttributeFilter(Md.VersionStd, [...selectedVehicles?.map((item) => item?.Version)]), filterCustIDValues.length <= 0 ? custIdFilter : null]}
                                    footnote={footnote}
                                />
                            </div>
                        </>
                    )}
                </section>
            )}
        </div>
    );
}

function RecordSwitchParameter(props) {
    const { displayRecords, setDisplayRecords } = useAppContext();
    const options = [
        {
            label: "Show latest in the month",
            value: "latestWeek",
        },
        {
            label: "Show all records in the month",
            value: "all",
        },
    ];

    return (
        ["all", "latestWeek"].includes(displayRecords) && (
            <div className={`flex items-center gap-8 ml-6 mb-6 ${props.className}`}>
                {options.map((option) => (
                    <fieldset className="flex flex-row gap-1 items-center">
                        <input
                            type="radio"
                            name="displayRecords"
                            id={option.value}
                            style={{
                                accentColor: "#6B50B6",
                            }}
                            onChange={() => setDisplayRecords(option.value)}
                            checked={option.value === displayRecords}
                        />
                        <label htmlFor={option.value} className={(option.value === displayRecords ? "text-indigo-500" : "cursor-pointer hover:text-steel") + " text-base font-medium"}>
                            {option.label}
                        </label>
                    </fieldset>
                ))}
            </div>
        )
    );
}

const tableLegendItems = [
    {
        label: "Regular Rental",
        color: "#000000",
    },
    {
        label: "From Previous Month(s)",
        color: "#D9D9D9",
        isbackground: true,
    },
    {
        label: "Special Promotion",
        color: "#FF0000",
    },
    {
        label: "PCP Rental",
        color: "#0070C0",
    },
    {
        label: "Extrap. from similar version",
        color: "#FCE4D6",
        isbackground: true,
    },
];

export { Vehicles, RecordSwitchParameter, tableLegendItems };
