import React, { useContext } from "react";
import FiltersContext from "../contexts/Filters";
import { BsInfoCircle } from "react-icons/bs";
import { Tooltip } from "antd";

const VatIndicator = () => {
    const { filterChannel1, filterCountryId } = useContext(FiltersContext);
    const country = filterCountryId?.positiveAttributeFilter?.in?.values[0];
    const channel = filterChannel1?.positiveAttributeFilter?.in?.values[0];
    const vatrule = ["FR", "FF"].includes(country)
        ? " Leasing rates include VAT for Passenger Cars, exclude VAT for LCV & CDV. All list prices include VAT, regardless of vehicle type. "
        : country === "NL"
        ? " Leasing Rates exclude VAT and Listprices include VAT and the BPM tax amount"
        : channel === "B2B"
        ? " VAT excluded"
        : " VAT included";
    return (
        <Tooltip title={vatrule} color="#6B50B6" placement="right" overlayStyle={{ minWidth: vatrule.split(" ").length > 10 ? "320px" : null }}>
            <BsInfoCircle className="w-5 h-5 text-indigo-700 cursor-help pb-1" />
        </Tooltip>
    );
};

export default VatIndicator;
