import React from "react";
import { LoadingComponent } from "@gooddata/sdk-ui";
import HighchartsComponent from "../HighchartsComponent";

/**
 * Generic overview graph component for bar charts with scatter points
 * @param {Object} props
 * @param {Array} props.data - Main data series
 * @param {Array} props.scatterData - Scatter data series
 * @param {string} props.title - Chart title
 * @param {boolean} props.loading - Loading state
 * @param {number} props.height - Chart height
 */
const OverviewGraph = ({ data = [], scatterData = [], title = "", loading = false, height = 450 }) => {
    const categories = data?.map((item) => item.name) || [];

    return (
        <div className="w-full">
            {loading ? (
                <LoadingComponent />
            ) : (
                <HighchartsComponent
                    enableScreenshot={false}
                    options={{
                        chart: {
                            height: categories.length < 6 ? height : 600,
                            type: "bar",
                        },
                        plotOptions: {
                            bar: {
                                borderRadius: 3.0,
                                dataLabels: {
                                    enabled: true,
                                    align: "right",
                                    x: -20,
                                    color: "#FFFFFF",
                                    formatter: function () {
                                        return this.y !== 0 ? this.y : null;
                                    },
                                },
                            },
                            scatter: {
                                stickyTracking: false,
                                marker: {
                                    radius: 6,
                                },
                            },
                        },
                        title: {
                            text: title,
                            align: "center",
                            style: {
                                fontSize: "13px",
                                fontWeight: "600",
                                color: "#506e96",
                            },
                        },
                        tooltip: {
                            formatter: function () {
                                if (this.series.type === "scatter") {
                                    return `<b>${this.series.name}</b><br/>${this.y}`;
                                }
                                return `<span>${this.x}</span><br/><em>Value:</em> ${this.y}`;
                            },
                        },
                        series: [
                            {
                                name: "Cheapest",
                                data: data,
                                color: "#506e96",
                                pointWidth: data.length < 12 ? 30.0 : 25.0,
                            },

                            ...scatterData,
                        ],
                        xAxis: {
                            categories: categories,
                            labels: {
                                style: {
                                    fontWeight: 600,
                                    fontSize: "12px",
                                    color: "#595959",
                                },
                            },
                        },
                        yAxis: {
                            title: {
                                text: "Value",
                                style: {
                                    color: "#506e96",
                                },
                            },
                            labels: {
                                style: {
                                    color: "#506e96",
                                },
                            },
                        },
                        legend: {
                            enabled: true,
                            symbolRadius: 0,
                            symbolHeight: 16,
                            labelFormatter: function () {
                                return `<span style="color: ${this.color}">${this.name}</span>`;
                            },
                        },
                    }}
                />
            )}
        </div>
    );
};

export default OverviewGraph;
