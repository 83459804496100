import React from "react";
import HighchartsComponent from "../HighchartsComponent";
import { useAppContext } from "../../contexts/AppContext";
import Highcharts from "highcharts";
import moment from "moment";

const defaultLegendStyle = {
    fontSize: "10px",
    fontWeigth: "600",
    color: "#595959",
};
const defaultMarkerConfig = {
    enabled: true,
    symbol: "circle",
};

export function TrendChart({
    services,
    changesByMonth = {},
    title,
    subtitle = undefined,
    categories,
    series,
    width = null,
    height = null,
    minValue = undefined,
    maxValue = undefined,
    marginBottom = 120,
    pdfMode = false,
    referenceLine = undefined,
    enableLegend = true,
    legendStyle = defaultLegendStyle,
    markerConfig = defaultMarkerConfig,
    screenshotName = "trends",
    metadata,
    filters,
    id,
    section,
    options = {},
    scaleUnit = "default",
    ...props
}) {
    const { getCustlabelByCustId } = useAppContext();
    const scaleConfig = {
        default: {
            xAxis: {
                categories: categories,
            },
        },
        datetime: {
            xAxis: {
                type: "datetime",
                labels: {
                    rotation: -45,
                    formatter: function () {
                        return Highcharts.dateFormat("%e %b %Y", this.value);
                    },
                },
                tickmarkPlacement: "on",
            },
        },
    };

    return (
        <HighchartsComponent
            widgetProps={{
                id,
                filters,
                section,
                ...metadata,
            }}
            options={{
                chart: {
                    type: "spline",
                    width: width,
                    height: height,
                    zoomType: "xy",
                    marginBottom: marginBottom,
                    spacingBottom: 2,
                    panning: {
                        enabled: true,
                        type: "xy",
                    },
                    panKey: "shift",
                },
                plotOptions: {
                    series: {
                        marker: markerConfig,
                        dataLabels: {
                            enabled: true,
                        },
                        connectNulls: true,
                        // enableMouseTracking: mouseTracking,
                    },
                },
                tooltip: {
                    useHTML: true,
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    shadow: true,
                    style: {
                        fontSize: "12px",
                        color: "#333",
                    },
                    formatter: function () {
                        const formattedDate = moment(this.x).format("YYYY-MM-DD");
                        let s = '<table style="border-collapse: collapse; width: 100%;">';
                        s += `<tr><th colspan="2" style="padding-bottom: 5px;"><b>${Highcharts.dateFormat("%e %b %Y", this.x)}</b></th></tr>`;
                        s += `<tr>
                                <td style="text-align: left; padding: 2px;"><b>${this.series.name}:</b></td>
                                <td style="text-align: right; padding: 2px;">${this.y}</td>
                            </tr>`;

                        const groupKey = this.series.name.trim();
                        const secondaryKey = title ? title.split("-")[0].trim() : null;
                        const monthChanges = changesByMonth[groupKey]?.[formattedDate];

                        if (monthChanges && monthChanges[secondaryKey]) {
                            Object.entries(monthChanges[secondaryKey]).forEach(([measure, value]) => {
                                s += `<tr>
                                        <td style="text-align: left; padding: 2px;"><b>${measure}:</b></td>
                                        <td style="text-align: right; padding: 2px;">${value}</td>
                                    </tr>`;
                            });
                        }

                        s += "</table>";
                        return s;
                    },
                },
                legend: {
                    enabled: enableLegend,
                    // verticalAlign: 'middle',
                    // align: 'right',
                    padding: 12,
                    itemStyle: legendStyle,
                },
                title: {
                    text: title,
                    style: {
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "#595959",
                    },
                },
                subtitle: {
                    text: subtitle,
                    align: "center",
                    style: {
                        fontWeight: 500,
                        fontStyle: "italic",
                    },
                },
                xAxis: {
                    title: {
                        text: null,
                    },
                    crosshair: true,
                    ...scaleConfig[scaleUnit].xAxis,
                },
                yAxis: {
                    min: minValue,
                    title: {
                        text: null,
                    },
                    minPadding: 0.05,
                    maxPadding: 0.05,
                    startOnTick: true,
                    endOnTick: false,
                    events: {
                        // afterSetExtremes: function (e) {
                        //     if (minValue !== undefined && maxValue !== undefined) {
                        //         const padding = (e.dataMax - e.dataMin) * 0.1;
                        //         this.setExtremes(e.dataMin - padding, e.dataMax + padding);
                        //     }
                        // },
                    },
                    plotLines: referenceLine && [
                        {
                            value: referenceLine.value,
                            color: "#FF0000",
                            dashStyle: "shortdash",
                            width: 2,
                            zIndex: 10,
                        },
                    ],
                },
                responsive: {
                    rules: [
                        {
                            condition: {
                                maxWidth: 500,
                            },
                            chartOptions: {
                                legend: {
                                    enabled: false,
                                },
                                yAxis: {
                                    labels: {
                                        align: "left",
                                        x: 0,
                                        y: -5,
                                    },
                                },
                            },
                        },
                    ],
                },

                series: series,
                lang: {
                    noData: "No data available",
                },
                noData: {
                    style: {
                        fontWeight: "bold",
                        fontSize: "15px",
                        color: "#595959",
                    },
                },
                ...options,
            }}
            enableScreenshot={!pdfMode}
            imgName={screenshotName}
            {...props}
        />
    );
}
