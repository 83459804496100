import React, { useCallback, useEffect, useRef, useState } from "react";
import { RiCloseLine } from "react-icons/ri";

export default function ImageViewer(props) {
    const ref = useRef(undefined);
    const refImage = useRef(undefined);
    const [scaleImage, setScaling] = useState(false);

    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            props.selectPicHandler(undefined);
        }
    }

    useEffect(() => {
        window.addEventListener("mousedown", handleClickOutside);
        return () => {
            window.removeEventListener("mousedown", handleClickOutside);
            if (scaleImage) setScaling(false);
        };
    }, [ref]);

    const putErrorImage = useCallback(() => {
        refImage.current.src = "images/scrape_picture_not_available.png";
        refImage.current.className = "object-scale-down p-2 h-[95%] w-[100%]";
    }, [refImage, props.url]);

    return (
        <div ref={ref} className="fixed inset-x-[20%] inset-y-[10%] z-10 bg-[#2d3748] rounded-xl h-4/5 w-2/3 shadow-lg backdrop-blur-sm">
            <div className="relative top-1.5 right-3 flex justify-end space-x-4">
                <RiCloseLine className="w-5 h-5 cursor-pointer text-white hover:text-secondary-500" onClick={() => props.selectPicHandler(undefined)} />
            </div>
            <img ref={refImage} src={props.url} className={"object-fill p-2 h-[95%] w-[100%]"} onError={() => putErrorImage()} />
        </div>
    );
}
