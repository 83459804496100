import React, { useContext, useEffect, useState } from "react";
import { ErrorComponent, LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { newArithmeticMeasure } from "@gooddata/sdk-model";
import { newJsonPivot } from "../../utils/jsonPivot";
import MeasuresContext, { flagServiceMap } from "../../contexts/Measures";
import { Dropdown } from "antd";
import { RiUnpinFill } from "react-icons/ri";
import { FiMoreVertical } from "react-icons/fi";
import { useDashboard } from "../../contexts/DashboardProvider";

import * as Md from "../../md/full";

const BasketSynthesis = ({ id, execDefinition, widgetUid, componentChild, ...props }) => {
    const { selectedKpi, seriesBy } = useContext(MeasuresContext);
    // const [categoryColors, setCategoryColors] = useState([]);
    const [data, setData] = useState([]);
    const [newSeries, setNewSeries] = useState([...execDefinition.seriesBy]);
    const { unPinwWidget } = useDashboard();
    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy: [...newSeries],
            slicesBy: execDefinition.slicesBy,
            filters: execDefinition.filters,
        },
    });
    const { flag_services } = props;

    useEffect(() => {
        if (result) {
            const data = result
                ?.data()
                .series()
                .toArray()
                .find((serie) => serie.measureTitle() === newSeries[selectedKpi.index].measure.localIdentifier);
            const dataPoints = data?.dataPoints().map((dp) =>
                Object.freeze({
                    value: Number(dp.rawValue) === 0 ? null : selectedKpi.index === 24 ? Number((Number(dp.rawValue) * 100).toFixed(2)) : Math.round(Number(dp.rawValue)),
                    custId: dp.sliceDesc.sliceTitles()[0],
                    versionStd: dp.sliceDesc.sliceTitles()[1],
                }),
            );
            if (dataPoints) {
                const parsedData = newJsonPivot(dataPoints, { row: "versionStd", column: "custId", value: "value" });
                setData(parsedData);
            }
        }
    }, [result, selectedKpi]);

    useEffect(() => {
        if (flag_services) {
            const enabledServices = Object.keys(flag_services)
                .filter((flService) => flService in flagServiceMap && flag_services[flService])
                .map((flService) => flagServiceMap[flService]);
            newSeries.splice(23, 1, newArithmeticMeasure([Md.FinRental.Avg, Md.AdjDeposit.Avg, ...enabledServices], "sum"));
            newSeries.splice(24, 1, newArithmeticMeasure([newArithmeticMeasure([Md.FinRental.Avg, Md.AdjDeposit.Avg, ...enabledServices], "sum"), Md.ListPrice.Avg], "ratio"));
            setNewSeries([...newSeries]);
        }
    }, [componentChild, flag_services]);

    return data.length > 0 ? (
        <div>{React.createElement(componentChild, { data, ...props })}</div>
    ) : status === "loading" ? (
        <div className="min-h-[400px] flex items-center justify-center">
            <LoadingComponent />
        </div>
    ) : (
        <div className="relative">
            <Dropdown
                menu={{
                    items: [
                        {
                            key: "unpin",
                            icon: <RiUnpinFill />,
                            label: "Unpin widget",
                            onClick: () => unPinwWidget(widgetUid),
                        },
                    ],
                }}
                trigger={["click"]}
            >
                <FiMoreVertical className="absolute top-2 right-2 z-40 rounded w-8 h-8 px-1.5 py-1 text-gray-500 cursor-pointer hover:bg-backgroundColor hover:text-indigo-700" />
            </Dropdown>
            <div className="min-h-[400px] flex flex-col items-center justify-center">
                <ErrorComponent />
                <span className="text-sm font-medium text-steel/80 text-center py-2">
                    Seems like there is an issue with the data or the widget <br /> try unpinning and re-pinning it
                </span>
            </div>
        </div>
    );
};

export default BasketSynthesis;
