import React, { useCallback, useContext, useEffect, useState } from "react";
import { newPositiveAttributeFilter, newAttributeSort } from "@gooddata/sdk-model";
import _ from "lodash";
import { Tooltip, message, Table } from "antd";

import { useDashboard } from "../contexts/DashboardProvider";
import * as Md from "../md/full";
import MeasuresContext from "../contexts/Measures";
import IndiceWidget from "../components/Dashboard/IndiceWidget";
import CompetitivenessWidgetWrapper from "../components/Dashboard/CompetitivenessWidgetWrapper";
import { AiOutlineEdit } from "react-icons/ai";
import LoadingSpinner from "../components/LoadingSpinner";

import BasketBarWidget from "../components/Dashboard/BasketBarWidget";
import BasketLineWidget from "../components/Dashboard/BasketLineWidget";
import LineIndices from "../components/Indices/LineIndices";
import BarVehicles from "../components/Vehicles/BarVehicles";
import VolumeTrend from "../components/Indices/VolumeTrend";
import LineVehicles from "../components/Vehicles/LineVehicles";
import { CumulatedIndices } from "../components/Indices/CumulatedIndices";
import BarCompetitiveness from "../components/Competitiveness/BarCompetitiveness";
import VolumeCompetitiveness from "../components/Competitiveness/VolumeCompetitiveness";
import MakeModelOverview from "../components/Vehicles/MakeModelOverview";
import { BasketBarChart } from "../components/Baskets/BasketBarChart";
import { BasketLineChart } from "../components/Baskets/BasketLineChart";
import { ScatterChart } from "../components/Visualization/ScatterChart";
import BasketLFWidget from "../components/Dashboard/BasketLFWidget";
import { WidgetWrapper } from "../components/Dashboard/WidgetWrapper";
import { DataCoverageWidget } from "../components/Dashboard/DataCoverageWidget";
import WBItable from "../components/info/WBItable";
import PAItable from "../components/info/PAItable";
import VAtable from "../components/info/VAtable";
import DAtable from "../components/info/DAtable";
import DataByMake from "../components/info/DataByMake";
import AdjustedDataCoverageWidget from "../components/Dashboard/AdjustedDataCoverageWidget";
import BasketSynthesisChart from "../components/Baskets/BasketViz/BasketSynthesisChart";
import BasketSynthesis from "../components/Dashboard/BasketSynthesis";
import { CarSelectorWrapper } from "../components/Dashboard/CarSelectorWrapper";
import FiltersContext from "../contexts/Filters";
import { useExecutionDataView } from "@gooddata/sdk-ui";
import { BarChart } from "../components/Visualization/BarChart";
import { TrendChart } from "../components/Visualization/TrendChart";
import AgregatedBarWidget from "../components/Dashboard/AgregatedBarWidget";
import AgregatedLineWidget from "../components/Dashboard/AgregatedLineWidget";
import BasketSummary from "../components/Dashboard/BasketSummary";
import TableComponent from "../components/TableComponent";
import { ColumnChartAnalysisWidget } from "../components/BrandModelAnalysis/ColumnChartAnalysis";
import TrendBrandAnalysis from "../components/BrandModelAnalysis/TrendBrandAnalysis";
import { ColumnModelAnalysisWidget } from "../components/BrandModelAnalysis/ColumnModelAnalysis";
import TrendIndices from "../components/Indices/LineIndices";
import BrandAnalysisWidget from "../components/BrandModelAnalysis/BrandAnalysisWidget";
import { CarSelectorOverview } from "../components/Vehicles/CarSelectorOverview";
import { columns, dataTrends, dates, overviewData, tableData } from "../utils/dashboardDummyData";
import OverviewGraph from "../components/Visualization/OverviewGraph";

const widgetWrapperSection = {
    "car-selector": CarSelectorWrapper,
    trends: IndiceWidget,
    competitiveness: CompetitivenessWidgetWrapper,
    basketbar: BasketBarWidget,
    basketline: BasketLineWidget,
    basketsummary: BasketSummary,
    avgbasketbar: AgregatedBarWidget,
    avgbasketline: AgregatedLineWidget,
    basketLFM: BasketLFWidget,
    basketsynthesis: BasketSynthesis,
    datacoverage: DataCoverageWidget,
    datacoverageadjusted: AdjustedDataCoverageWidget,
};

const MonthListQuery = ({ setMonthList = (newMonths) => {}, ...props }) => {
    // const [monthlist, setMonthList] = useState([]);
    const { currentFilterHistory } = useContext(FiltersContext);

    const { result } = useExecutionDataView({
        execution: {
            seriesBy: [Md.FinRental.Avg, Md.Latestm.Avg, Md.Latestw.Avg],
            slicesBy: [Md.MthCode],
            filters: [...currentFilterHistory, newPositiveAttributeFilter(Md.FlagPrevMth, ["1"])],
            // sortBy: [newAttributeSort(Md.MthCode, 'desc')]
        },
    });

    useEffect(() => {
        const months = [];
        const slices = result?.data().slices().toArray();
        slices?.forEach((slice) => {
            if (!months.includes(slice.sliceTitles()[0])) {
                months.push(slice.sliceTitles()[0]);
            }
        });
        setMonthList(months);
    }, [result]);

    return <LoadingSpinner />;
};

const Dashboard = (props) => {
    const { seriesBy, selectedKpi } = useContext(MeasuresContext);
    const { filterCountryId, filterChannel1, filterMthCode } = useContext(FiltersContext);
    const { widgets, updateWidgets, updateWidgetLoadingStatus, deleteLoadingStatus } = useDashboard();
    // Synchronize month list of trends
    const [monthlist, setMonthList] = useState([]);

    // filter local widgets by selected filters
    let customizedFilters = [
        {
            [filterChannel1.positiveAttributeFilter.displayForm.identifier]: [filterChannel1.positiveAttributeFilter.in.values[0]],
        },
        {
            [filterCountryId.positiveAttributeFilter.displayForm.identifier]: [filterCountryId.positiveAttributeFilter.in.values[0]],
        },
    ];
    let filteredWidgets = [...widgets].filter((widget) => {
        return (
            _.isEqual(widget.filters[filterChannel1.positiveAttributeFilter.displayForm.identifier], customizedFilters[0][filterChannel1.positiveAttributeFilter.displayForm.identifier]) &&
            _.isEqual(widget.filters[filterCountryId.positiveAttributeFilter.displayForm.identifier], customizedFilters[1][filterCountryId.positiveAttributeFilter.displayForm.identifier])
        );
    });

    // Local widgets state
    const [localWidgets, setLocalWidgets] = useState([...filteredWidgets]);
    const [enableEditWidgetsPosition, setEditWidgetsPositionStatus] = useState(false);
    const [isLocalWidgetsUpdated, setUpdateWidgetFlag] = useState(false);
    // Block permutation until the callback gets refreshed with the new state of localWidgets
    const [blockEditWidgets, setBlockEditWidgets] = useState(false);

    const permutateWidgets = useCallback(
        (sourceIndex, targetIndex) => {
            const sourceItems = localWidgets?.splice(sourceIndex, 1, localWidgets[targetIndex]);
            // update widget position
            if (sourceItems?.length > 0) {
                const tmp = localWidgets[targetIndex].position;
                localWidgets[targetIndex].position = sourceItems[0].position;
                sourceItems[0].position = tmp;
                localWidgets?.splice(targetIndex, 1, sourceItems[0]);
                setLocalWidgets([...localWidgets]);
            }
        },
        [localWidgets, setLocalWidgets],
    );

    const onChangeWidget = useCallback(
        (widgetIndex, widgetProps = {}) => {
            localWidgets?.splice(widgetIndex, 1, { ...localWidgets[widgetIndex], ...widgetProps });
            setLocalWidgets([...localWidgets]);
            setBlockEditWidgets(true);
            setTimeout(() => {
                setBlockEditWidgets(false);
            }, 200);
        },
        [localWidgets, setLocalWidgets],
    );

    const onSaveSelection = useCallback(() => {
        // Find the widgets updated
        // const diffWidgets = _.difference(widgets, localWidgets);
        const payload = localWidgets
            ?.map((widget, index) => {
                let obj = Object.entries(widget)
                    .filter(([key, val]) => ["title", "position", "width", "height"].includes(key))
                    .reduce(
                        (acc, [key, val]) => {
                            if (widgets?.find((item) => item.id === widget.id)[key] !== val) {
                                acc = {
                                    _set: {
                                        ...acc._set,
                                        [key]: val,
                                    },
                                };
                            }
                            return acc;
                        },
                        { _set: {} },
                    );
                if (obj && widgets?.findIndex((item) => item.id === widget.id) !== index) {
                    obj._set.position = index;
                }
                // check width and height
                if (obj && widgets.find((item) => item.id === widget.id).width !== widget.width) {
                    obj._set.width = widget.width;
                }
                if (obj && widgets.find((item) => item.id === widget.id).height !== widget.height) {
                    obj._set.height = widget.height;
                }
                return {
                    ...obj,
                    where: {
                        id: {
                            _eq: widget.id,
                        },
                    },
                };
            })
            ?.filter((obj) => Object.keys(obj._set).length > 0);

        return updateWidgets(payload).then((res) => {
            message.success("Dashboard updated");
            setEditWidgetsPositionStatus(false);
        });
    }, [localWidgets]);

    useEffect(() => {
        if (!_.isEqual(filteredWidgets, localWidgets)) {
            setLocalWidgets([...filteredWidgets]);
        }
    }, [widgets, deleteLoadingStatus]);

    useEffect(() => {
        setUpdateWidgetFlag(!_.isEqual(filteredWidgets, localWidgets));
    }, [localWidgets, widgets]);

    useEffect(() => {
        setLocalWidgets([...filteredWidgets]);
        setMonthList([]);
    }, [filterChannel1, filterCountryId]);

    return localWidgets?.length <= 0 ? (
        <StaticDashboard />
    ) : monthlist.length <= 0 ? (
        <MonthListQuery setMonthList={setMonthList} />
    ) : deleteLoadingStatus || blockEditWidgets ? (
        <div className="flex items-stretch justify-center">
            <LoadingSpinner />
        </div>
    ) : (
        <div className={`flex flex-col items-stretch gap-2 ${blockEditWidgets ? "opacity-25 cursor-wait" : ""}`}>
            {!enableEditWidgetsPosition ? (
                <Tooltip title={"Edit widgets"} placement="left" color="#6B50B6">
                    <AiOutlineEdit className="self-end w-10 h-10 p-2 border rounded-md text-steel hover:bg-backgroundColor cursor-pointer" onClick={() => setEditWidgetsPositionStatus(!enableEditWidgetsPosition)} />
                </Tooltip>
            ) : !isLocalWidgetsUpdated ? (
                <button
                    className="self-end px-3 py-2 border rounded-md bg-red cursor-pointer"
                    style={{
                        fontWeight: 600,
                        fontSize: "14px",
                        color: "white",
                    }}
                    disabled={updateWidgetLoadingStatus}
                    onClick={() => setEditWidgetsPositionStatus(!enableEditWidgetsPosition)}
                >
                    Cancel
                </button>
            ) : (
                <button
                    className="self-end px-3 py-2 border rounded-md cursor-pointer"
                    style={{
                        fontWeight: 600,
                        fontSize: "14px",
                        color: "white",
                        backgroundColor: "#6B50B6",
                    }}
                    disabled={updateWidgetLoadingStatus}
                    onClick={() => onSaveSelection()}
                >
                    {updateWidgetLoadingStatus ? "Saving ..." : "Save my dashboard"}
                </button>
            )}
            <div className="grid grid-cols-2 grid-rows-dashboard gap-4">
                {localWidgets
                    ?.filter((widget) => widgetWrapperSection[widget.section] || componentIDs.find((item) => item.id === widget.label))
                    .map((widget, index) => {
                        const filters = Object.entries(widget.filters)
                            .filter(([key, val]) => val.length > 0)
                            .map(([keyAttr, filterValues]) => {
                                const filterObj = Object.entries(Md).find(([key, mdObj]) => {
                                    return keyAttr === mdObj?.attribute?.displayForm?.identifier;
                                });
                                if (filterObj[0] === "MthCode") {
                                    if (widget.section !== "trends") return newPositiveAttributeFilter(filterObj[1], [filterMthCode.positiveAttributeFilter.in.values[0]]);
                                    else if (filterValues?.length > 0) {
                                        const index = monthlist.findIndex((item) => item === filterValues[0]) !== -1 ? monthlist.findIndex((item) => item === filterValues[0]) : 0;
                                        const newMonths = monthlist.slice(index);
                                        return newPositiveAttributeFilter(filterObj[1], newMonths);
                                    }
                                }
                                return newPositiveAttributeFilter(filterObj[1], filterValues);
                            });
                        const component = componentIDs.find((item) => item.id === widget.label);
                        const execDefinition = {
                            ...component?.execDefinition,
                            seriesBy: component?.execDefinition?.seriesBy ? (component?.execDefinition?.onlyMeasures ? component?.execDefinition?.seriesBy : [...seriesBy, ...component?.execDefinition?.seriesBy]) : [...seriesBy],
                            slicesBy: component?.execDefinition?.slicesBy,
                            filters,
                        };

                        return (
                            <WidgetWrapper widgetIndex={index} widget={{ ...widget, filters }} enableDnd={enableEditWidgetsPosition} onUpdateWidgetPosition={permutateWidgets} onChangeTitle={onChangeWidget} onUpdateWidgetDimention={onChangeWidget}>
                                {widgetWrapperSection[widget.section] && widget.label !== "trend-rental-line"
                                    ? React.createElement(widgetWrapperSection[widget.section], { id: widget.label, execDefinition, componentChild: component?.componentType, enablePin: false, widgetUid: widget.id, widgetIndex: index, ...widget.metadata, selectedKpi })
                                    : // <IndiceWidget id={widget.id} execDefinition={execDefinition} componentChild={component.componentType}  />
                                      React.createElement(component?.componentType, { ...execDefinition, widgetUid: widget.id, enablePin: false, widgetIndex: index, selectedKpi, ...widget.metadata })}
                            </WidgetWrapper>
                        );
                        // <WidgetWrapper id={component.id} execDefinition={execDefinition} transformHandler={component.dataTransformer} rawDataTransformer={component.rawDataTransformer} generateLabels={component.generateLabels} componentType={component.componentType} />;
                    })}
            </div>
        </div>
    );
};

const StaticDashboard = () => {
    const [documentWidth, setDocumentWidth] = useState(document.body.getBoundingClientRect().width);

    function resizeWindow() {
        setDocumentWidth(document.body.getBoundingClientRect().width);
    }

    useEffect(() => {
        // To fix responsiveness of the chart when resizing the browser
        window.addEventListener("resize", resizeWindow);
        return () => {
            window.removeEventListener("resize", resizeWindow);
        };
    }, []);

    return (
        <div className="flex flex-col gap-6 items-center w-full">
            <h2 className="font-bold text-[23px]">Build your own dashboard using graphs and tables from the various Rentaleye sections</h2>
            <div
                className="flex flex-col gap-6 w-full border border-gray-200 rounded-md p-6"
                style={{
                    boxShadow: "-1px 5px 30px 2px #6B50B6",
                }}
            >
                <div className="grid grid-cols-2 gap-4 w-full">
                    <div className="flex flex-col items-center gap-1 pt-2 mb-2 w-full h-fit min-h-full z-10 border border-gray-200 rounded-md shadow-md shadow-[#708090] p-1">
                        <h3 className="text-indigo-700 text-lg font-semibold w-full text-center focus:outline-none">Rental evolution</h3>
                        {/* <span className="px-8 text-sm font-semibold text-steel text-center">Trends evolution</span> */}
                        <div className="w-min mx-auto">
                            <TrendChart
                                id={"dummy-id"}
                                services={[]}
                                section={"test"}
                                title={""}
                                subtitle={""}
                                categories={dates}
                                series={dataTrends}
                                metadata={{}}
                                filters={{}}
                                scaleUnit={"datetime"}
                                enableScreenshot={false}
                                width={documentWidth < 1020 ? documentWidth / 3 : (2 * documentWidth) / 5}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col items-center gap-1 pt-2 mb-2 w-full h-fit min-h-full z-10 border border-gray-200 rounded-md shadow-md shadow-[#708090] p-1">
                        <h3 className="text-indigo-700 text-lg font-semibold w-full text-center focus:outline-none">Vehicle Rental Overview</h3>
                        {/* <span className="px-8 text-sm font-semibold text-steel text-center">Price comparison</span> */}
                        <OverviewGraph data={overviewData.mainData} scatterData={overviewData.scatterData} title="" loading={false} height={450} />
                    </div>
                </div>
                <div className="w-full border border-gray-200 rounded-md shadow-md shadow-[#708090] p-4">
                    <h3 className="text-indigo-700 text-lg font-semibold w-full text-center focus:outline-none mb-4">Rental Comparison Details</h3>
                    <Table dataSource={tableData} columns={columns} pagination={false} className="w-full" />
                </div>
            </div>
        </div>
    );
};

// Object associating a component type with an ID
const componentIDs = [
    {
        id: "trend-line",
        componentType: LineIndices,
        execDefinition: {
            slicesBy: [Md.MthCode, Md.CustId],
        },
    },
    {
        id: "cumul-indices",
        componentType: CumulatedIndices,
        execDefinition: {
            slicesBy: [Md.MthCode, Md.CustId],
        },
    },
    {
        id: "volume-indices",
        componentType: VolumeTrend,
        execDefinition: {
            slicesBy: [Md.MthCode, Md.CustId],
        },
    },
    {
        id: "trend-rental-line",
        componentType: TrendIndices,
        execDefinition: {
            slicesBy: [Md.MthCode, Md.CustId],
        },
    },
    {
        id: "vehicle-bar-chart",
        componentType: BarVehicles,
        execDefinition: {
            slicesBy: [Md.CustId, Md.Channel3],
        },
    },
    {
        id: "vehicle-trends-chart",
        componentType: LineVehicles,
        execDefinition: {
            seriesBy: [Md.CustId],
            slicesBy: [Md.DateDatasets.DateOfScraping.Date.YyyyMmDd],
        },
    },
    {
        id: "synthesis-graph",
        componentType: MakeModelOverview,
        execDefinition: {
            slicesBy: [Md.CustId, Md.VersionStd],
        },
    },
    {
        id: "competitiveness-bar-chart",
        componentType: BarCompetitiveness,
        execDefinition: {
            slicesBy: [Md.Custmixindex, Md.CustId, Md.CustLabel],
        },
    },
    {
        id: "competitiveness-volume-chart",
        componentType: VolumeCompetitiveness,
        execDefinition: {
            slicesBy: [Md.Custmixindex, Md.CustId, Md.CustLabel],
        },
    },
    {
        id: "basket-bar-chart",
        componentType: BasketBarChart,
        execDefinition: {
            slicesBy: [Md.CustId, Md.VersionStd],
        },
    },
    {
        id: "basket-synthesis-graph",
        componentType: BasketSynthesisChart,
        execDefinition: {
            slicesBy: [Md.CustId, Md.VersionStd],
        },
    },
    {
        id: "basket-line-chart",
        componentType: BasketLineChart,
        execDefinition: {
            slicesBy: [Md.CustId, Md.DateDatasets.DateOfScraping.Date.YyyyMmDd, Md.VersionStd],
        },
    },
    {
        id: "basket-LFMatrix-chart",
        componentType: ScatterChart,
        execDefinition: {
            seriesBy: [Md.Deposit.Avg, Md.Emissions.Avg],
            slicesBy: [Md.Channel1, Md.Ctryid, Md.Duration, Md.Channel3, Md.TrimName, Md.Fuel, Md.GearsPlus, Md.Drive, Md.VehSegment, Md.CustId, Md.CorrModel],
            sortBy: [newAttributeSort(Md.CorrModel)],
        },
    },
    {
        id: "summary-table",
        componentType: TableComponent,
        execDefinition: {
            slicesBy: [Md.CustId, Md.VersionStd],
        },
    },
    {
        id: "aggregated-basket-bar-chart",
        componentType: BarChart,
        execDefinition: {
            slicesBy: [],
        },
    },
    {
        id: "aggregated-basket-trend-chart",
        componentType: TrendChart,
        execDefinition: {
            slicesBy: [Md.MthCode],
        },
    },
    {
        id: "monthly-rate-scraping",
        componentType: WBItable,
        execDefinition: {
            seriesBy: [Md.FlBreakdown.Avg, Md.FlCo2malus.Avg, Md.FlFinancialloss.Avg, Md.FlInsurance.Avg, Md.FlReplacement.Avg, Md.FlTyres.Avg, Md.FlWintertyres.Avg, Md.FlEvbonus.Avg, Md.FlService.Avg, Md.ListPrice.Avg, Md.Ps.Avg, Md.Kw.Avg, Md.Co2malus.Avg],
            slicesBy: [Md.CustId],
            onlyMeasures: true,
        },
    },
    {
        id: "monthly-rate-adjusted",
        componentType: PAItable,
        execDefinition: {},
    },
    {
        id: "vehicle-available",
        componentType: VAtable,
        execDefinition: {
            slicesBy: [Md.CustId],
        },
    },
    {
        id: "vehicle-available-duration",
        componentType: DAtable,
        execDefinition: {
            slicesBy: [Md.CustId, Md.Duration],
        },
    },
    {
        id: "vehicle-available-make",
        componentType: DataByMake,
        execDefinition: {
            slicesBy: [Md.CustId, Md.CorrMake],
        },
    },
    {
        id: "brand-column-chart",
        componentType: ColumnChartAnalysisWidget,
        execDefinition: {},
    },
    {
        id: "brand-trends-chart",
        componentType: TrendBrandAnalysis,
        execDefinition: {},
    },
    {
        id: "brand-model-column-chart",
        componentType: ColumnModelAnalysisWidget,
        execDefinition: {},
    },
    {
        id: "version-analysis-chart",
        componentType: BrandAnalysisWidget,
        execDefinition: {},
    },
    {
        id: "car-selector-overview-weekly",
        componentType: CarSelectorOverview,
        execDefinition: {
            slicesBy: [Md.DateDatasets.DateOfScraping.WeekMonSunYear.WeekNrYear],
            sortBy: Md.DateDatasets.DateOfScraping.WeekMonSunYear.WeekNrYear,
        },
    },
    {
        id: "car-selector-overview-monthly",
        componentType: CarSelectorOverview,
        execDefinition: {
            slicesBy: [Md.MthCode],
            sortBy: Md.MthCode,
        },
    },
];

export { Dashboard };
