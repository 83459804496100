import React, { useCallback, useContext, useMemo, useState } from "react";
import _ from "lodash";

import { Checkbox, Dropdown, Tooltip } from "antd";
import { useBrandConfigurator } from "../../contexts/BrandConfiguratorProvider";
import MeasuresContext from "../../contexts/Measures";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { utils, writeFileXLSX } from "xlsx";
import FiltersContext from "../../contexts/Filters";
import { settingsAliases, useUserContext } from "../../contexts/User";
// import "./brand_style.css";

export default function BrandConfigurator({ makeModelVersionTree, selectedPlayers, ...props }) {
    const { filterSelectors, setFilterSelector, deselectConfigurator, setExportTrends } = useBrandConfigurator();
    const { selectedKpi } = useContext(MeasuresContext);
    const { getFirstFilterValue } = useContext(FiltersContext);
    const { kpiSettings, enabledKpisettings, IncServices } = useUserContext();
    // State used to display nested list
    const [visibleMakes, setVisibleMakes] = useState({});
    const [visibleModels, setVisibleModels] = useState({});
    // Avoid mutation by reference
    const makeModelVersionTree2 = _.cloneDeep(makeModelVersionTree);
    const channel1 = getFirstFilterValue("filterChannel1");
    const serviceMessage = useMemo(() => {
        const services =
            kpiSettings && enabledKpisettings
                ? Object.entries(kpiSettings)
                      .filter(([key, val]) => val === 1 && key in enabledKpisettings && enabledKpisettings[key] === 1)
                      .reduce((acc, [key, val]) => {
                          acc.push(settingsAliases[key]);
                          return acc;
                      }, [])
                : [];
        const message = IncServices ? (services?.length > 0 ? `${services.join(", ")}` : "No Services") : "No services adjustment (services included as per web offer)";
        return message;
    }, [kpiSettings, enabledKpisettings, IncServices]);

    const selectConfigurator = useCallback(
        (make, model = undefined, versions = []) => {
            const newConfigurator = Object.assign({
                ...filterSelectors,
                [make]: model
                    ? {
                          ...filterSelectors[make],
                          [model]:
                              model && make in filterSelectors && model in filterSelectors[make]
                                  ? // versions.length > 0 && filterSelectors[make][model].length < makeModelObject[make][model].length ?
                                    [...filterSelectors[make][model], ...versions]
                                  : versions,
                          // : makeModelObject[make][model]
                      }
                    : makeModelVersionTree2[make],
            });
            setFilterSelector(newConfigurator);
        },
        [makeModelVersionTree2, filterSelectors],
    );

    function showHideNestedList(type, value) {
        if (type === "make") {
            setVisibleMakes({
                ...visibleMakes,
                [value]: value in visibleMakes ? !visibleMakes[value] : true,
            });
        } else if (type === "model") {
            setVisibleModels({
                ...visibleModels,
                [value]: value in visibleModels ? !visibleModels[value] : true,
            });
        }
    }

    function clearSelection() {
        setFilterSelector({});
    }

    function downloadSpotData() {
        // prepare the filterSelectors to be downloaded
        const data = Object.keys(filterSelectors).reduce((acc, brand) => {
            Object.keys(filterSelectors[brand]).forEach((model) => {
                filterSelectors[brand][model].forEach((item) => {
                    const arr = selectedPlayers
                        .filter((player) => player.custId in item && item[player.custId].length > 0)
                        .map((player) =>
                            Object.assign({
                                Version: item.label,
                                Make: brand,
                                Model: model,
                                Player: player.custLabel,
                                Channel1: channel1,
                                [selectedKpi.name]: item[player.custId][selectedKpi.index] !== null ? selectedKpi.roundValue(item[player.custId][selectedKpi.index]) : null,
                                "List Price": item[player.custId][1] !== null ? Math.round(item[player.custId][1]) : null,
                                "Adj.Rental includes": serviceMessage,
                            }),
                        );
                    acc = [...acc, ...arr];
                });
            });
            return acc;
        }, []);
        const ws = utils.json_to_sheet(data);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Data");
        writeFileXLSX(wb, "brand_model_analysis_spot_export.xlsx");
    }

    const countActiveModels = useCallback(
        (brand) => {
            return brand in filterSelectors ? Object.keys(filterSelectors[brand]).length : 0;
        },
        [filterSelectors],
    );

    const countActiveVersionStdByModel = useCallback(
        (brand, model) => {
            return brand in filterSelectors && model in filterSelectors[brand] ? filterSelectors[brand][model].length : 0;
        },
        [filterSelectors],
    );

    const exportMenuItems = [
        {
            key: "spot",
            label: "Spot figure",
            onClick: () => downloadSpotData(),
        },
        {
            key: "trends",
            label: "Trends history",
            onClick: () => setExportTrends(true),
        },
    ];

    return (
        <div className={`h-full py-1 border rounded-md border-gray-300 shadow-md`}>
            <div className="px-2 flex items-center justify-between">
                <span className="text-indigo-700 text-lg font-semibold">Selection box</span>
                <button className="border-gray-300 bg-secondary-500 text-sm text-white py-0.5 border rounded font-semibold shadow-button w-32" onClick={clearSelection}>
                    <span>Clear selection</span>
                </button>
                {/* <IoCloseCircleOutline onClick={clearSelection} className="text-secondary-500 w-6 h-6 cursor-pointer" /> */}
            </div>
            <ul className="mt-2 h-[450px] overflow-y-scroll">
                {Object.keys(makeModelVersionTree2).map((make) => {
                    const selectedModelsCount = countActiveModels(make);

                    return (
                        <li>
                            <div
                                className={`sticky top-0 z-20 py-1 px-2 flex justify-stretch items-center font-medium gap-2 bg-white hover:bg-backgroundColor border-b border-gray-200 ${selectedModelsCount > 0 ? "text-indigo-500" : "text-[#374151]"} `}
                                onClick={(e) => showHideNestedList("make", make)}
                                title={!(make in visibleMakes) ? `See ${make} models` : null}
                            >
                                <div className="flex-1 flex items-center gap-2">
                                    <div className="p-1 bg-indigo-100 rounded-[4px]">{visibleMakes[make] ? <AiOutlineMinus className="w-4 h-4 text-indigo-300" /> : <AiOutlinePlus className="w-4 h-4 text-indigo-300" />}</div>
                                    <span className="text-base leading-8 cursor-copy">{make}</span>
                                    {selectedModelsCount > 0 && <span>({selectedModelsCount})</span>}
                                </div>
                            </div>
                            {visibleMakes[make] && (
                                <ul className="pl-8">
                                    {Object.keys(makeModelVersionTree2[make]).map((model) => {
                                        const versionCount = countActiveVersionStdByModel(make, model);
                                        return (
                                            <ul>
                                                <div className={`sticky top-10 z-10 bg-white flex items-center gap-2 py-0.5 ${versionCount > 0 ? "text-steel font-medium" : "text-gray-500"}`} onClick={(e) => showHideNestedList("model", model)}>
                                                    <Checkbox
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (make in filterSelectors && filterSelectors[make][model]) deselectConfigurator(make, model);
                                                            else selectConfigurator(make, model, makeModelVersionTree2[make][model]);
                                                        }}
                                                        checked={make in filterSelectors && makeModelVersionTree2[make][model].length === versionCount}
                                                        indeterminate={make in filterSelectors && makeModelVersionTree2[make][model].length > versionCount && versionCount !== 0}
                                                    />
                                                    <span className="leading-6 cursor-copy">{model}</span>
                                                    {versionCount > 0 && <span>({versionCount})</span>}
                                                </div>
                                                {visibleModels[model] && (
                                                    <ul className="pl-4 pr-1">
                                                        {makeModelVersionTree2[make][model].map((versionObject) => {
                                                            const makeModelWordsCount = [make, model].join(" ").split(" ").length;
                                                            const versionLabel = versionObject.label.split(" ").slice(makeModelWordsCount).join(" ");
                                                            const minListPrice = _.min(selectedPlayers.map((p) => (versionObject[p.custId] ? versionObject[p.custId][1] : null)));
                                                            const custIdsPhrases = selectedPlayers.reduce((acc, p) => {
                                                                if (versionObject[p.custId]?.length > 0) {
                                                                    const val = versionObject[p.custId][selectedKpi.index] > 0 ? selectedKpi.roundValue(versionObject[p.custId][selectedKpi.index]) : null;
                                                                    if (val) acc.push(`${p.custLabel} ${val}`);
                                                                }
                                                                return acc;
                                                            }, []);
                                                            const custIdsPhrase = custIdsPhrases.join(", ");
                                                            const tooltipMessage = minListPrice ? `List Price ${minListPrice}, ${custIdsPhrase}` : custIdsPhrase;

                                                            return (
                                                                <li
                                                                    className="flex items-center gap-2 text-[13px] font-normal cursor-pointer py-1"
                                                                    onClick={() => {
                                                                        if (make in filterSelectors && model in filterSelectors[make] && filterSelectors[make][model].find((item) => item.label === versionObject.label)) deselectConfigurator(make, model, [versionObject]);
                                                                        else selectConfigurator(make, model, [versionObject]);
                                                                    }}
                                                                >
                                                                    <Checkbox checked={make in filterSelectors && model in filterSelectors[make] && filterSelectors[make][model].find((item) => item.label === versionObject.label)} />
                                                                    <Tooltip title={tooltipMessage} placement="left" overlayStyle={{ fontSize: 12, minWidth: "max-content" }}>
                                                                        <span>{versionLabel}</span>
                                                                    </Tooltip>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                )}
                                            </ul>
                                        );
                                    })}
                                </ul>
                            )}
                        </li>
                    );
                })}
            </ul>
            <Dropdown menu={{ items: exportMenuItems, onClick: ({ key }) => exportMenuItems.find((obj) => obj.key === key).onClick }} placement="topRight" trigger={["click"]} arrow disabled={Object.keys(filterSelectors).length === 0}>
                <button
                    className="mr-2.5 mb-2 bg-indigo-700 text-sm text-white py-1 border rounded font-semibold w-32 disabled:bg-indigo-500"
                    style={{
                        float: "right",
                    }}
                >
                    Export
                </button>
            </Dropdown>
        </div>
    );
}
