import { newArithmeticMeasure, newAttributeSort } from "@gooddata/sdk-model";
import { ErrorComponent, LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import React, { useContext, useEffect, useState } from "react";
import MeasuresContext, { flagServiceMap } from "../../contexts/Measures";
import { Dropdown } from "antd";
import { RiUnpinFill } from "react-icons/ri";
import { FiMoreVertical } from "react-icons/fi";
import * as Md from "../../md/full";
import { useDashboard } from "../../contexts/DashboardProvider";

const AgregatedLineWidget = ({ id, execDefinition, widgetUid, componentChild, ...props }) => {
    const { selectedKpi } = useContext(MeasuresContext);
    const { seriesBy: execSeries, slicesBy, filters } = execDefinition;
    const { unPinwWidget } = useDashboard();
    const [newSeries, setNewSeries] = useState([...execSeries]);
    const [data, setData] = useState([]);
    const [categories, setCategories] = useState([]);
    const displayMode = props.displayMode;
    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy: newSeries,
            slicesBy: [...displayMode.attributes, ...slicesBy],
            filters,
            sortBy: [newAttributeSort(displayMode.attributes[displayMode.attributes.length - 1])],
        },
    });

    const { flag_services } = props;

    useEffect(() => {
        const serie = result?.data().series().firstForMeasure(newSeries[selectedKpi.index]);
        const slices = result?.data().slices().toArray();
        const mthValues = slices
            ?.map((slice) => slice.sliceTitles()[slice.sliceTitles().length - 1])
            .reduce((acc, mthCode) => {
                !acc.includes(mthCode) && acc.push(mthCode);
                return acc;
            }, []);
        mthValues?.sort();

        if (serie && mthValues) {
            const dataPoints = serie?.dataPoints().reduce((acc, dp) => {
                const sliceTitles = dp.sliceDesc.sliceTitles();
                const mthValue = sliceTitles[sliceTitles.length - 1];
                const objName = sliceTitles.slice(0, sliceTitles.length - 1).reduce((acc, nextElem) => (displayMode.attributes.length > 1 ? acc + " " + nextElem : nextElem));
                let obj = acc.find((item) => item.name === objName);
                // Get mthValue position in the mthValues list
                const mthIndex = mthValues.findIndex((mth) => mth === mthValue);
                const dpValue = Number(dp.rawValue) === 0 ? null : selectedKpi.index === 24 ? Number((Number(dp.rawValue) * 100).toFixed(2)) : Math.round(Number(dp.rawValue));
                if (obj) {
                    obj.data[mthIndex] = dpValue;
                } else {
                    let dataArr = Array(mthValues.length - 1).fill(null);
                    dataArr.splice(mthIndex, 1, dpValue);
                    acc.push({
                        name: objName,
                        data: dataArr,
                        // color: getColorByCustId(custId),
                    });
                }
                return acc;
            }, []);
            setData(dataPoints);
            setCategories(mthValues);
        }
    }, [result, selectedKpi]);

    useEffect(() => {
        if (flag_services) {
            const enabledServices = Object.keys(flag_services)
                .filter((flService) => flService in flagServiceMap && flag_services[flService])
                .map((flService) => flagServiceMap[flService]);
            newSeries.splice(23, 1, newArithmeticMeasure([Md.FinRental.Avg, Md.AdjDeposit.Avg, ...enabledServices], "sum"));
            newSeries.splice(24, 1, newArithmeticMeasure([newArithmeticMeasure([Md.FinRental.Avg, Md.AdjDeposit.Avg, ...enabledServices], "sum"), Md.ListPrice.Avg], "ratio"));
            setNewSeries([...newSeries]);
        }
    }, [componentChild, flag_services]);

    return status !== "loading" && data?.length > 0 ? (
        <>
            <div className="border-2 border-gray-300 rounded">
                {React.createElement(componentChild, {
                    series: data,
                    categories,
                    displayMode: props.displayMode,
                    legendStyle: {
                        fontSize: data?.length > 8 ? "8px" : "10px",
                        fontWeigth: data?.length > 8 ? "400" : "600",
                        color: "#595959",
                        ellipsis: data?.length > 6 ? true : false,
                        width: data?.length > 6 ? "130px" : "auto",
                    },
                    kpiSettings: flag_services,
                    ...props,
                })}
            </div>
        </>
    ) : status === "error" ? (
        <div className="relative">
            <Dropdown
                menu={{
                    items: [
                        {
                            key: "unpin",
                            icon: <RiUnpinFill />,
                            label: "Unpin widget",
                            onClick: () => unPinwWidget(widgetUid),
                        },
                    ],
                }}
                trigger={["click"]}
            >
                <FiMoreVertical className="absolute top-2 right-2 z-40 rounded w-8 h-8 px-1.5 py-1 text-gray-500 cursor-pointer hover:bg-backgroundColor hover:text-indigo-700" />
            </Dropdown>
            <div className="min-h-[400px] flex flex-col items-center justify-center">
                <ErrorComponent />
                <span className="text-sm font-medium text-steel/80 text-center py-2">
                    Seems like there is an issue with the data or the widget <br /> try unpinning and re-pinning it
                </span>
            </div>
        </div>
    ) : (
        <div className="min-h-[400px] flex items-center justify-center">
            <LoadingComponent />
        </div>
    );
};

export default AgregatedLineWidget;
