import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import UserContext from "../../contexts/User";
import { useAppContext } from "../../contexts/AppContext";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Modal, Table, message } from "antd";
import DropIcon from "../Icons/DropIcon";
import { useMutation } from "@apollo/client";
import update from "immutability-helper";
import FiltersContext from "../../contexts/Filters";
import { useExecutionDataView } from "@gooddata/sdk-ui";
import * as Md from "../../md/full";
import { GET_ALL_USER_PREFERENCES, UPDATE_CUSTOMER_SLOTS_SETTINGS } from "../../queries/Users";
import LoadingSpinner from "../LoadingSpinner";
import AddIcon from "../Icons/AddIcon";
import PropTypes from "prop-types";

const type = "DraggableBodyRow";

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
    const ref = useRef(null);
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: (monitor) => {
            // @ts-ignore: Type safety is handled with runtime checks
            const dragIndex = monitor.getItem()?.index;
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? " drop-over-downward" : " drop-over-upward",
            };
        },
        drop: (item) => {
            // @ts-ignore: Type safety is handled with runtime checks
            moveRow(item.index, index);
        },
    });
    const [, drag] = useDrag({
        type,
        item: {
            index,
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));
    return (
        <tr
            ref={ref}
            className={`${className}${isOver ? dropClassName : ""}`}
            style={{
                cursor: "move",
                ...style,
            }}
            {...restProps}
        />
    );
};

const DnDModal = ({ isOpen, setIsOpen }) => {
    // Get the list of cust ids
    const { filterCountryId, filterChannel1 } = useContext(FiltersContext);
    const { result, status, error } = useExecutionDataView({
        execution: {
            seriesBy: [Md.FinRental.Avg],
            slicesBy: [Md.CustId],
            filters: [filterCountryId, filterChannel1],
        },
    });

    const [allCustLabels, setAllCustLabels] = useState([]);
    const [initialCustLabels, setInitialCustLabels] = useState([]);
    const [hasChanges, setHasChanges] = useState(false);
    const { defaultUserPref } = useContext(UserContext);
    const { getFilterValue } = useContext(FiltersContext);
    const { getCustlabelByCustId } = useAppContext();
    const [updateSettings] = useMutation(UPDATE_CUSTOMER_SLOTS_SETTINGS);
    const [customerSlots, setCustomerSlot] = useState([]);
    const ctryid = getFilterValue("filterCountryId")[0];
    const channel1 = getFilterValue("filterChannel1")[0];

    const onDropSlot = (position) => {
        // Make a copy of the current items
        const updatedItems = [...allCustLabels];

        // Get the player being removed
        const removedPlayer = updatedItems[position];

        // If we have a valid player (not null)
        if (removedPlayer) {
            // Set the slot position to null (empty)
            updatedItems[position] = null;

            // Add the removed player to the end (available players section)
            updatedItems.push(removedPlayer);

            // Update state
            setAllCustLabels(updatedItems);
        }
    };

    const onAddSlot = (position) => {
        // Make a copy of the current items
        const updatedItems = [...allCustLabels];

        // Get the player being added
        const playerToAdd = updatedItems[position];

        // If we have a valid player (not null)
        if (playerToAdd) {
            // Find the first empty slot in the selected section (index 0-7)
            const emptySlotIndex = updatedItems.findIndex((item, index) => item === null && index < 8);

            // If we found an empty slot
            if (emptySlotIndex !== -1) {
                // Remove the player from the available section
                updatedItems.splice(position, 1);

                // Add the player to the empty slot
                updatedItems[emptySlotIndex] = playerToAdd;
            } else {
                // If no empty slots, show a message to the user
                message.warning({
                    content: "All slots are filled. Remove a player first to add a new one.",
                    style: {
                        right: "20px",
                        top: "70px",
                        position: "absolute",
                    },
                    duration: 3,
                });
                return;
            }

            // Update state
            setAllCustLabels(updatedItems);
        }
    };

    useEffect(() => {
        const initialSlotsData = defaultUserPref
            ? Object.entries(defaultUserPref)
                  .filter(([key, elem]) => key.includes("customer_slot") && elem !== "NULL" && elem !== null)
                  .map(([key, elem]) => elem)
            : [];
        setCustomerSlot([...initialSlotsData]);
    }, [defaultUserPref]);

    useEffect(() => {
        if (result) {
            const dataSlices = result?.data().slices().toArray();
            const custLabels = dataSlices.map((dataSlice) =>
                Object.assign({
                    key: dataSlice.sliceTitles()[0],
                    player: getCustlabelByCustId(dataSlice.sliceTitles()[0]),
                }),
            );

            // Create a new array with 8 null slots for the selected players section
            const newAllCustLabels = Array(8).fill(null);

            // Create a list of all available players that aren't in the selected slots
            const availablePlayers = [...custLabels];

            // Fill in the selected slots
            customerSlots.forEach((slotId, index) => {
                if (index < 8) {
                    // Find the player in the available players list
                    const playerIndex = availablePlayers.findIndex((player) => player.key === slotId);

                    if (playerIndex !== -1) {
                        // Add the player to the appropriate slot
                        newAllCustLabels[index] = availablePlayers[playerIndex];

                        // Remove from available players
                        availablePlayers.splice(playerIndex, 1);
                    }
                }
            });

            // Add remaining available players after the 8 slots
            const fullLabelsList = [...newAllCustLabels, ...availablePlayers];
            setAllCustLabels(fullLabelsList);
            setInitialCustLabels(JSON.parse(JSON.stringify(fullLabelsList)));
            setHasChanges(false);
        }
    }, [result, customerSlots]);

    // Check for changes when allCustLabels changes
    useEffect(() => {
        if (initialCustLabels.length > 0 && allCustLabels.length > 0) {
            // Compare the initial 8 slots with current 8 slots
            let changed = false;

            // Check if any of the first 8 slots have changed
            for (let i = 0; i < 8; i++) {
                const initialKey = initialCustLabels[i]?.key || null;
                const currentKey = allCustLabels[i]?.key || null;
                if (initialKey !== currentKey) {
                    changed = true;
                    break;
                }
            }

            setHasChanges(changed);
        }
    }, [allCustLabels, initialCustLabels]);

    function onSaveClick() {
        let mutationData = allCustLabels
            .map((elem, index) =>
                Object.assign({
                    [`customer_slot_${index + 1}`]: elem?.key || null,
                }),
            )
            .reduce((acc, elem2) => {
                return {
                    ...acc,
                    ...elem2,
                };
            }, {});

        updateSettings({
            variables: {
                ...mutationData,
                country: ctryid,
                channel1: channel1,
            },
            refetchQueries: [
                {
                    query: GET_ALL_USER_PREFERENCES,
                },
            ],
        })
            .then((res) => {
                // refrechSettings({
                //     ctryid,
                //     channel1
                // });
                message.success({
                    content: "Player slots updated",
                    style: {
                        right: "20px",
                        top: "70px",
                        position: "absolute",
                    },
                    duration: 2,
                });
            })
            .catch((e) => {
                message.error({
                    content: `${e}`,
                    style: {
                        right: "20px",
                        top: "70px",
                        position: "absolute",
                    },
                    duration: 2,
                });
            });
    }
    const components = {
        body: {
            row: DraggableBodyRow,
        },
    };
    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const dragRow = allCustLabels[dragIndex];

            // Check if this is a move from the available section to the selected section
            const isMovingToSelectedSection = dragIndex >= 8 && hoverIndex < 8;

            // Only check capacity if moving to an empty slot in the selected section
            if (isMovingToSelectedSection && allCustLabels[hoverIndex] === null) {
                // Count non-null items in the first 8 slots
                const selectedCount = allCustLabels.slice(0, 8).filter((item) => item !== null).length;

                // If all slots are filled, show warning
                if (selectedCount >= 8) {
                    message.warning({
                        content: "All slots are filled. Remove a player first to add a new one.",
                        style: {
                            right: "20px",
                            top: "70px",
                            position: "absolute",
                        },
                        duration: 3,
                    });
                    return;
                }
            }

            let mutableCustLabels;

            // If moving to an empty slot
            if (allCustLabels[hoverIndex] === null) {
                mutableCustLabels = update(allCustLabels, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 1, dragRow],
                    ],
                });
            }
            // If moving from available to selected and the slot has a player, swap them
            else if (isMovingToSelectedSection) {
                const selectedPlayer = allCustLabels[hoverIndex];

                // Make a copy of the array
                const newArray = [...allCustLabels];

                // Remove the available player
                newArray.splice(dragIndex, 1);

                // Place the available player in the selected slot
                newArray[hoverIndex] = dragRow;

                // Add the previously selected player to the end (available section)
                newArray.push(selectedPlayer);

                mutableCustLabels = newArray;
            } else {
                // Otherwise insert before the hover position
                mutableCustLabels = update(allCustLabels, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                });
            }

            setAllCustLabels(mutableCustLabels);
        },
        [allCustLabels],
    );

    // Add drop icon button
    const selectedPlayerColumns = [
        {
            title: "Selected Players",
            dataIndex: "player",
            key: "player",
            render: (cellValue, record, index) => {
                if (record === null) {
                    return (
                        <div className="flex justify-center py-1 px-1 italic text-xs font-medium text-gray-500 border border-dashed border-gray-300 rounded-md bg-gray-50">
                            <span>Empty slot</span>
                        </div>
                    );
                }
                return (
                    <div className="flex items-center text-sm gap-2 py-1 px-1 rounded-md bg-indigo-50">
                        <div className="flex-1 flex items-center gap-2">
                            <img src={`/images/logos/Logo_${record.key}.png`} width={40} height={40} alt={cellValue} className="rounded-full border border-gray-200" />
                            <span className="font-medium truncate">{cellValue}</span>
                        </div>
                        <button onClick={() => onDropSlot(index)} className="text-gray-500 hover:text-red-500 transition-colors p-1 rounded-full hover:bg-red-50" title="Remove from selected slots">
                            <DropIcon width={14} height={14} color="#f56565" />
                        </button>
                    </div>
                );
            },
        },
    ];

    const availablePlayerColumns = [
        {
            title: "Available Players",
            dataIndex: "player",
            key: "player",
            render: (cellValue, record, index) => {
                if (record === null) {
                    return (
                        <div className="flex justify-center py-1 px-1 italic text-xs font-medium text-gray-500 border border-dashed border-gray-300 rounded-md bg-gray-50">
                            <span>Empty slot</span>
                        </div>
                    );
                }

                // Calculate the actual index in the allCustLabels array
                const actualIndex = index + 8;

                return (
                    <div className="flex items-center text-sm gap-2 py-1 px-3 rounded-md bg-gray-50">
                        <div className="flex-1 flex items-center gap-2 min-w-0">
                            <img src={`/images/logos/Logo_${record.key}.png`} width={40} height={40} alt={cellValue} className="rounded-full border border-gray-200 flex-shrink-0" />
                            <span className="font-medium truncate">{cellValue}</span>
                        </div>
                        <button onClick={() => onAddSlot(actualIndex)} className="text-gray-500 hover:text-green-500 transition-colors p-1 rounded-full hover:bg-green-50 flex-shrink-0 ml-2" title="Add to selected slots">
                            <AddIcon width={14} height={14} color="#48bb78" />
                        </button>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <Modal
                okText="Save"
                okButtonProps={{ disabled: !hasChanges }}
                title={
                    <div className="text-base font-medium text-indigo-700">
                        Edit Players Slots
                        <p className="text-xs font-normal text-gray-500 mt-0.5">Drag and drop players between columns to configure your selected slots.</p>
                    </div>
                }
                open={isOpen}
                onOk={() => {
                    onSaveClick();
                    setIsOpen(false);
                }}
                onCancel={() => setIsOpen(false)}
                width={800}
                className="player-slots-modal"
                bodyStyle={{ padding: "12px" }}
            >
                {status === "loading" ? (
                    <div className="flex justify-center items-center py-6">
                        <LoadingSpinner />
                        <span className="ml-3 text-gray-600">Loading players data...</span>
                    </div>
                ) : error ? (
                    <div className="p-6 bg-red-50 rounded-md text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 mx-auto text-red-500 mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <h3 className="text-lg font-medium text-red-700">Error Loading Data</h3>
                        <p className="text-sm text-red-600 mt-1">{error.message || "Failed to load players data. Please try again."}</p>
                        <button className="mt-4 px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors" onClick={() => setIsOpen(false)}>
                            Close
                        </button>
                    </div>
                ) : allCustLabels.length === 0 ? (
                    <div className="p-6 bg-gray-50 rounded-md text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 mx-auto text-gray-400 mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <h3 className="text-lg font-medium text-gray-700">No Players Available</h3>
                        <p className="text-sm text-gray-600 mt-1">There are no players available for the selected filters.</p>
                        <button className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors" onClick={() => setIsOpen(false)}>
                            Close
                        </button>
                    </div>
                ) : (
                    <DndProvider backend={HTML5Backend}>
                        <div className="player-slots-container">
                            <div className="mb-3 bg-gradient-to-r from-indigo-50 to-white rounded-md border border-indigo-100 overflow-hidden">
                                <div className="flex items-center justify-between p-2">
                                    <div className="flex items-center">
                                        <p className="text-xs font-medium text-gray-700">Tip: {hasChanges ? "Click 'Save' to save your changes" : "Make changes to enable the Save button"}</p>
                                    </div>
                                    <div className="flex items-center text-xs text-gray-700">
                                        <span className="flex items-center bg-white px-2 py-1 rounded-md shadow-sm">
                                            <DropIcon color="#f56565" className="inline-block mr-1" width={12} height={12} />
                                            <span className="font-medium">Remove</span>
                                        </span>
                                        <span className="mx-2 text-gray-300">•</span>
                                        <span className="flex items-center bg-white px-2 py-1 rounded-md shadow-sm">
                                            <AddIcon color="#48bb78" className="inline-block mr-1" width={12} height={12} />
                                            <span className="font-medium">Add</span>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="flex gap-2">
                                {/* Active Players Column */}
                                <div className="flex-1 border border-indigo-100 rounded-md overflow-hidden">
                                    <div className="bg-indigo-100 p-2">
                                        <h4 className="text-sm font-semibold text-indigo-700">Selected Slots ({allCustLabels.slice(0, 8).filter((elem) => elem !== null).length}/8)</h4>
                                    </div>
                                    <Table
                                        rowClassName={(record, index) => `player-slot selected-slot ${record === null ? "empty-slot" : ""}`}
                                        columns={selectedPlayerColumns}
                                        dataSource={allCustLabels.slice(0, 8)}
                                        style={{
                                            width: "100%",
                                        }}
                                        size="small"
                                        components={components}
                                        // @ts-ignore
                                        onRow={(record, index) => ({
                                            index,
                                            moveRow,
                                        })}
                                        pagination={false}
                                        scroll={{ y: 240 }}
                                        showHeader={false}
                                    />
                                </div>

                                {/* Available Players Column */}
                                <div className="flex-1 border border-gray-200 rounded-md overflow-hidden">
                                    <div className="bg-gray-100 p-2">
                                        <h4 className="text-sm font-semibold text-gray-700">Available Players</h4>
                                    </div>
                                    <Table
                                        rowClassName={(record) => `player-slot available-slot ${record === null ? "empty-slot" : ""}`}
                                        columns={availablePlayerColumns}
                                        dataSource={allCustLabels.slice(8)}
                                        style={{
                                            width: "100%",
                                        }}
                                        size="small"
                                        components={components}
                                        // @ts-ignore
                                        onRow={(record, index) => ({
                                            index: index + 8,
                                            moveRow,
                                        })}
                                        pagination={false}
                                        scroll={{ y: 240 }}
                                        showHeader={false}
                                    />
                                </div>
                            </div>

                            <style>{`
                            .player-slots-modal .ant-modal-body {
                                padding: 16px;
                            }
                            .player-slots-container {
                                width: 100%;
                            }
                            .player-slot {
                                cursor: move;
                                transition: all 0.3s;
                                margin: 1px 0;
                            }
                            .player-slot:hover {
                                transform: translateX(3px);
                                background-color: rgba(79, 70, 229, 0.05);
                            }
                            .selected-slot {
                                border-left: 3px solid #4f46e5;
                            }
                            .available-slot {
                                border-left: 3px solid #d1d5db;
                            }
                            .empty-slot {
                                opacity: 0.7;
                            }
                            .drop-over-downward {
                                border: 2px dashed #4f46e5;
                                background-color: rgba(79, 70, 229, 0.1);
                            }
                            .drop-over-upward {
                                border: 2px dashed #4f46e5;
                                background-color: rgba(79, 70, 229, 0.1);
                            }
                            .ant-table-row.drop-over-downward td,
                            .ant-table-row.drop-over-upward td {
                                background-color: rgba(79, 70, 229, 0.05) !important;
                                transition: background-color 0.3s;
                            }
                            .selected-slot:hover, .available-slot:hover {
                                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
                            }
                            .player-slots-modal .ant-table-cell {
                                padding: 6px 8px;
                                min-width: 250px;
                            }
                            `}</style>
                        </div>
                    </DndProvider>
                )}
            </Modal>
        </>
    );
};

const Selectedcust = ({ playerVersionAvailability = {} }) => {
    const { defaultUserPref } = useContext(UserContext);
    const { getCustlabelByCustId } = useAppContext();
    const [customerSlot, setCustomerSlot] = useState([]);
    const [allPlayers, setAllPlayers] = useState([]);
    const [hiddenPlayers, setHiddenPlayers] = useState([]);

    // Get the list of all customer IDs
    const { filterCountryId, filterChannel1 } = useContext(FiltersContext);
    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy: [Md.FinRental.Avg],
            slicesBy: [Md.CustId],
            filters: [filterCountryId, filterChannel1],
        },
    });

    useEffect(() => {
        if (result) {
            const dataSlices = result?.data().slices().toArray();
            const allCustIds = dataSlices.map((dataSlice) => dataSlice.sliceTitles()[0]);
            setAllPlayers(allCustIds);
        }
    }, [result]);

    useEffect(() => {
        const initialSlotsData = defaultUserPref
            ? Object.entries(defaultUserPref)
                  .filter(([key, elem]) => key.includes("customer_slot") && elem !== "NULL" && elem !== null)
                  .map(([key, elem]) => elem)
            : [];
        setCustomerSlot([...initialSlotsData]);
        // Calculate hidden players (all players that are not in the customer slots)
        if (allPlayers.length > 0) {
            const hidden = allPlayers.filter((player) => !initialSlotsData.includes(player));
            setHiddenPlayers(hidden);
        }
    }, [defaultUserPref, allPlayers]);

    const PlayerBadge = ({ playerId, isActive }) => {
        const playerVersionData = playerVersionAvailability[playerId] || { count: 0, total: 0 };
        const { count: versionCount, total } = playerVersionData;

        return (
            <div className={`flex items-center gap-1 px-2 py-1 rounded-md shadow-sm ${isActive ? "bg-indigo-300/40 border border-indigo-300" : "bg-gray-100 border border-secondary-300/30"}`}>
                <span className={`text-xs font-medium truncate ${isActive ? "text-indigo-700" : "text-secondary-300"}`}>{getCustlabelByCustId(playerId)}</span>
                {versionCount > 0 && <span className={`text-xs font-medium rounded-full ${isActive ? "text-indigo-700" : "text-secondary-300"}`}>[{versionCount}]</span>}
            </div>
        );
    };

    return (
        <div className="flex flex-row gap-2">
            <div className="flex flex-col gap-4 my-4 flex-1">
                <div className="grid grid-cols-[120px_1fr] gap-y-2">
                    <span className="text-sm font-medium text-indigo-700">Active Players:</span>
                    <div className="flex gap-1 flex-wrap">{customerSlot.length > 0 ? customerSlot.map((elem, index) => <PlayerBadge key={index} playerId={elem} isActive={true} />) : <span className="text-sm text-gray-500 italic">No players selected</span>}</div>

                    <span className="text-sm font-medium text-secondary-300">Hidden Players:</span>
                    <div className="flex gap-1 flex-wrap">
                        {hiddenPlayers.length > 0 ? (
                            hiddenPlayers.map((playerId, index) => <PlayerBadge key={index} playerId={playerId} isActive={false} />)
                        ) : status === "loading" ? (
                            <div className="flex items-center">
                                <LoadingSpinner />
                                <span className="text-sm text-gray-500 ml-2">Loading players...</span>
                            </div>
                        ) : (
                            <span className="text-sm text-gray-500 italic">No additional players available</span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const DndButton = ({ setIsOpen = () => {} }) => {
    return (
        <button className="w-44 py-1 bg-indigo-700 hover:bg-indigo-500 text-white font-semibold shadow-button rounded-md transition-colors flex items-center justify-center gap-2" onClick={setIsOpen}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z" />
            </svg>
            Edit players
        </button>
    );
};

export { Selectedcust, DnDModal, DndButton };

Selectedcust.propTypes = {
    playerVersionAvailability: PropTypes.object,
};

Selectedcust.defaultProps = {
    playerVersionAvailability: {},
};
