import React, { useContext, useMemo } from "react";
import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import MeasuresContext from "../../contexts/Measures";
import * as Md from "../../md/full";
import _ from "lodash";
import { CustIDByScope } from "../../contexts/BrandConfiguratorProvider";
import FiltersContext from "../../contexts/Filters";
import VersionAnalysisReport from "./VersionAnalysisReport";
import { transformSlicesToBrandTree } from "../../routes/BrandModelAnalysis";

const BrandAnalysisWidget = ({ filters, ...props }) => {
    const { selectedKpi, seriesBy } = useContext(MeasuresContext);
    const { getFirstFilterValue } = useContext(FiltersContext);
    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy,
            slicesBy: [Md.CorrMake, Md.CorrModel, Md.CustId, Md.VersionStd],
            filters,
        },
    });

    const countryId = getFirstFilterValue("filterCountryId");
    const channel1 = getFirstFilterValue("filterChannel1");

    // Prepare filter selectors
    const makeModelObject = useMemo(() => {
        const slices = result?.data().slices().toArray();
        const mappedObj = transformSlicesToBrandTree(slices);
        return mappedObj;
    }, [result, status, selectedKpi]);

    return status === "loading" ? (
        <div className="flex flex-col items-center justify-center gap-2 min-h-[400px]">
            <div>
                <LoadingComponent />
            </div>
            <span>Loading...</span>
        </div>
    ) : (
        <div className="max-h-[450px]">
            <VersionAnalysisReport filters={filters} filterSelectors={makeModelObject} {...props} />
        </div>
    );
};

export default BrandAnalysisWidget;
