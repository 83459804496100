const dataTrends = [
    {
        name: "Player 1",
        data: [
            [1704067200000, null],
            [1704067200000, 406],
            [1706745600000, 409],
            [1709251200000, 409],
            [1711929600000, 409],
            [1704067200000, null],
            [1717200000000, 408],
            [1719878400000, 408],
            [1722470400000, 408],
            [1704067200000, null],
            [1704067200000, null],
            [1725494400000, 408],
            [1704067200000, null],
            [1728172800000, 395],
            [1704067200000, null],
            [1704067200000, null],
            [1730851200000, 401],
            [1704067200000, null],
            [1733443200000, 413],
            [1704067200000, null],
            [1736035200000, 413],
        ],
        events: {},
        point: {
            events: {},
        },
        customEvents: {
            series: {},
            point: {},
        },
    },
    {
        name: "Player 2",
        data: [
            [1729468800000, null],
            [1729468800000, null],
            [1729468800000, null],
            [1729468800000, null],
            [1729468800000, null],
            [1729468800000, null],
            [1729468800000, null],
            [1729468800000, null],
            [1729468800000, null],
            [1729468800000, null],
            [1729468800000, null],
            [1729468800000, null],
            [1729468800000, null],
            [1729468800000, null],
            [1729468800000, 372],
            [1730678400000, 372],
            [1729468800000, null],
            [1729468800000, null],
            [1733443200000, 372],
            [1735862400000, 372],
            [1729468800000, null],
        ],
        color: "#0F364A",
        events: {},
        point: {
            events: {},
        },
        customEvents: {
            series: {},
            point: {},
        },
    },
    {
        name: "Player 3",
        data: [
            [1701388800000, 419],
            [1704067200000, 419],
            [1706745600000, 423],
            [1709251200000, 396],
            [1711929600000, 395],
            [1701388800000, null],
            [1701388800000, null],
            [1719878400000, 455],
            [1722470400000, 455],
            [1725148800000, 455],
            [1701388800000, null],
            [1701388800000, null],
            [1728000000000, 469],
            [1701388800000, null],
            [1701388800000, null],
            [1730678400000, 469],
            [1701388800000, null],
            [1733270400000, 468],
            [1701388800000, null],
            [1735862400000, 468],
            [1701388800000, null],
        ],
        color: "#01839D",
        events: {},
        point: {
            events: {},
        },
        customEvents: {
            series: {},
            point: {},
        },
    },
];

const dates = [
    1701388800000, 1704067200000, 1706745600000, 1709251200000, 1711929600000, 1714521600000, 1717200000000, 1719878400000, 1722470400000, 1725148800000, 1725321600000, 1725494400000, 1728000000000, 1728172800000, 1729468800000, 1730678400000, 1730851200000, 1733270400000, 1733443200000,

    1735862400000, 1736035200000,
];

const overviewData = {
    mainData: [
        {
            name: "Vehicle 1",
            y: 483,
            color: "#506e96",
        },
        {
            name: "Vehicle 2",
            y: 425,
            color: "#506e96",
        },
        {
            name: "Vehicle 3",
            y: 319,
            color: "#506e96",
        },
        {
            name: "Vehicle 4",
            y: 254,
            color: "#506e96",
        },
    ],
    scatterData: [
        {
            type: "scatter",
            id: "CAP",
            name: "Player 1",
            color: "#192995",
            data: [
                {
                    x: 1,
                    y: 425,
                },
                {
                    x: 3,
                    y: 254,
                },
                {
                    x: 0,
                    y: 542,
                },
            ],
        },
        {
            type: "scatter",
            id: "MEA",
            name: "Player 2",
            color: "#96146E",
            data: [
                {
                    x: 1,
                    y: 483,
                },
                {
                    x: 3,
                    y: 315,
                },
                {
                    x: 0,
                    y: 483,
                },
                {
                    x: 2,
                    y: 319,
                },
            ],
        },
    ],
};

const tableData = [
    {
        key: "1",
        vehicle: "Vehicle 1",
        "Ayvens Config": 385,
        "Ayvens Showroom": 400,
        "Elite Auto": 402,
    },
    {
        key: "2",
        vehicle: "Vehicle 2",
        "Ayvens Config": 412,
        "Ayvens Showroom": 450,
        "Elite Auto": 415,
    },
    {
        key: "3",
        vehicle: "Vehicle 3",
        "Ayvens Config": 395,
        "Ayvens Showroom": 430,
        "Elite Auto": 396,
    },
    {
        key: "4",
        vehicle: "Vehicle 4",
        "Ayvens Config": 425,
        "Ayvens Showroom": 460,
        "Elite Auto": 418,
    },
];

const columns = [
    {
        title: "Vehicle",
        dataIndex: "vehicle",
        key: "vehicle",
        width: "30%",
    },
    {
        title: "Player 1",
        dataIndex: "Ayvens Config",
        key: "Ayvens Config",
    },

    {
        title: "Player 2",
        dataIndex: "Ayvens Showroom",
        key: "Ayvens Showroom",
    },

    {
        title: "Player 3",
        dataIndex: "Elite Auto",
        key: "Elite Auto",
    },
];

export { dataTrends, dates, overviewData, tableData, columns };
