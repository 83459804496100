import React, { useContext, useEffect, useState } from "react";

import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { newAbsoluteDateFilter, newAttributeSort, newMeasureSort, newPositiveAttributeFilter } from "@gooddata/sdk-model";

import * as Md from "../../../md/full";
import MeasuresContext from "../../../contexts/Measures";
import { useAppContext } from "../../../contexts/AppContext";
import { TrendChart } from "../../Visualization/TrendChart";
import UserContext from "../../../contexts/User";
import { PdfHeader } from "./BasketViz";
import moment from "moment";
import { BarChart } from "../../Visualization/BarChart";
import FiltersContext from "../../../contexts/Filters";
import { Selectedcust } from "../../Layout/Selectedcust";
const AggregatedBasketViz = ({ basket, selectedKpi, printMode, custIds, filterCountryId, filterMthCode, filterChannel1, filterDuration, filterExtrapType, filterRolledForward, printStatus, setIsLoading, adjdeposititle, props }) => {
    const { seriesBy } = useContext(MeasuresContext);
    const { getCountryByCode, dateRange } = useAppContext();
    const { defaultUserPref, kpiSettings } = useContext(UserContext);
    const { displayMode } = useContext(FiltersContext);
    const [versionStdFilter] = useState(
        newPositiveAttributeFilter(
            Md.VersionStd,
            basket.basketFilters.filter((elem) => elem.type === "version_std").map((elem) => elem.value),
        ),
    );
    const custSlots = Object.entries(defaultUserPref)
        .filter(([key, val]) => key.includes("customer_slot") && val !== null)
        .map(([key, val]) => val);
    const [custIdFilter] = useState(newPositiveAttributeFilter(Md.CustId, custSlots));

    const [dataSeriesBar, setDataSeriesBar] = useState({ name: undefined, data: [] });
    const [categories, setCategories] = useState([]);
    const [trendSeries, setTrendSeries] = useState([]);
    const [trendCategories, setTrendCategories] = useState([]);

    const maxValue = dataSeriesBar.data?.length > 0 && dataSeriesBar.data[0].y;
    const chartTitle = `${getCountryByCode(filterCountryId.positiveAttributeFilter.in?.values[0])} 
                | ${filterChannel1.positiveAttributeFilter.in?.values[0]} | ${filterDuration?.positiveAttributeFilter?.in?.values[0]} ${adjdeposititle ? "| " + adjdeposititle : ""} | 
                ${selectedKpi.name}`;
    var monthDate = moment(filterMthCode.positiveAttributeFilter.in?.values[0], "YYYY MM").format("MMMM YY");

    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy,
            slicesBy: [...displayMode.attributes],
            filters: [versionStdFilter, custIdFilter, filterCountryId, filterMthCode, filterChannel1, filterDuration, filterExtrapType, filterRolledForward],
            sortBy: [newMeasureSort(seriesBy[selectedKpi.index], "desc"), newAttributeSort(displayMode.attributes[displayMode.attributes.length - 1])],
        },
    });

    const formattedDateRange = dateRange.map((dt) => dt.format("YYYY-MM-DD"));
    const trendQueryFilters =
        formattedDateRange.length === 2
            ? [versionStdFilter, custIdFilter, filterCountryId, filterChannel1, filterDuration, filterExtrapType, filterRolledForward, newAbsoluteDateFilter(Md.DateDatasets.DateOfScraping.ref, formattedDateRange[0], formattedDateRange[1])]
            : [versionStdFilter, custIdFilter, filterCountryId, filterChannel1, filterDuration, filterExtrapType, filterRolledForward];
    const { result: trendResult, status: trendStatus } = useExecutionDataView({
        execution: {
            seriesBy,
            slicesBy: [...displayMode.attributes, Md.DateDatasets.DateOfScraping.Date.YyyyMmDd],
            filters: trendQueryFilters,
            sortBy: [newAttributeSort(displayMode.attributes[displayMode.attributes.length - 1])],
        },
    });

    useEffect(() => {
        if (status === "success" && trendStatus === "success") {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [status, trendStatus, setIsLoading]);

    useEffect(() => {
        const slices = result?.data().slices().toArray();
        if (slices) {
            const dataPoints = slices.map((slice) => {
                // const custId = slice.sliceTitles()[0];
                const value = slice.dataPoints().find((dp) => seriesBy[selectedKpi.index].measure.localIdentifier === dp.seriesDesc.measureDefinition.measure.localIdentifier)?.rawValue;
                return {
                    y: selectedKpi.index === 24 ? Number((Number(value) * 100).toFixed(2)) : Math.round(Number(value)),
                    color: "#506e96",
                };
            });
            setDataSeriesBar({
                name: selectedKpi.name,
                data: dataPoints,
            });
            const titles = slices.map((slice) => slice.sliceTitles().reduce((acc, sliceTitle) => (displayMode.attributes.length > 1 ? acc + " " + sliceTitle : sliceTitle)));
            setCategories(titles);
        }
    }, [result]);

    useEffect(() => {
        const serie = trendResult?.data().series().firstForMeasure(seriesBy[selectedKpi.index]);
        const slices = trendResult?.data().slices().toArray();
        const mthValues = slices
            ?.map((slice) => new Date(slice.sliceTitles()[slice.sliceTitles().length - 1]).getTime())
            .reduce((acc, mthCode) => {
                !acc.includes(mthCode) && acc.push(mthCode);
                return acc;
            }, []);
        mthValues?.sort();

        if (serie && mthValues) {
            const dataPoints = serie?.dataPoints().reduce((acc, dp) => {
                const sliceTitles = dp.sliceDesc.sliceTitles();
                const mthValue = new Date(sliceTitles[sliceTitles.length - 1]).getTime();
                const objName = sliceTitles.slice(0, sliceTitles.length - 1).reduce((acc, nextElem) => (displayMode.attributes.length > 1 ? acc + " " + nextElem : nextElem));
                let obj = acc.find((item) => item.name === objName);
                // Get mthValue position in the mthValues list
                const mthIndex = mthValues.findIndex((mth) => mth === mthValue);
                const dpValue = Number(dp.rawValue) === 0 ? null : selectedKpi.index === 24 ? Number((Number(dp.rawValue) * 100).toFixed(2)) : Math.round(Number(dp.rawValue));
                if (obj) {
                    obj.data[mthIndex] = [mthValue, dpValue];
                } else {
                    let dataArr = Array(mthValues.length - 1).fill([mthValue, null]);
                    dataArr.splice(mthIndex, 1, [mthValue, dpValue]);
                    acc.push({
                        name: objName,
                        data: dataArr,
                        // color: getColorByCustId(custId),
                    });
                }
                return acc;
            }, []);

            setTrendSeries(dataPoints);
            setTrendCategories(mthValues);
        }
    }, [trendResult]);

    return status === "loading" && trendStatus === "loading" ? (
        <div className="h-60 py-2">
            <LoadingComponent />
        </div>
    ) : (
        <div className="flex flex-col gap-20">
            {printMode !== "trends" && (
                <div className={"break-after-page space-y-4 "}>
                    {printStatus ? (
                        <PdfHeader
                            country={getCountryByCode(filterCountryId.positiveAttributeFilter.in?.values[0])}
                            channel1={filterChannel1.positiveAttributeFilter.in?.values[0]}
                            duration={filterDuration.positiveAttributeFilter.in?.values[0]}
                            kpi={selectedKpi.name}
                            basketName={basket.name}
                            date={monthDate}
                            adjdeposititle={adjdeposititle}
                        />
                    ) : (
                        <div className="my-4 flex flex-col gap-2">
                            <span className="grow leading-5 text-xl font-bold text-indigo-700">Basket {basket.name}</span>
                            <Selectedcust />
                            <span className="font-semibold text-lg text-steel">Average Spot Figures - {chartTitle}</span>
                        </div>
                    )}
                    {dataSeriesBar.data.length <= 0 ? (
                        <div className="py-2">
                            <LoadingComponent />
                        </div>
                    ) : (
                        <BarChart
                            series={[dataSeriesBar]}
                            categories={categories}
                            maxValue={maxValue}
                            title={null}
                            width={printStatus ? 1000 : undefined}
                            height={!printStatus ? 600 : undefined}
                            barWidth={30}
                            marginTop={5}
                            pdfMode={printStatus}
                            filters={[versionStdFilter, custIdFilter, filterCountryId, filterMthCode, filterChannel1, filterDuration, filterExtrapType, filterRolledForward]}
                            id={"aggregated-basket-bar-chart"}
                            section={"avgbasketbar"}
                            metadata={{ displayMode, kpiSettings }}
                            {...props}
                        />
                    )}
                </div>
            )}
            {printMode !== "bars" && (
                <div className="break-after-page space-y-4">
                    {printStatus ? (
                        <PdfHeader
                            country={getCountryByCode(filterCountryId.positiveAttributeFilter.in?.values[0])}
                            channel1={filterChannel1.positiveAttributeFilter.in?.values[0]}
                            duration={filterDuration.positiveAttributeFilter.in?.values[0]}
                            kpi={selectedKpi.name}
                            basketName={basket.name}
                            date={monthDate}
                            adjdeposititle={adjdeposititle}
                        />
                    ) : (
                        <span className="font-semibold text-lg text-steel my-2">Average Trends - {chartTitle}</span>
                    )}
                    {trendSeries?.length <= 0 ? (
                        <div className="py-2">
                            <LoadingComponent />
                        </div>
                    ) : (
                        <TrendChart
                            title={null}
                            series={trendSeries}
                            categories={trendCategories}
                            scaleUnit="datetime"
                            height={!printStatus ? 700 : 680}
                            width={printStatus ? 1000 : undefined}
                            marginBottom={trendSeries.length > 6 ? 160 : 100}
                            pdfMode={printStatus}
                            legendStyle={{
                                fontSize: "10px",
                                fontWeigth: "600",
                                color: "#595959",
                            }}
                            filters={[versionStdFilter, custIdFilter, filterCountryId, filterChannel1, filterDuration, filterExtrapType, filterRolledForward]}
                            id={"aggregated-basket-trend-chart"}
                            section={"avgbasketline"}
                            metadata={{ displayMode, kpiSettings }}
                            {...props}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export { AggregatedBasketViz };
