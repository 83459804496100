import React from "react";
import HighchartsComponent from "../HighchartsComponent";
import { useAppContext } from "../../contexts/AppContext";

export function ScatterChart({ series, barsColor = undefined, setSelectedCustomer, selectedCustomer, title, country, width = null, height = 720, pdfMode = false, marginLeft = null, marginTop = null, corrline, screenshotName = "scatter-chart", ...props }) {
    const { getCustlabelByCustId } = useAppContext();
    const { kpiSettings } = props;

    return (
        <HighchartsComponent
            widgetProps={{
                id: props.id,
                filters: props.filters,
                country,
                flag_services: kpiSettings,
                section: props.section,
            }}
            options={{
                chart: {
                    type: "scatter",
                    marginLeft: marginLeft,
                    // series.length > 0 ? 190 : 0,
                    marginTop: marginTop !== null ? marginTop : pdfMode ? 0 : 60,
                    spacingLeft: !pdfMode ? 15 : null,
                    // spacingRight: 10,
                    width: width,
                    height: pdfMode ? 600 : height,
                },
                plotOptions: {
                    scatter: {
                        color: barsColor,
                        marker: {
                            symbol: "circle",
                            radius: 6,
                            states: {
                                hover: {
                                    enabled: true,
                                    lineColor: "rgb(100,100,100)",
                                },
                            },
                        },
                        states: {
                            hover: {
                                marker: {
                                    enabled: false,
                                },
                            },
                        },
                        jitter: {
                            x: 0.1,
                            y: 0.1,
                        },
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return `<b>${this.point.m} ${this.point.t}</b>`;
                            },
                            style: {
                                fontSize: "10px",
                                fontWeight: "bold",
                                color: "#595959",
                            },
                        },
                        events: {
                            legendItemClick: function () {
                                if (this.name !== "Correlation line") {
                                    selectedCustomer?.includes(this.name) ? setSelectedCustomer(selectedCustomer.filter((elem) => elem !== this.name)) : setSelectedCustomer([...selectedCustomer, this.name]);
                                }
                            },
                        },
                    },
                    line: {
                        color: "rgb(100,100,100)",
                        marker: {
                            enabled: false,
                        },
                    },
                },
                tooltip: {
                    formatter: function () {
                        if (this.series.name !== "Correlation line") return `<b>${getCustlabelByCustId(this.series.name)} - ${this.point.m} ${this.point.t}</b><br/>Leasing Factor : ${this.point.z}<br/>Monthly rate : ${this.point.y}<br/>List Price : ${this.point.x}`;
                        else return `<b>${this.series.name}</b><br/>Monthly rate : ${Math.round(this.point.y)}<br/>List Price : ${Math.round(this.point.x)} <br/>Correlation coefficient : ${Number(corrline.r).toFixed(2)}`;
                    },
                },
                legend: {
                    enabled: true,
                    labelFormatter: function () {
                        return `<div style="width: 200px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">${getCustlabelByCustId(this.name)}</div>`;
                    },
                },
                title: {
                    text: title,
                    align: "center",
                    style: {
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#595959",
                    },
                },
                xAxis: {
                    title: {
                        text: "List Price",
                        style: {
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#595959",
                        },
                    },
                    labels: {
                        format: `{value} ${["UK", "GB"].includes(country) ? "£" : "€"}`,
                    },
                    gridLineWidth: 1,
                },
                yAxis: {
                    labels: {
                        format: `{value} ${["UK", "GB"].includes(country) ? "£" : "€"}`,
                    },
                    title: {
                        text: "Monthly Rate",
                        style: {
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#595959",
                        },
                    },
                },
                series:
                    series.length > 0
                        ? [
                              ...series,
                              {
                                  type: "line",
                                  name: "Correlation line",
                                  data: corrline.line,
                                  color: "rgb(100,100,100)",
                                  dashStyle: "Dash",
                              },
                          ]
                        : [],
                lang: {
                    noData: "No data available",
                },
                noData: {
                    style: {
                        fontWeight: "bold",
                        fontSize: "15px",
                        color: "#595959",
                    },
                },
            }}
            enableScreenshot={!pdfMode}
            imgName={screenshotName}
            {...props}
        />
    );
}
