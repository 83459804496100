import React, { useContext, useState } from "react";

import HighchartsComponent from "../HighchartsComponent";
import { useBrandConfigurator } from "../../contexts/BrandConfiguratorProvider";
import MeasuresContext from "../../contexts/Measures";

import { DownOutlined, UpOutlined } from "@ant-design/icons";

import * as Md from "../../md/full";
import "../../styles/brandModelAnalysis.css";
import _ from "lodash";
import { useAppContext } from "../../contexts/AppContext";

const VersionAnalysisReport = ({ filters, filterSelectors, ...props }) => {
    const widgetId = "version-analysis-chart";
    const { deselectConfigurator } = useBrandConfigurator();
    const { selectedKpi } = useContext(MeasuresContext);
    const { getCustlabelByCustId, getColorByCustId } = useAppContext();

    const { title, subtitle } = props;
    let selectedPlayers = props.selectedPlayers;

    if (!selectedPlayers) {
        const playerFilter = filters.find((obj) => obj.positiveAttributeFilter.displayForm.identifier === Md.CustId.attribute.displayForm.identifier);
        const playerCustIDs = playerFilter?.positiveAttributeFilter.in.values.length > 0 ? playerFilter.positiveAttributeFilter.in.values : ["OEM", "CAP"];
        selectedPlayers = playerCustIDs.map((custId) => ({
            custId: custId,
            custLabel: getCustlabelByCustId(custId),
            custColor: getColorByCustId(custId),
        }));
    }

    const dataVersionsFlat = Object.entries(filterSelectors)
        .reduce((acc, [brand, modelsObj]) => {
            const flatVersionsByModel = Object.entries(modelsObj)
                .reduce((accModel, [model, versionArr]) => {
                    const arr = versionArr.map((versionObj) => ({
                        ...versionObj,
                        model: model,
                        y: _.min(
                            Object.entries(versionObj)
                                .filter(([key, val]) => selectedPlayers.map((p) => p.custId).includes(key) && val)
                                .map(([key, custValues]) => custValues[selectedKpi.index]),
                        ),
                    }));
                    return [...accModel, ...arr];
                }, [])
                .map((obj) => ({
                    ...obj,
                    brand: brand,
                }));
            return [...acc, ...flatVersionsByModel];
        }, [])
        .sort((a, b) => a.y - b.y);

    const categories = dataVersionsFlat.map((elem) => elem.label);
    const series = [
        {
            name: "Cheapest",
            type: "bar",
            data: dataVersionsFlat.map((obj) => (obj.y > 0 ? selectedKpi.roundValue(obj.y) : null)),
            color: "white",
            borderColor: "#3a0ca3",
            borderWidth: 2,
            enableMouseTracking: false,
            legend: {
                symbolRadius: 0,
                symbolWidth: 0,
                squareSymbol: false,
            },
        },
        ...selectedPlayers.map((p) => ({
            name: p.custLabel,
            type: "scatter",
            color: p.custColor,
            showInLegend: dataVersionsFlat.filter((serie) => p.custId in serie && serie[p.custId] && serie[p.custId][selectedKpi.index] > 0).length > 0,
            legend: {
                symbolRadius: 0,
            },
            data: dataVersionsFlat.map((obj) => (p.custId in obj && obj[p.custId] ? selectedKpi.roundValue(obj[p.custId][selectedKpi.index]) : null)),
        })),
    ];

    // .map((val) => selectedKpi.roundValue(val));

    const showFullGraph = categories.length <= 8;
    const report = (
        <HighchartsComponent
            widgetProps={{
                id: widgetId,
                filters,
                section: "oem-views",
            }}
            options={{
                chart: {
                    type: "bar",
                    height: categories.length > 15 ? categories.length * 40 : props.height,
                    marginLeft: props.widgetUid ? 270 : 480,
                },
                plotOptions: {
                    scatter: {
                        stickyTracking: false,
                        showInLegend: false,
                        // findNearestPointBy: 'y',
                        marker: {
                            radius: 5,
                            symbol: "circle",
                        },
                    },
                    bar: {
                        series: {
                            pointPadding: 10,
                            groupPadding: 10,
                            stacking: "normal",
                        },
                        pointWidth: categories.length <= 7 ? 22 : 20,
                    },
                },
                title: {
                    text: title,
                    align: "center",
                    style: {
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "#3a0ca3",
                    },
                },
                subtitle: {
                    text: subtitle,
                    align: "center",
                    style: {
                        fontSize: "12px",
                        fontWeight: 600,
                        color: "#506e96",
                    },
                },
                xAxis: {
                    categories: categories,
                    labels: {
                        padding: 3,
                        style: {
                            textOverflow: "ellipsis",
                            // whiteSpace: 'nowrap',
                            overflow: "ellipsis",
                        },
                        useHTML: true,
                        formatter: function () {
                            return props.widgetUid ? this.value : `<span class="versionxAxisLabel">${this.value}</span>`;
                        },
                        events: {
                            click: function (event) {
                                if (props.widgetUid) return;
                                const versionObj = dataVersionsFlat.find((item) => item.label === event.target.textContent);
                                deselectConfigurator(versionObj.brand, versionObj.model, [versionObj]);
                            },
                        },
                    },
                },
                yAxis: {
                    title: {
                        text: null,
                    },
                },
                tooltip: {
                    formatter: function () {
                        return `${this.x.name}<br/>${this.series.name} <b>${this.y}</b>`;
                    },
                },
                series: series,
                legend: {
                    useHTML: true,
                    symbolRadius: 0,
                    symbolWidth: 0,
                    squareSymbol: false,
                    labelFormatter: function () {
                        return this.name === "Cheapest"
                            ? `<div style="display:flex; gap:3px; align-items: center; margin: 4px 2px;"><div style="width:12px; height:12px; border: 2px solid #3a0ca3;"></div> <span>${this.name}</span></div>`
                            : `<div style="display:flex; gap:3px; align-items: center; margin: 4px 2px;"><div style="width:12px; height:12px; border-radius: 20px; background-color: ${this.color};"></div> <span>${this.name}</span></div>`;
                    },
                },
            }}
            menuButtonPosition={props.widgetUid ? "outside" : "inside"}
            {...props}
        />
    );

    return props.widgetUid ? report : <VersionAnalysisWrapper showFullGraph={showFullGraph} reportComponent={report} />;
};

const VersionAnalysisWrapper = ({ reportComponent, showFullGraph, ...props }) => {
    const [showMore, setShowMore] = useState(false);
    return (
        <div>
            <div
                className={!showFullGraph && !showMore ? "h-[300px] overflow-hidden bg-white" : ""}
                style={
                    !showFullGraph && !showMore
                        ? {
                              maskImage: "linear-gradient(to bottom, black 70%, transparent 100%)",
                              WebkitMaskImage: "linear-gradient(to bottom, black 70%, transparent 100%)",
                          }
                        : {}
                }
            >
                {reportComponent}
                {props.footnote && <span className="pl-3 pb-2 text-left text-xs font-medium">{props.footnote}</span>}
            </div>

            {!showFullGraph && (
                <div
                    className={!showMore ? "mx-auto max-w-fit flex items-center justify-center gap-2 px-4 py-1.5 mb-2 rounded-md hover:bg-backgroundColor cursor-pointer" : "mx-auto max-w-fit flex items-center justify-center mb-2 p-2 rounded-full hover:bg-backgroundColor"}
                    onClick={() => setShowMore(!showMore)}
                >
                    {!showMore ? (
                        <>
                            <DownOutlined
                                // className={"p-2 rounded-full hover:bg-backgroundColor"}
                                style={{
                                    color: "#506e96",
                                }}
                            />
                            <span className="text-steel font-semibold">Show versions</span>
                        </>
                    ) : (
                        <UpOutlined
                            style={{
                                color: "#506e96",
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default VersionAnalysisReport;
