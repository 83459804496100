import React, { useContext, useEffect, useState } from "react";
import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";

import * as Md from "../../md/full";
import MeasuresContext from "../../contexts/Measures";
import { newAttributeSort } from "@gooddata/sdk-model";
import { BrandColors, CustIDByScope, useBrandConfigurator } from "../../contexts/BrandConfiguratorProvider";
import HighchartsComponent from "../HighchartsComponent";
import FiltersContext from "../../contexts/Filters";
import { calculationModes } from "../../routes/BrandModelAnalysis";
import { useAppContext } from "../../contexts/AppContext";
import { utils, writeFileXLSX } from "xlsx";
import { message } from "antd";

const widgetId = "brand-trends-chart";

function TrendBrandAnalysis({ filters, calculationMode = "average", selectedPlayers, ...props }) {
    const { seriesBy, selectedKpi } = useContext(MeasuresContext);
    const { getFirstFilterValue } = useContext(FiltersContext);
    const { getCustlabelByCustId } = useAppContext();
    const { exportTrends, setExportTrends } = useBrandConfigurator();
    const [monthList, setMonthList] = useState([]);
    // const { currentFilter, getFirstFilterValue } = useContext(FiltersContext);

    const countryId = getFirstFilterValue("filterCountryId");
    const channel1 = getFirstFilterValue("filterChannel1");

    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy,
            slicesBy: [Md.CorrMake, Md.CorrModel, Md.CustId, Md.MthCode, Md.VersionStd, Md.Channel3],
            filters,
            sortBy: [newAttributeSort(Md.MthCode, "asc")],
        },
    });
    const [graphSeries, setGraphSeries] = useState([]);
    const flatColors = Object.values(BrandColors);

    useEffect(() => {
        const slices = result?.data().slices().toArray();
        // Data prepared to be conform with trends format
        const preparedData = slices?.reduce((acc, slice) => {
            const [brand, model, custId, mthCode, versionStd] = slice.sliceTitles();
            const key = `${brand}-${model}`;
            const kpiValue = slice.dataPoints()[selectedKpi.index].rawValue !== null ? Number(slice.dataPoints()[selectedKpi.index].rawValue) : null;
            const versionObj = {
                versionStd: versionStd,
                value: kpiValue,
            };
            if (key in acc) {
                if (custId in acc[key]) {
                    if (mthCode in acc[key][custId]) {
                        acc[key][custId][mthCode] = [...acc[key][custId][mthCode], versionObj];
                    } else {
                        acc[key][custId][mthCode] = [versionObj];
                    }
                } else {
                    acc[key] = {
                        ...acc[key],
                        [custId]: {
                            [mthCode]: [versionObj],
                        },
                    };
                }
            } else {
                acc = {
                    ...acc,
                    [key]: {
                        [custId]: {
                            [mthCode]: [versionObj],
                        },
                    },
                };
            }
            return acc;
        }, {});
        // Calculate avg/median for each model
        const calculatedData = preparedData
            ? Object.keys(preparedData).reduce((acc, key) => {
                  acc[key] = Object.keys(preparedData[key]).reduce((accModel, custId) => {
                      accModel[custId] = Object.entries(preparedData[key][custId]).reduce((accCustId, [mthCode, versionStdArr]) => {
                          const noEmptyValues = versionStdArr.filter((obj) => obj?.value).map((obj) => obj.value);
                          accCustId[mthCode] = noEmptyValues.length > 0 ? selectedKpi.roundValue(calculationModes[calculationMode].calculationMethod(noEmptyValues)) : null;
                          return accCustId;
                      }, {});
                      return accModel;
                  }, {});
                  return acc;
              }, {})
            : {};
        // Getting the month list
        const monthCodeArr = slices
            ? slices
                  ?.reduce((acc, slice) => {
                      const monthCode = slice.sliceTitles()[3];
                      if (!acc.includes(monthCode)) acc = [...acc, monthCode];
                      return acc;
                  }, [])
                  .sort((a, b) => a.localeCompare(b))
            : [];

        if (monthCodeArr.length > 0) {
            const gSeries = calculatedData
                ? Object.keys(calculatedData).reduce((acc, model, index) => {
                      const brandSerie = Object.keys(calculatedData[model]).map((custId) =>
                          Object.assign({
                              name: `${model}-${getCustlabelByCustId(custId)}`,
                              data: monthCodeArr.map((mthCode) => (mthCode in calculatedData[model][custId] ? calculatedData[model][custId][mthCode] : null)),
                              color: flatColors.pop(),
                              // dashStyle: promoConfig === "promo" ? "Dash" : "Solid",
                          }),
                      );
                      return [...acc, ...brandSerie];
                  }, [])
                : [];
            setGraphSeries(gSeries);
            setMonthList(monthCodeArr);
        }

        return () => {
            setGraphSeries([]);
            setMonthList([]);
        };
    }, [result, selectedKpi, calculationMode]);

    useEffect(() => {
        if (exportTrends) {
            if (status === "loading") {
                message.loading({
                    content: "Your file will be ready as soon as the trends report is completed",
                    style: {
                        marginTop: "20vh",
                    },
                });
            } else {
                const slices = result?.data().slices().toArray();
                // Data prepared to be conform with trends format
                const dataExport = slices?.reduce(
                    (acc, slice) => {
                        // First arr for mthCodes to be used as a header file second for data
                        const [brand, model, custId, mthCode, versionStd, financeType] = slice.sliceTitles();
                        const custLabel = getCustlabelByCustId(custId);
                        const kpiValue = slice.dataPoints()[selectedKpi.index].rawValue !== null ? selectedKpi.roundValue(Number(slice.dataPoints()[selectedKpi.index].rawValue)) : null;
                        const index = acc[1].findIndex((obj) => obj.Version === versionStd && obj.Player === custLabel);
                        if (!acc[0].includes(mthCode)) {
                            acc[0].push(mthCode);
                        }
                        if (index !== -1) {
                            acc[1][index] = {
                                ...acc[1][index],
                                [mthCode]: kpiValue,
                            };
                        } else {
                            acc[1].push({
                                Version: versionStd,
                                Make: brand,
                                Model: model,
                                Player: custLabel,
                                Channel1: channel1,
                                "Finance Type": financeType,
                                [mthCode]: kpiValue,
                            });
                        }
                        return acc;
                    },
                    [[], []],
                );
                // .sort((e1, e2) => (e1.Version.localeCompare(e2.Version) === 0 ? e1.Player.localeCompare(e2.Player) : e1.Version.localeCompare(e2.Version)));
                if (dataExport && dataExport[1].length > 0) {
                    const headers = ["Version", "Make", "Model", "Player", "Channel1", "Finance Type", ...dataExport[0].sort((e1, e2) => e1.localeCompare(e2))];
                    const data = dataExport[1].sort((e1, e2) => (e1.Version.localeCompare(e2.Version) === 0 ? e1.Player.localeCompare(e2.Player) : e1.Version.localeCompare(e2.Version)));
                    const ws = utils.json_to_sheet(data, {
                        header: headers,
                    });
                    const wb = utils.book_new();
                    utils.book_append_sheet(wb, ws, "Data");
                    writeFileXLSX(wb, "brand_model_analysis_trends_export.xlsx");
                    setExportTrends(false);
                }
            }
        }

        return () => {
            if (exportTrends && status !== "loading") setExportTrends(false);
        };
    }, [result, status, exportTrends]);

    return status === "loading" || monthList.length === 0 ? (
        <div className="flex flex-col items-center justify-center gap-2 min-h-[400px]">
            <div>
                <LoadingComponent />
            </div>
            <span>Loading trends...</span>
        </div>
    ) : (
        <div className="relative flex flex-col gap-2">
            <TrendChart
                series={graphSeries}
                categories={monthList}
                filters={filters}
                id={widgetId}
                section={""}
                metadata={undefined}
                widgetProps={{
                    id: widgetId,
                    filters,
                    section: "oem-views",
                    calculationMode: calculationMode,
                }}
                menuButtonPosition={props.widgetUid ? "outside" : "inside"}
                marginBottom={70}
                {...props}
            />
            {props.footnote && <span className="pl-3 pb-2 text-left text-xs font-medium">{props.footnote}</span>}
        </div>
    );
}

const TrendChart = ({ id, filters, series, categories, ...props }) => (
    <HighchartsComponent
        widgetProps={{
            id,
            filters,
            section: "oem-views",
        }}
        options={{
            chart: {
                type: "spline",
                height: 500,
                marginTop: 65,
                spacingBottom: 2,
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: true,
                        symbol: "circle",
                    },
                    // dataLabels: {
                    //     enabled: true,
                    // },
                    connectNulls: true,
                    // enableMouseTracking: mouseTracking,
                    cursor: "pointer",
                },
            },
            legend: {
                enabled: true,
                // verticalAlign: 'middle',
                // align: 'right',
                padding: 12,
                itemStyle: {
                    fontSize: "10px",
                    fontWeigth: "600",
                    color: "#595959",
                },
            },
            title: {
                text: props.title,
                align: "center",
                style: {
                    fontSize: "15px",
                    fontWeight: "600",
                    color: "#3a0ca3",
                },
            },
            subtitle: {
                text: props.subtitle,
                align: "center",
                style: {
                    fontSize: "12px",
                    fontWeight: 600,
                    color: "#506e96",
                },
            },
            xAxis: {
                categories: categories,
                crosshair: true,
            },
            yAxis: {
                title: {
                    text: null,
                },
            },
            series: series.map((serie) => {
                if (serie.name.length === 3) {
                    return {
                        ...serie,
                        name: serie.name,
                    };
                } else {
                    return serie;
                }
            }),
            lang: {
                noData: "No data available",
            },
            noData: {
                style: {
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "#595959",
                },
            },
        }}
        imgName={"brand-model-trends"}
        {...props}
    />
);

export default TrendBrandAnalysis;
