import React, { useContext, useEffect, useMemo, useState } from "react";
import MakeConfigurator from "../components/BrandModelAnalysis/BrandConfigurator";
import { ColumnChartAnalysis } from "../components/BrandModelAnalysis/ColumnChartAnalysis";
import TrendBrandAnalysis from "../components/BrandModelAnalysis/TrendBrandAnalysis";
import MeasuresContext from "../contexts/Measures";
import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { TbError404 } from "react-icons/tb";

import * as Md from "../md/full";
import FiltersContext from "../contexts/Filters";
import { newPositiveAttributeFilter } from "@gooddata/sdk-model";
import LoadingSpinner from "../components/LoadingSpinner";
import { CustIDByScope, useBrandConfigurator } from "../contexts/BrandConfiguratorProvider";
import { ColumnModelAnalysis } from "../components/BrandModelAnalysis/ColumnModelAnalysis";
import VersionAnalysisReport from "../components/BrandModelAnalysis/VersionAnalysisReport";
import _ from "lodash";
import { useAppContext } from "../contexts/AppContext";
import { calculateAvg, calculateMedian } from "../utils/calculationUtils";
import { Modal, Tooltip } from "antd";
import { FaRegCheckCircle } from "react-icons/fa";
import { ActionButton } from "../components/Buttons/PrimaryButton";
import { AiOutlineEdit } from "react-icons/ai";

const widgetHeight = 550;
const widgetStyle = `pt-1 px-1 border border-gray-300 rounded-md shadow-md min-h-[${widgetHeight}px]`;

const calculationModes = {
    average: {
        label: "Average",
        brandLabel: "Average",
        calculationMethod: (arr) => calculateAvg(arr),
    },
    median: {
        label: "Median",
        brandLabel: "Average of Median",
        calculationMethod: (arr) => calculateMedian(arr),
    },
};

function BrandModelAnalysis(props) {
    const { currentFilter, getFirstFilterValue, getFilterValue, getFiltersForFootnote, updateFilter } = useContext(FiltersContext);
    const { seriesBy, selectedKpi } = useContext(MeasuresContext);
    const { calculationMode } = useAppContext();
    const { filterSelectors, selectedPlayers, setFilterSelector, toGDFilters, setStatus } = useBrandConfigurator();
    const countryId = getFirstFilterValue("filterCountryId");
    const brandModelFilters = [
        ...currentFilter.filter((filterObj) => filterObj?.positiveAttributeFilter?.in?.values.length > 0 && ![Md.CorrMake, Md.CorrModel, Md.CustId, Md.CustLabel].map((obj) => obj.attribute.displayForm.identifier).includes(filterObj?.positiveAttributeFilter.displayForm.identifier)),
        newPositiveAttributeFilter(Md.CustId, [...selectedPlayers.map((player) => player.custId)]),
    ];
    const footnote = getFiltersForFootnote();
    // ?.split(' | ').filter(item => !(item.includes('Make') || item.includes('Model'))).join(" | ");

    const filters = [...brandModelFilters, ...toGDFilters()];
    const filtersTrends = filters.filter((obj) => obj?.positiveAttributeFilter.displayForm.identifier !== Md.MthCode.attribute.displayForm.identifier);

    const [corrMakeFilterValues, setCorrMakeFilterValues] = useState(getFilterValue("filterMake"));
    const [corrModelFilterValues, setCorrModelFilterValues] = useState(getFilterValue("filterModel"));

    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy,
            slicesBy: [Md.CorrMake, Md.CorrModel, Md.CustId, Md.VersionStd],
            filters: brandModelFilters,
        },
    });

    const makeModelVersionTree = useMemo(() => {
        const slices = result?.data().slices().toArray();
        const mappedObj = slices ? transformSlicesToBrandTree(slices) : {};
        return mappedObj;
    }, [result, status, selectedKpi]);

    const generateSubTitle = ({ isTrends = false }) => {
        return [countryId, getFirstFilterValue("filterDuration"), getFirstFilterValue("filterChannel1"), !isTrends ? getFirstFilterValue("filterMthCode") : null, selectedKpi.name, ["UK", "GB"].includes(countryId) ? "GBP" : "EUR"].filter((item) => item !== null).join(", ");
    };

    useEffect(() => {
        // When makeModel list changed, sync the values of the selected filters
        if (Object.keys(makeModelVersionTree).length > 0 && Object.keys(filterSelectors).length > 0) {
            const configuratorSynced = Object.keys(filterSelectors).reduce((acc, make) => {
                if (make in makeModelVersionTree) {
                    acc[make] = Object.keys(filterSelectors[make]).reduce((accMake, model) => {
                        const versionsArr = filterSelectors[make][model].filter((versionObj) => make in makeModelVersionTree && model in makeModelVersionTree[make] && makeModelVersionTree[make][model].map((obj) => obj.label).includes(versionObj.label));
                        const syncedVersions = versionsArr.map((versionObj) => makeModelVersionTree[make][model].find((item) => item.label === versionObj.label));
                        if (model in makeModelVersionTree[make]) accMake[model] = syncedVersions.length > 0 ? syncedVersions : makeModelVersionTree[make][model];
                        return accMake;
                    }, {});
                }
                return acc;
            }, {});
            setFilterSelector(configuratorSynced);
        }
    }, [makeModelVersionTree]);

    useEffect(() => {
        setStatus(status);
    }, [status]);

    useEffect(() => {
        // Reset the filters for make and model
        updateFilter("filterMake", newPositiveAttributeFilter(Md.CorrMake, []));
        updateFilter("filterModel", newPositiveAttributeFilter(Md.CorrMake, []));
    }, []);

    return status === "loading" ? (
        <LoadingSpinner />
    ) : makeModelVersionTree ? (
        <>
            <div className="grid grid-cols-12 gap-4 grid-rows-2">
                {Object.keys(filterSelectors).length > 0 ? (
                    <>
                        <div className={`col-span-4 ${widgetStyle}`}>
                            <ColumnChartAnalysis filters={filters} calculationMode={calculationMode} selectedPlayers={selectedPlayers} title={`Monthly Rate ${calculationModes[calculationMode].brandLabel} by Brand`} subtitle={generateSubTitle({ isTrends: false })} footnote={footnote} />
                        </div>
                        <div className={`col-span-5 ${widgetStyle}`}>
                            <TrendBrandAnalysis filters={filtersTrends} calculationMode={calculationMode} selectedPlayers={selectedPlayers} title={`Monthly Rate ${calculationModes[calculationMode].brandLabel} Trends by Model`} subtitle={generateSubTitle({ isTrends: true })} footnote={footnote} />
                        </div>
                    </>
                ) : (
                    <div className="col-span-8 flex flex-col items-center justify-center gap-2">
                        <span className="text-xl font-bold text-indigo-700">Quickly compare models and versions</span>
                        <span className="text-lg font-medium text-indigo-700">Select the brands, models or versions in the Selection box at the right</span>
                    </div>
                )}
                <div className={Object.keys(filterSelectors).length > 0 ? "col-span-3" : "col-span-4"}>
                    <MakeConfigurator makeModelVersionTree={makeModelVersionTree} height={widgetHeight} selectedPlayers={selectedPlayers} selectedKpi={selectedKpi} />
                </div>

                {Object.keys(filterSelectors).length > 0 && (
                    <div className={`col-span-full ${widgetStyle} max-h-[${widgetHeight + 10}px]`}>
                        <ColumnModelAnalysis
                            filters={filters}
                            selectedPlayers={selectedPlayers}
                            calculationMode={calculationMode}
                            calculationHandler={calculationModes[calculationMode].calculationMethod}
                            title={`Monthly Rate ${calculationModes[calculationMode].label} by Model`}
                            subtitle={generateSubTitle({ isTrends: false })}
                            footnote={footnote}
                            height={520}
                        />
                    </div>
                )}
                {Object.keys(filterSelectors).length > 0 && (
                    <div className={`col-span-full ${widgetStyle} max-h-[${widgetHeight + 10}px]`}>
                        <VersionAnalysisReport filterSelectors={filterSelectors} filters={filters} selectedPlayers={selectedPlayers} title={"Monthly Rate by Version"} subtitle={generateSubTitle({ isTrends: false })} footnote={footnote} height={520} />
                    </div>
                )}
            </div>
            <PlayerSelectionModal />
        </>
    ) : (
        <div className="flex items-center gap-2 justify-center">
            <TbError404 className="w-10 h-10 text-steel" />
            <span className="text-lg font-medium text-indigo-500">These views are compatible only with the OEMs, make sure that the activated filters are compatible with the OEM data</span>
        </div>
    );
}

const DropDownPlayerField = (props) => {
    const { selectedPlayers, showPlayerSelectionModal, setShowPlayerSelectionModal, statusQuery } = useBrandConfigurator();

    return (
        <div className="space-y-1">
            <span className="font-medium text-sm text-indigo-700">Selected players</span>
            <div className={(statusQuery === "loading" ? "opacity-50 cursor-progress pointer-events-none" : "") + " flex items-end gap-4"}>
                <div className="w-6 h-6">
                    <AiOutlineEdit
                        className="text-indigo-700 w-6 h-6 hover:opacity-50 cursor-pointer"
                        title="Edit players"
                        onClick={(e) => {
                            e.preventDefault();
                            setShowPlayerSelectionModal(!showPlayerSelectionModal);
                        }}
                    />
                </div>
                <span className="text-xs text-gray-400 font-medium">{selectedPlayers.map((p) => p.custLabel).join(", ")}</span>
            </div>
        </div>
    );
};

function PlayerSelectionModal(props) {
    const { currentFilter } = useContext(FiltersContext);
    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy: [Md.VersionCount],
            slicesBy: [Md.CustId],
            filters: currentFilter,
        },
    });

    const { getCustlabelByCustId, getColorByCustId } = useAppContext();
    const { selectedPlayers, setSelectedPlayers, showPlayerSelectionModal, setShowPlayerSelectionModal } = useBrandConfigurator();
    const [items, setItems] = useState([]);
    // local selection
    const [localSelection, setLocalSelection] = useState(selectedPlayers);

    useEffect(() => {
        const slices = result?.data().slices().toArray();
        if (slices)
            setItems(
                slices
                    .map((slice) => ({
                        custId: slice.sliceTitles()[0],
                        custLabel: getCustlabelByCustId(slice.sliceTitles()[0]),
                        custColor: getColorByCustId(slice.sliceTitles()[0]),
                    }))
                    .sort((a, b) => a.custLabel.localeCompare(b.custLabel)),
            );
    }, [result]);

    useEffect(() => {
        setLocalSelection([...selectedPlayers.filter((p) => items.map((item) => item.custId).includes(p.custId))]);
        return () => {
            setLocalSelection([]);
        };
    }, [selectedPlayers, items]);

    function onSubmitSelection() {
        setSelectedPlayers([...localSelection]);
        setShowPlayerSelectionModal(false);
    }

    function onSelectItem(item) {
        const arr = [...localSelection];
        const index = arr.findIndex((obj) => obj.custId === item.custId);
        if (index !== -1) {
            if (arr.length > 1) arr.splice(index, 1);
        } else if (arr.length < 5) arr.push(item);
        setLocalSelection([...arr]);
    }

    function onClickCloseModal() {
        setShowPlayerSelectionModal(false);
        setLocalSelection([...selectedPlayers]);
    }

    return (
        <Modal open={showPlayerSelectionModal} footer={null} onCancel={() => onClickCloseModal()} width={700}>
            <h3 className="font-semibold text-indigo-500 mb-3">Select players</h3>
            {status === "loading" ? (
                <div className="flex flex-col justify-center items-center gap-2 h-40">
                    <LoadingComponent />
                </div>
            ) : (
                <>
                    <Tooltip
                        title={<span className="text-gray-500">For an optimal visualization, the selection is limited to 5 slots</span>}
                        placement="right"
                        color="#F5F5F5"
                        overlayStyle={{
                            maxWidth: "500px",
                        }}
                    >
                        <span className="text-gray-500 text-[12px] font-medium mb-3">{`(${localSelection.length}/5)`}</span>
                    </Tooltip>
                    <ul className={`grid grid-rows-${Math.round(items.length / 2)} grid-cols-2 gap-4 z-50 py-4`}>
                        {items.map((item, index) => {
                            const isSelected = localSelection.find((obj) => obj.custId === item.custId) !== undefined;
                            const middlePosition = Math.round(items.length / 2);
                            const rowPosition = Math.floor(index % middlePosition);

                            return (
                                <li
                                    className={`flex items-center gap-2 hover:bg-indigo-100 py-1 px-2 cursor-pointer`}
                                    key={item.custId}
                                    onClick={() => onSelectItem(item)}
                                    style={{
                                        gridColumnStart: index >= Math.floor(middlePosition) ? 2 : 1,
                                        gridRowStart: rowPosition + 1,
                                    }}
                                >
                                    <input
                                        type="radio"
                                        checked={isSelected}
                                        className="w-4 h-4"
                                        style={{
                                            accentColor: "#6B50B6",
                                        }}
                                    />
                                    {/* <FaRegCheckCircle className={isSelected ? "text-indigo-500" : "text-gray-300"} /> */}
                                    <label htmlFor={item.value} className={`cursor-pointer text-base ${isSelected ? "font-medium" : "font-normal"}`}>
                                        {item.custLabel}
                                    </label>
                                </li>
                            );
                        })}
                    </ul>
                    <ActionButton title={"Submit"} onClick={onSubmitSelection} />
                </>
            )}
        </Modal>
    );
}

function transformSlicesToBrandTree(slices) {
    return slices
        ? slices.reduce((acc, slice) => {
              const [make, model, custId, versionStd] = slice.sliceTitles();
              const versionObject = {
                  label: versionStd,
                  //   associate kpiValues for each custId
                  [custId]: slice.dataPoints().map((dp) => (dp.rawValue !== null ? Number(dp.rawValue) : null)),
              };
              if (!(make in acc)) {
                  acc[make] = {
                      [model]: [versionObject],
                  };
              } else {
                  if (model in acc[make]) {
                      const index = acc[make][model].findIndex((obj) => obj.label === versionStd);
                      if (index !== -1) {
                          acc[make][model][index] = {
                              ...acc[make][model][index],
                              ...versionObject,
                          };
                      } else {
                          acc[make][model] = [...acc[make][model], versionObject];
                      }
                  } else {
                      acc[make][model] = [versionObject];
                  }
              }
              return acc;
          }, {})
        : {};
}
export { BrandModelAnalysis, calculationModes, DropDownPlayerField, transformSlicesToBrandTree };
