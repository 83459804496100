import { newArithmeticMeasure, newAttributeSort, newMeasureSort } from "@gooddata/sdk-model";
import { ErrorComponent, LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import React, { useContext, useEffect, useState } from "react";
import { Dropdown } from "antd";
import { RiUnpinFill } from "react-icons/ri";
import { FiMoreVertical } from "react-icons/fi";
import MeasuresContext, { flagServiceMap } from "../../contexts/Measures";
import { useDashboard } from "../../contexts/DashboardProvider";
import * as Md from "../../md/full";

const AgregatedBarWidget = ({ id, execDefinition, widgetUid, componentChild, ...props }) => {
    const { selectedKpi } = useContext(MeasuresContext);
    const { seriesBy: execSeries, slicesBy, filters } = execDefinition;
    const { unPinwWidget } = useDashboard();
    const [newSeries, setNewSeries] = useState([...execSeries]);
    const [data, setData] = useState({ name: undefined, data: [] });
    const [categories, setCategories] = useState([]);
    const displayMode = props.displayMode;
    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy: [...newSeries],
            slicesBy: [...slicesBy, ...displayMode.attributes],
            filters,
            sortBy: [newMeasureSort(execSeries[selectedKpi.index], "desc"), newAttributeSort(displayMode.attributes[displayMode.attributes.length - 1])],
        },
    });
    const { flag_services } = props;

    useEffect(() => {
        const slices = result?.data().slices().toArray();
        if (slices) {
            const dataPoints = slices.map((slice) => {
                const value = slice.dataPoints().find((dp) => newSeries[selectedKpi.index].measure.localIdentifier === dp.seriesDesc.measureDefinition.measure.localIdentifier)?.rawValue;
                return {
                    y: selectedKpi.index === 24 ? Number((Number(value) * 100).toFixed(2)) : Math.round(Number(value)),
                    color: "#506e96",
                };
            });
            setData({
                name: selectedKpi.name,
                data: dataPoints,
            });
            const titles = slices.map((slice) => slice.sliceTitles().reduce((acc, sliceTitle) => (displayMode.attributes.length > 1 ? acc + " " + sliceTitle : sliceTitle)));
            setCategories(titles);
        }
    }, [result, selectedKpi, componentChild]);

    useEffect(() => {
        if (flag_services) {
            const enabledServices = Object.keys(flag_services)
                .filter((flService) => flService in flagServiceMap && flag_services[flService])
                .map((flService) => flagServiceMap[flService]);
            newSeries.splice(23, 1, newArithmeticMeasure([Md.FinRental.Avg, Md.AdjDeposit.Avg, ...enabledServices], "sum"));
            newSeries.splice(24, 1, newArithmeticMeasure([newArithmeticMeasure([Md.FinRental.Avg, Md.AdjDeposit.Avg, ...enabledServices], "sum"), Md.ListPrice.Avg], "ratio"));
            setNewSeries([...newSeries]);
        }
    }, [componentChild, flag_services]);

    return status !== "loading" && data?.data?.length > 0 ? (
        <>
            <div className="border-2 border-gray-300 rounded">{React.createElement(componentChild, { series: [data], categories, displayMode: props.displayMode, kpiSettings: flag_services, ...props })}</div>
        </>
    ) : status === "error" ? (
        <div className="relative">
            <Dropdown
                menu={{
                    items: [
                        {
                            key: "unpin",
                            icon: <RiUnpinFill />,
                            label: "Unpin widget",
                            onClick: () => unPinwWidget(widgetUid),
                        },
                    ],
                }}
                trigger={["click"]}
            >
                <FiMoreVertical className="absolute top-2 right-2 z-40 rounded w-8 h-8 px-1.5 py-1 text-gray-500 cursor-pointer hover:bg-backgroundColor hover:text-indigo-700" />
            </Dropdown>
            <div className="min-h-[400px] flex flex-col items-center justify-center">
                <ErrorComponent />
                <span className="text-sm font-medium text-steel/80 text-center py-2">
                    Seems like there is an issue with the data or the widget <br /> try unpinning and re-pinning it
                </span>
            </div>
        </div>
    ) : (
        <div className="min-h-[400px] flex items-center justify-center">
            <LoadingComponent />
        </div>
    );
};

export default AgregatedBarWidget;
