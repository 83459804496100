import bearFactory, { FixedLoginAndPasswordAuthProvider, ContextDeferredAuthProvider } from "@gooddata/sdk-backend-bear";
import { withNormalization, withCaching } from "@gooddata/sdk-backend-base";

import { backend as hostname } from "../../constants";

const backendConfig = process.env.REACT_APP_SET_HOSTNAME ? { hostname } : {};

const customCacheSettings = {
    maxExecutions: 30,
    maxResultWindows: 15,
    maxCatalogs: 3,
    maxCatalogOptions: 100,
    maxSecuritySettingsOrgs: 3,
    maxSecuritySettingsOrgUrls: 100,
    maxSecuritySettingsOrgUrlsAge: 300000,
    maxAttributeWorkspaces: 3,
    maxAttributeDisplayFormsPerWorkspace: 300,
    maxAttributesPerWorkspace: 300,
    maxAttributeElementResultsPerWorkspace: 300,
    maxWorkspaceSettings: 3,
};

export const createBackend = () => {
    return withNormalization(withCaching(bearFactory(backendConfig), customCacheSettings)).withAuthentication(new ContextDeferredAuthProvider());
    // return bearFactory(backendConfig).withAuthentication(new ContextDeferredAuthProvider());
    // return bearFactory(backendConfig).withAuthentication(new FixedLoginAndPasswordAuthProvider(process.env.REACT_APP_GDC_USERNAME, process.env.REACT_APP_GDC_PASSWORD));
};

export const backendWithCredentials = (backend, username, password) => {
    return backend.withAuthentication(new FixedLoginAndPasswordAuthProvider(username, password));
};
